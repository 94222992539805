/* eslint-disable no-nested-ternary */
import * as React from 'react';

import { colors } from '@crehana/ui';

import { Clock3, Users, Star, Play3 } from '@crehana/icons';

import { formatters } from '@crehana/utils';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const { baseLighter, yellowMain, baseMain, white } = colors;

const Enrollments: React.FC<Partial<CourseCardProps>> = props => {
  const { enrollments, score, hideScore, dark, bundleCoursesLength, duration } =
    props;

  return (
    <div className="flex items-center justify-between my-12 EnrollmentsComponent sm:my-16">
      <div>
        <div className="flex items-center">
          {typeof enrollments === 'number' && enrollments > 0 && (
            <div className="flex items-center mr-16">
              <Users size={20} color={baseLighter} />
              <span
                className={`ml-8 font-body4 ${
                  dark ? 'text-white' : 'text-gray-dark'
                }`}
              >
                {enrollments}
              </span>
            </div>
          )}
          {!hideScore && score && (
            <div className="flex items-center">
              <Star color={yellowMain} size={24} />
              <span
                className={`ml-4 sm:ml-8 font-body4 ${
                  dark ? 'text-white' : 'text-gray-dark'
                }`}
              >
                {(
                  Math.ceil((score.average === 0 ? 5 : score.average) * 100) /
                  100
                ).toFixed(2)}
              </span>
            </div>
          )}
        </div>
        {!!bundleCoursesLength && (
          <div className="flex items-center mt-12">
            <div className="flex items-center">
              <Play3 size={16} color={dark ? white : baseMain} />
              <span
                className={`ml-8 font-body4 ${
                  dark ? 'text-white' : 'text-base-main'
                }`}
              >
                {`${bundleCoursesLength} cursos`}
              </span>
            </div>
            {!!duration && (
              <div className="flex items-center ml-12">
                <Clock3 size={14} color={dark ? white : baseMain} />
                <span
                  className={`ml-4 sm:ml-8 font-body4 ${
                    dark ? 'text-white' : 'text-base-main'
                  }`}
                >
                  {formatters.courseDuration(duration)}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Enrollments;
