import React from 'react';
import styled, { keyframes } from 'styled-components';

const bounceCircle = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    background-color: #4c22f4;
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
    
`;

const LoadingWrapper = styled.div`
  .circleG {
    height: 16px;
    width: 16px;
    animation: ${bounceCircle} 1.2s linear infinite;
    border-radius: 11px;
  }
  #circleG1 {
    animation-delay: 0.18s;
  }
  #circleG2 {
    animation-delay: 0.42000000000000004s;
  }
  #circleG3 {
    animation-delay: 0.5399999999999999s;
  }
`;

const Enrolling = () => {
  return (
    <LoadingWrapper className="flex items-center">
      <div id="circleG1" className="circleG mr-8" />
      <div id="circleG2" className="circleG mr-8" />
      <div id="circleG3" className="circleG mr-8" />
    </LoadingWrapper>
  );
};

export default Enrolling;
