import React from 'react';

import { Container, Col, PrimaryButton } from '@crehana/ui';

const RetryFetch: React.FC<{ refetch: () => void }> = ({ refetch }) => {
  return (
    <Container className="w-full text-center">
      <Col>
        <p className="font-body3 pb-16">
          ¡Oh, no! Ocurrió un problema al cargar la lista cursos.
        </p>
        <PrimaryButton onClick={refetch} label="Volver a intentar" />
      </Col>
    </Container>
  );
};

export default RetryFetch;
