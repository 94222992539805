import React from 'react';

import { useTranslation } from '@crehana/i18n';
import { TextField, colors } from '@crehana/ui';
import { NewArrowLeft, Clear } from '@crehana/icons';

import { useFilterContext } from 'Jsx/CampaignLanding/hooks/useFilters';

const SearchField: React.FC<{
  hideSearchInput(): void;
  isBlackTheme: boolean;
}> = ({ hideSearchInput, isBlackTheme }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = React.useState('');

  const { setSearchTextDebouncedValue } = useFilterContext();

  return (
    <div>
      <TextField
        className="w-full text-white"
        css={`
          & > div {
            background: ${isBlackTheme ? '#20222c' : 'white'};
            border: none !important;
            ${isBlackTheme &&
            `
              & input {
                color: white !important;
                &::placeholder {
                  color: white;
                  opacity: 1;
                }
              }
              & input::placeholder {
                color: white;
                opacity: 1;
              }
            `}
          }
        `}
        inputProps={{
          placeholder: t('SEARCH_PLACEHOLDER'),
        }}
        autoFocus
        startAdornment={
          <button
            type="button"
            className="w-full h-full block pl-0"
            onClick={hideSearchInput}
          >
            <NewArrowLeft
              size={24}
              color={isBlackTheme ? colors.white : colors.baseMain}
            />
          </button>
        }
        endAdornment={
          searchText.length > 1 ? (
            <button
              type="button"
              className="w-full h-full block"
              onClick={() => {
                setSearchText('');
                setSearchTextDebouncedValue('');
              }}
            >
              <Clear size={18} />
            </button>
          ) : (
            <div />
          )
        }
        value={searchText}
        onChange={event => {
          const { value } = event.target;

          setSearchText(value);
          setSearchTextDebouncedValue(value);
        }}
      />
    </div>
  );
};

export default SearchField;
