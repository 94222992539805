import * as React from 'react';

import { unescapeChars } from '@crehana/ui';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const Professor: React.FC<Partial<CourseCardProps>> = props => {
  const { professor, dark } = props;

  if (!professor?.fullName) {
    return null;
  }

  return (
    <div className="flex mt-8 ProfessorComponent">
      <span className={`font-body4 ${dark ? 'text-white' : 'text-gray-dark'}`}>
        {`Por ${professor ? unescapeChars(professor.fullName) : 'crehana'}`}
      </span>
    </div>
  );
};

export default Professor;
