import React from 'react';

import { useTranslation } from '@crehana/i18n';
import { colors } from '@crehana/ui';

import { UList, Filter as FilterIcon } from '@crehana/icons';

import { useFilterContext } from 'Jsx/CampaignLanding/hooks/useFilters';

import SearchField from '../SearchField';

type Props = {
  openModal: (view: 'filters' | 'orderBy') => () => void;
  isFilterButtonActive: boolean;
  isOrderButtonActive: boolean;
  filterSelectedCount: number;
  isBlackTheme: boolean;
};

const ButtonsAndSearch: React.FC<Props> = ({
  openModal,
  isFilterButtonActive,
  isOrderButtonActive,
  filterSelectedCount,
  isBlackTheme,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = React.useState('');

  const { setSearchTextDebouncedValue } = useFilterContext();

  const searchCoursesByText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;

    setSearchText(query);
    setSearchTextDebouncedValue(query);
  };
  const clearSearchText = () => {
    setSearchText('');
    setSearchTextDebouncedValue('');
  };

  return (
    <div className="w-full flex items-center justify-end">
      <button
        type="button"
        className="flex items-center mr-24"
        data-testid="page-filter-filterBtn"
        onClick={openModal('filters')}
      >
        <FilterIcon
          size={14}
          color={isFilterButtonActive ? colors.secondaryMain : ''}
        />
        <span
          className={`font-subtitle2 ml-8 ${
            isFilterButtonActive
              ? 'text-secondary-main'
              : isBlackTheme
              ? 'text-white'
              : 'text-base-main'
          }`}
        >
          {t('FILTER_LABEL')}{' '}
          {filterSelectedCount > 0 ? `(${filterSelectedCount})` : ''}
        </span>
      </button>
      <button
        type="button"
        className="flex items-center mr-24"
        data-testid="page-filter-orderByBtn"
        onClick={openModal('orderBy')}
      >
        <UList
          size={16}
          color={
            isOrderButtonActive
              ? colors.secondaryMain
              : isBlackTheme
              ? colors.white
              : ''
          }
        />
        <span
          className={`font-subtitle2 ml-8 ${
            isOrderButtonActive
              ? 'text-secondary-main'
              : isBlackTheme
              ? 'text-white'
              : 'text-base-main'
          }`}
        >
          {t('ORDER_BY_LABEL')} {isOrderButtonActive ? '(1)' : ''}
        </span>
      </button>

      <div style={{ maxWidth: 190 }}>
        <SearchField
          value={searchText}
          onChange={searchCoursesByText}
          onClear={clearSearchText}
          placeholder={`${t('SEARCH_PLACEHOLDER')}...`}
          isBlackTheme={isBlackTheme}
        />
      </div>
    </div>
  );
};

export default ButtonsAndSearch;
