import { loadScript } from '@crehana/utils';

import { ProviderPlugin } from './thirdPartyLoader';

function gtagProvider(): ProviderPlugin {
  return {
    initialize: () => {
      if (!window.GOOGLE_ANALYTICS_ENABLED) {
        return;
      }
      loadScript({
        src: 'https://www.googletagmanager.com/gtag/js?id=UA-60829200-1',
        async: true,
      });

      window.dataLayer = window.dataLayer || [];

      window.gtag = (...args) => {
        window.dataLayer!.push(...args);
      };
      window.gtag('js', new Date());

      // window.gtag('config', 'UA-60829200-1');
      window.gtag('config', 'AW-769894422');
    },
    loaded: () => !!window.gtag && !!window.dataLayer,
  };
}

export default gtagProvider;
