import React from 'react';
import styled, { css } from 'styled-components';

import { Dialog, PrimaryButton } from '@crehana/ui';
import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';
import { getImgxUrl } from '@crehana/utils';

import { secondaryMainHEX } from '@crehana/ui/dist/styles/colors';

import { usePageContext } from 'Jsx/CampaignLanding/Context';

import CountDownTimer from './components/CountdownTimer';

type ImageBackgroundProps = {
  mobileBackground?: string;
  tabletBackground?: string;
  desktopBackground?: string;
};

export const ImageBackground = styled.div<ImageBackgroundProps>`
  min-height: 252px;
  background-size: cover;
  background-position: bottom;
  background-image: url(${props => props.mobileBackground});
  ${props => props.theme.breakpoints.up('sm')} {
    background-image: url(${props => props.tabletBackground});
  }
  ${props => props.theme.breakpoints.up('md')} {
    min-height: 360px;
    background-image: url(${props => props.desktopBackground});
  }
`;

export const Content = styled.div`
  ${props => props.theme.breakpoints.up('sm')} {
    max-width: 700px;
  }
  ${props => props.theme.breakpoints.up('md')} {
    max-width: 800px;
  }
`;

const Hero = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { loading, me } = useMe();
  const {
    bannerDesktop,
    bannerMobile,
    bannerTablet,
    title,
    titleColor,
    subtitle,
    description,
    subtitleColor,
    logoImageUrl,
    timer,
    timerCustomColor,
    timerBackgroundColor,
    bannerButonTextColor,
    bannerButtonText,
    pageSlug,
    legalsCopy,
    tycTextColor,
  } = usePageContext();

  return (
    <ImageBackground
      desktopBackground={
        bannerDesktop
          ? getImgxUrl(bannerDesktop, { auto: 'format' })
          : undefined
      }
      tabletBackground={
        bannerTablet ? getImgxUrl(bannerTablet, { auto: 'format' }) : undefined
      }
      mobileBackground={
        bannerMobile ? getImgxUrl(bannerMobile, { auto: 'format' }) : undefined
      }
      className="pt-72 md:pt-96 pb-36 px-20 flex justify-center bg-base-dark bg-no-repeat"
    >
      <Content className="flex flex-col items-center text-center ">
        {logoImageUrl && (
          <img
            src={logoImageUrl}
            alt="logo-banner"
            style={{
              width: 'auto',
              height: 60,
            }}
            className="hidden md:inline-block md:mt-0 md:mx-auto md:mb-16"
          />
        )}
        <h1
          className="mb-20 sm:mb-12 md:mb-16"
          style={{ color: titleColor || 'white' }}
          data-testid="page-title"
        >
          <span className="font-h2-md sm:hidden">{title}</span>
          <span className="font-h1-md hidden sm:block md:hidden">{title}</span>
          <span className="font-h1 hidden md:block">{title}</span>
        </h1>
        <h2
          style={{
            color: subtitleColor!,
          }}
          className="mb-16 md:mb-24 text-white"
          data-testid="page-subtitle"
        >
          <span className="font-subtitle2-md sm:hidden">{subtitle}</span>
          <span className="font-subtitle1-md hidden sm:block md:hidden">
            {subtitle}
          </span>
          <span className="font-subtitle1 hidden md:block ">{subtitle}</span>
        </h2>
        <p
          className="font-body4 text-white text-center mb-16"
          style={{ maxWidth: '445px' }}
        >
          <span className="font-body4-md sm:hidden">{description}</span>
          <span className="font-body2-md hidden sm:block md:hidden">
            {description}
          </span>
          <span className="font-body1-md hidden md:block">{description}</span>
        </p>
        {timer && timer.expireDate && (
          <CountDownTimer
            timerCustomColor={timerCustomColor}
            timerBackgroundColor={timerBackgroundColor}
            date={timer.expireDate}
          />
        )}

        {!loading && !me && bannerButtonText && (
          <PrimaryButton
            className="mt-24 w-full overflow-hidden"
            onClick={() => {
              if (window.showRegisterModal) {
                window.showRegisterModal();
              } else {
                window.location.href = `/registro/?nextUrl=/${pageSlug}`;
              }
            }}
            css={css`
              max-width: 280px;
              background-color: ${bannerButonTextColor
                ? `${bannerButonTextColor} !important`
                : ''};
              ${props => props.theme.breakpoints.up('md')} {
                width: auto;
                max-width: 100%;
              }
            `}
            label={bannerButtonText || '¡Regístrate ahora!'}
          />
        )}

        {Boolean(legalsCopy) && (
          <React.Fragment>
            <button
              className="font-link mt-24"
              style={{ color: tycTextColor || secondaryMainHEX }}
              onClick={() => {
                setIsOpen(true);
              }}
              type="button"
            >
              Ver términos y condiciones
            </button>
            <Dialog
              size="s"
              open={isOpen}
              onDismiss={() => {
                setIsOpen(false);
              }}
            >
              <div className="py-48 px-32">
                <p className="font-legals font-gray-dark">{legalsCopy}</p>
              </div>
            </Dialog>
          </React.Fragment>
        )}
      </Content>
    </ImageBackground>
  );
};

export default Hero;
