import React from 'react';
import ReactDOM from 'react-dom';

import { useIsClient } from '@crehana/react-hooks';

import SocialNotification from './SocialNotification';
import { SocialNotificationPortalProps } from './types';

const SocialNotificationPortal: React.FC<SocialNotificationPortalProps> =
  props => {
    const isClient = useIsClient();

    if (!isClient) {
      return null;
    }

    return ReactDOM.createPortal(
      <SocialNotification {...props} />,
      document.body,
    );
  };

export default SocialNotificationPortal;
