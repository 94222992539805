import React from 'react';

import { PrimaryButton, colors } from '@crehana/ui';
import { Clear } from '@crehana/icons';

import Media from 'Jsx/Shared/ui/Media';

const SignupBottomBanner = () => {
  const [hideSignupBanner, setHideSignupBanner] = React.useState(false);

  if (hideSignupBanner) return null;
  return (
    <Media from="xs" until="md">
      <div className="w-full flex items-start justify-center fixed bg-base-main z-snackbar bottom-0">
        <div className="mt-0 w-11/12 py-20 pl-20 flex flex-col sm:flex-row justify-center items-center">
          <p className="tw-font-pop text-center mb-16 sm:mb-0 sm:mr-24 text-white">
            Para acceder a cursos gratis
          </p>
          <PrimaryButton
            full
            label="Regístrate aquí"
            style={{ maxWidth: '280px' }}
            onClick={() => {
              setHideSignupBanner(true);
              if (window.showRegisterModal) {
                window.showRegisterModal('/cursos-gratis/');
              } else {
                window.location.href = `?next=/cursos-gratis`;
              }
            }}
          />
        </div>

        <button
          type="button"
          className="w-1/12 pt-8 pr-8 sm:pt-12"
          onClick={() => {
            setHideSignupBanner(true);
          }}
        >
          <Clear size={20} color={colors.secondaryMain} />
        </button>
      </div>
    </Media>
  );
};

export default SignupBottomBanner;
