import React from 'react';
import styled, { css } from 'styled-components';

import { Dialog, PrimaryButton } from '@crehana/ui';

import { useMe } from '@crehana/web';

import { useTranslation } from '@crehana/i18n';

import { secondaryMainHEX } from '@crehana/ui/dist/styles/colors';

import { createUrlWithSourceQueryParams } from 'Jsx/Analytics/Amplitude';

import { usePageContext } from 'Jsx/CampaignLanding/Context';

import CountDownTimer from './components/CountdownTimer';

export const Content = styled.div`
  ${props => props.theme.breakpoints.up('sm')} {
    max-width: 700px;
  }
  ${props => props.theme.breakpoints.up('md')} {
    max-width: 800px;
  }
`;

const InfluencerHero = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { loading, me } = useMe();
  const {
    title,
    subtitle,
    logoImageUrl,
    timer,
    timerCustomColor,
    timerBackgroundColor,
    bannerButonTextColor,
    bannerButtonText,
    pageSlug,
    legalsCopy,
    tycTextColor,
  } = usePageContext();
  const { t } = useTranslation();

  const urlWithSource = createUrlWithSourceQueryParams({
    url: `/registro/?nextUrl=/${pageSlug}`,
    source: 'Landing Influencer',
    sourceDetail: 'Influencer Hero CTA',
  });

  return (
    <div className="pt-56 md:pt-96 sm:pb-24 px-20 flex justify-center bg-no-repeat">
      <Content className="flex flex-col items-center text-center ">
        {logoImageUrl && (
          <img
            src={logoImageUrl}
            alt="logo-banner"
            style={{
              width: 'auto',
              height: 60,
            }}
            className="hidden md:inline-block md:mt-0 md:mx-auto md:mb-16"
          />
        )}
        <h1
          className="mb-16 sm:mb-24 sm:w-7/12 md:w-full font-h4"
          data-testid="page-title"
          style={{ maxWidth: 900 }}
        >
          {title}
        </h1>
        <p data-testid="page-subtitle" className="mb-12 font-body2">
          {subtitle}
        </p>
        {timer && timer.expireDate && (
          <div className="mb-24">
            <CountDownTimer
              timerCustomColor={timerCustomColor}
              timerBackgroundColor={timerBackgroundColor}
              date={timer.expireDate}
            />
          </div>
        )}

        {!loading && !me && bannerButtonText && (
          <PrimaryButton
            className="w-full overflow-hidden"
            onClick={() => {
              if (window.showRegisterModal) {
                window.showRegisterModal();
              } else {
                window.location.href = urlWithSource;
              }
            }}
            // @ts-ignore
            css={css`
              max-width: 280px;
              background-color: ${bannerButonTextColor
                ? `${bannerButonTextColor} !important`
                : ''};
              ${props => props.theme.breakpoints.up('md')} {
                width: auto;
                max-width: 100%;
              }
            `}
            label={bannerButtonText || '¡Regístrate ahora!'}
          />
        )}

        {Boolean(legalsCopy) && (
          <React.Fragment>
            <button
              type="button"
              className="mt-24 font-link"
              style={{ color: tycTextColor || secondaryMainHEX }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {t('LEGAL_LABEL')}
            </button>
            <Dialog
              size="s"
              open={isOpen}
              onDismiss={() => {
                setIsOpen(false);
              }}
            >
              <div className="py-48 px-32">
                <p color="gray-dark" className="font-legals">
                  {legalsCopy}
                </p>
              </div>
            </Dialog>
          </React.Fragment>
        )}
      </Content>
    </div>
  );
};

export default InfluencerHero;
