/* eslint-disable camelcase, react/no-array-index-key */
import React from 'react';
import { Helmet } from 'react-helmet';

import { MetaTags as MetaTagsType } from 'Jsx/global.d';

const MetaTags: React.FC<MetaTagsType> = ({
  title,
  description,
  image,
  protocol_host,
  canonical_url,
  extra_kwargs,
}) => {
  const metaImage = image || 'img/logo/logo-crehana.png';
  const metaTitle = title ? `${title} | Crehana` : '';
  const metaDescription =
    description ||
    'Crehana es una comunidad educativa donde personas aprenden habilidades creativas en cursos preparados por expertos. Únete hoy y descubre un mundo creativo.';

  return (
    <React.Fragment>
      {metaTitle && (
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="title" content={metaTitle} />
          <meta property="og:title" content={metaTitle} />
          <meta name="twitter:title" content={metaTitle} />
        </Helmet>
      )}

      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />

        <meta property="og:image" content={metaImage} />
        <meta property="og:image:url" content={metaImage} />
        <meta name="twitter:image:src" content={metaImage} />

        <meta property="fb:app_id" content="375346295993240" />
        {protocol_host && (
          <meta property="fb:site_name" content={protocol_host} />
        )}

        {canonical_url && <meta property="og:url" content={canonical_url} />}
        <meta property="og:provider_name" content="Crehana" />
        <meta property="og:site_name" content="Crehana" />

        {extra_kwargs &&
          Object.keys(extra_kwargs).map((property, index) => (
            <meta
              key={index}
              property={property}
              content={extra_kwargs[property]}
            />
          ))}

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="Crehana21312323" />
        <meta name="twitter:site" content="@crehanacom" />
        <meta name="twitter:domain" content="crehana.com" />
      </Helmet>
    </React.Fragment>
  );
};

export default React.memo(MetaTags);
