// @ts-nocheck
/**
 * Remember to only load the service worker after our main components are mounted.
 * If we load the service worker at first-load it will start to fetch all assets of manifest.json...
 * ... and it can impact in a negative way the bandwidth of our users
 */
const loadServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/ServiceWorker.js')
      .then(registration => {
        console.log('SW registered');
      })
      .catch(registrationError => {
        console.log('Crehana SW registration failed: ', registrationError);
      });
  }
};

export default loadServiceWorker;
