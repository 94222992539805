import React from 'react';

import { colors } from '@crehana/ui';

import { CourseTag as CourseTagProps } from '../../../types/Course';

const CourseTag: React.FC<CourseTagProps> = ({
  textLabel,
  textColor,
  backgroundColor,
}) => (
  <span
    className="block px-8 py-4 text-center rounded-5 font-caption min-w-96"
    style={{
      backgroundColor: backgroundColor || colors.secondaryMain,
      color: textColor || '',
    }}
  >
    {textLabel}
  </span>
);

export default CourseTag;
