/* eslint-disable no-nested-ternary */
import * as React from 'react';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

// eslint-disable-next-line no-restricted-imports
import formatPrice from '../../utils/formatPrice';

const Prices: React.FC<Partial<CourseCardProps>> = props => {
  const {
    prices,
    bundlePrice,
    courseType,
    customBundle,
    dark,
    classification,
    isBlackTheme,
  } = props;
  const { me: user } = useMe();

  if ((user && user.isPremium) || customBundle) {
    return null;
  }

  if (!prices) {
    return null;
  }

  const priceFormatted = formatPrice(prices!.price, prices!.currencySymbol);

  if (courseType === 'membership') {
    return <div className="h-24" />;
  }

  if (bundlePrice && prices) {
    return (
      <span className="font-currency" data-testid="card-real-price">
        {`${prices.currencySymbol}${prices.realPrice!.toLocaleString('en-US')}`}
      </span>
    );
  }

  // @ts-expect-error totalPrice is only available in bundle
  if (classification === 'bundle' && prices && prices.totalPrice) {
    return (
      <React.Fragment>
        <span
          className="course-card-price font-currency text-red-main"
          data-testid="card-price"
        >
          {`${prices.currencySymbol}${priceFormatted}`}
        </span>
        <span
          className="course-card-discount font-discount-s text-gray-dark"
          data-testid="card-real-price"
        >
          {`${prices.currencySymbol}${
            prices.currencySymbol === 'R$'
              ? // @ts-expect-error totalPrice is only available in bundle
                prices.totalPrice!.toLocaleString()
              : // @ts-expect-error totalPrice is only available in bundle
                prices.totalPrice!.toLocaleString('en-US')
          }`}
        </span>
      </React.Fragment>
    );
  }

  const priceColor =
    prices && prices.discount && prices.discount > 0
      ? 'text-red-main'
      : 'text-primary-main';

  if (courseType === 'temporal-access' || user?.canEnrollFreemium) {
    return <div style={{ height: 22 }} />;
  }

  return (
    <div
      className="flex items-center w-full PricesComponent"
      css={`
        .course-card-price {
          line-height: 22px;
        }
        .course-card-discount {
          font-size: 14px;
        }
      `}
    >
      {prices ? (
        prices && prices.price === 0 ? (
          <span className="course-card-price font-currency text-primary-main">
            Gratis
          </span>
        ) : (
          <>
            <span
              className={`font-h4 ${
                isBlackTheme
                  ? 'text-white'
                  : dark
                  ? 'text-secondary-light'
                  : priceColor
              }`}
              data-testid="card-price"
            >
              {`${prices.currencySymbol}${priceFormatted}`}
            </span>
            {prices.discount && prices.discount > 0 ? (
              <span
                className={`font-discount ml-16 ${
                  dark ? 'text-white' : 'text-gray-dark'
                }`}
                data-testid="card-real-price"
              >
                {`${prices.currencySymbol}${
                  prices.currencySymbol === 'R$'
                    ? prices.realPrice!.toLocaleString()
                    : prices.realPrice!.toLocaleString('en-US')
                }`}
              </span>
            ) : null}
          </>
        )
      ) : null}
    </div>
  );
};

export default Prices;
