import React, { useEffect } from 'react';

import { ShoppingCart } from '@crehana/icons/outline';

import Loadable from '@crehana/web/dist/Loadable';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';

import { customLocalStorage } from '@crehana/utils';

import { useProductsContainer } from '@crehana/web/dist/ReactContexts/Products';

import { LightMenuWithTopBannerAndAuthDialog as LightMenu } from '@crehana/web';

import { useTranslation } from '@crehana/i18n';

import { LANGUAGES } from '@crehana/ts-types';

import { InitialStateResult } from 'Jsx/Utils/getBaseInitialState';
import MetaTags from 'Jsx/Shared/SEO/MetaTags';

// eslint-disable-next-line
import CoursesInBundleProvider from 'Jsx/ContextApis/CoursesInBundle';
import { useAmplitude, getSourceEventParams } from 'Jsx/Analytics/Amplitude';

import type { BackgroundXPosition } from 'Jsx/CampaignLanding/types';

import HeroSection from './components/HeroSection';
import SignupBottomBanner from './components/SignupBottomBanner';
import WarningDialog from './components/WarningDialog';
import TrustPulseNotification from './components/TrustPulseNotification';
import CourseAndFilters from './sections/CourseAndFilters';
import { AppProps, PAGE_TYPES } from './types';
import { CAMPAIGN_LANDING_AMPLITUDE_PAGE_NAME } from './constants';
import { PageContext } from './Context';
import useFacebookPageView from './hooks/useFacebookPageView';
import useCouponValidation from './hooks/useCouponValidation';

const Specializations = () => (
  <Loadable
    module={() =>
      import(
        /* webpackChunkName: "crehana-app-campaign-landing-specializations" */
        './sections/Specializations'
      )
    }
  />
);

export type ExtraState = {};

const App: React.FC<InitialStateResult<AppProps & ExtraState>> = ({
  categories,
  countryPrefix,
  pageTheme,
  stepTheme,
  featureFlags,
  ...props
}) => {
  const { countryCode } = useTranslation();
  const { logEvent } = useAmplitude();
  const { me, loading } = useMe();
  const { showShoppingCartPopup } = useProductsContainer();

  const { isApplyCouponWithRequirements } = props;
  const {
    loading: isCouponLoading,
    hasCoupon,
    nextUrl,
    couponCode,
  } = useCouponValidation(props.pageSlug, !isApplyCouponWithRequirements);

  const isNotLogged = !loading && !me;
  const pageData = {
    ...props,
    countryPrefix,
    couponCode,
    languages:
      countryCode === 'br'
        ? [
            ...props.languages.filter(
              language => language.value !== LANGUAGES.ES,
            ),
          ]
        : props.languages,
  };
  const [warningDialog, setWarningDialog] = React.useState({
    forceOpen:
      pageData.isNewUserRequired && pageData.showPopupIsNewUserRequired,
  });

  useFacebookPageView();

  useEffect(() => {
    const comesFromCouponPage = customLocalStorage.getItem(
      'comes_from_coupon_page',
    );

    const shouldRedirect =
      isApplyCouponWithRequirements &&
      !isCouponLoading &&
      nextUrl &&
      (!hasCoupon || comesFromCouponPage !== 'yes');

    if (shouldRedirect) {
      window.location.href = nextUrl;
    }

    if (!isCouponLoading && isApplyCouponWithRequirements) {
      customLocalStorage.removeItem('comes_from_coupon_page');
    }
  }, [isCouponLoading, nextUrl, hasCoupon, isApplyCouponWithRequirements]);

  useEffect(() => {
    // @ts-expect-error
    logEvent({
      event: 'Render Campaign Landing',
      data: {
        ...getSourceEventParams(),
        'App Render': 'Yes',
        'Campaign Type': pageData.campaignName,
        Slug: pageData.pageSlug,
      },
    });
  }, []);

  const isBlackTheme = pageTheme === 'black' || pageTheme === 'dark';
  const isStepsInDarkMode = stepTheme === 'black' || stepTheme === 'dark';

  const isLATAMPage = pageData.pageSlug === 'LATAM';
  // In the future this value must come from the backend to allow the user more flexibility
  // We are adding this exception for this page slug only because it was an urgent requirement
  const backgroundXPosition: BackgroundXPosition =
    pageData.pageSlug === 'Plurall-Crehana' ? 'right' : 'center';

  return (
    <div className={`${isBlackTheme ? 'bg-black' : ''}`}>
      <LightMenu
        position="absolute"
        isTransparent={!pageData.isInfluencerLandingPage}
        theme={{
          nav: pageData.isInfluencerLandingPage ? 'light' : 'dark',
          submenu: 'light',
          searchInput: 'light',
        }}
        layoutConfig={{
          hideEveryLeftMenuAction: isLATAMPage,
          hideRegisterButton: pageData.isFreeInfluencerLandingPage,
          hideLoginButton: pageData.isFreeInfluencerLandingPage,
          hideShoppingCart: true,
          hideCategories: true,
          hidePremiumLandingLink: true,
          hideB2BLandingLink: true,
          hideTopBanner: isApplyCouponWithRequirements,
          phoneInputProps: {
            hide:
              !featureFlags?.isEnableCallCenterInMainMenu ||
              pageData?.isAffiliate,
            source: 'SOURCE_CAMPAIGN_LANDING_MENU',
          },
        }}
        // @ts-expect-error
        customThemes={
          pageData.logoColor
            ? {
                light: { nav: { logo: { color: pageData.logoColor } } },
                dark: { nav: { logo: { color: pageData.logoColor } } },
              }
            : undefined
        }
        topBannerProps={{
          graphqlVariables: {
            landingId: pageData.pageId,
            landingType: 'CAMPAIGN_LANDING',
          },
        }}
        amplitudePageName={CAMPAIGN_LANDING_AMPLITUDE_PAGE_NAME}
      />
      <CoursesInBundleProvider
        campaignSlug={
          pageData.pageType === PAGE_TYPES.CUSTOM_BUNDLE ||
          pageData.pageType === PAGE_TYPES.PACKS_NEW_COUPON_FLOW
            ? pageData.pageSlug
            : undefined
        }
        isDark={isBlackTheme}
        customBundleTitle={pageData.title as string}
        // @ts-expect-error
        customBundleSize={pageData.customBundleItems}
        ctaButtonText={
          pageData.isQuickPackEnrollment
            ? (pageData.ctaButtonText as string)
            : ''
        }
      >
        <PageContext.Provider value={pageData}>
          <div className="relative min-h-screen">
            <div id="main-hero-section">
              <HeroSection
                type={pageData.campaignName}
                isBlackTheme={isBlackTheme}
                isStepsInDarkMode={isStepsInDarkMode}
                minHeight={isLATAMPage}
                backgroundXPosition={backgroundXPosition}
              />
            </div>
            {isNotLogged &&
              pageData.isInfluencerLandingPage &&
              pageData.pageSlug !== 'sinenrollment' &&
              !pageData.isNewUserRequired &&
              !pageData.isFreeInfluencerLandingPage && <SignupBottomBanner />}
            {pageData.isSpecializationPage ? (
              <Specializations />
            ) : (
              <React.Fragment>
                <CourseAndFilters
                  isBlackTheme={isBlackTheme}
                  showWarningDialog={() => {
                    setWarningDialog({ forceOpen: true });
                  }}
                  isNotLogged={isNotLogged}
                />

                {/* Shopping cart button */}
                {pageData?.pageType === 'CURSO' &&
                  pageData?.ctaButtonText !== 'affiliates' && (
                    <div className="fixed bottom-0 right-0 mb-24 mr-24">
                      <button
                        type="button"
                        className="flex items-center justify-center w-56 h-56 rounded-full bg-secondary-main z-dialog"
                        onClick={showShoppingCartPopup}
                      >
                        <ShoppingCart size={32} color="white" />
                      </button>
                    </div>
                  )}
              </React.Fragment>
            )}
            {/* Warning Dialog */}
            {warningDialog.forceOpen && <WarningDialog />}
          </div>
        </PageContext.Provider>
      </CoursesInBundleProvider>

      {!pageData?.isQuickEnrollment && <TrustPulseNotification />}

      <MetaTags {...pageData.metaTags} />
    </div>
  );
};

export default App;
