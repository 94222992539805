import React from 'react';

import { useProductsContainer } from '@crehana/web/dist/ReactContexts/Products';
import { trackUserActivity, UserActivityEvent } from '@crehana/analytics';

import { AddToPack } from '../../components';

import CallToActions from '../../components/CallToActions';

import { CourseProps } from '../../types';

import Image from './components/Image';
import Title from './components/Title';
import CourseTag from './components/CourseTag';
import Score from './components/Score';
import Enrollments from './components/Enrollments';
import Prices from './components/Prices';

const Mobile: React.FC<CourseProps> = ({
  originalId,
  // image props
  lazyImageProps,
  priority,
  url,
  promoImage,
  promoImageAnimated,
  imagePlaceholder,
  imgxParams,
  title,
  customOnClickImage,
  customBundle,
  // AddToPack props
  courseInPack,
  validateAddCustomBundle,
  fullCustomBundle,
  // CallToActions props
  onClickBuyNow,
  isEnrollment,
  pageType,
  courseType,
  enrollmentAccessPreviewUrl,
  urlToBuyNow,
  prices,
  customAddToCart,
  customInShoppingCart,
  // title component props
  clickTitleHandler,
  metaDescription,
  dark,
  // CourseTag props
  courseTag,
  enrollments,
  score,
  showOnlyShoppingCartButton,
  showBuyNowButtonOnly,
  pageId,
  temporalAccessDurationLabel,
  product,
  renderCustomCallbackButtons,
  customButtonLabel,
  professor,
  showPrice,
}) => {
  const {
    shoppingCartState: { loadingCart, courseIdsInCart },
    addCourseToCart,
    removeCourseFromCart,
  } = useProductsContainer();

  // @ts-expect-error
  const onClickTitleHandler = e => {
    if (clickTitleHandler) {
      e.preventDefault();
      clickTitleHandler(e);
    } else if (url) {
      trackUserActivity({
        event: UserActivityEvent.CLICK,
        data: {
          product_id: originalId!,
          product_name: title,
          item_type: 'course',
          item_path: window.location.pathname,
        },
        nextUrl: url,
      });
    }
  };

  const renderTag = () => {
    if (courseTag) {
      return <CourseTag {...courseTag} />;
    }
  };

  const inShoppingCart = customAddToCart
    ? customInShoppingCart
    : courseIdsInCart.includes(originalId);

  return (
    <div className="w-full px-12" data-testid="course-card-mobile-wrapper">
      <div
        className={`relative rounded-10  p-16 ${
          dark ? 'bg-base-light-dark-mode ' : 'bg-white'
        }`}
        style={{ border: dark ? '0' : '1px solid #D2FAF6' }}
      >
        <div className="flex">
          <div>
            <div className="relative min-w-104">
              <Image
                lazyImageProps={lazyImageProps}
                url={url}
                promoImage={promoImage}
                promoImageAnimated={promoImageAnimated}
                imagePlaceholder={imagePlaceholder}
                imgxParams={imgxParams}
                title={title}
                customOnClickImage={customOnClickImage}
                priority={priority}
              />
            </div>
            <div className="mt-8">{renderTag()}</div>
          </div>
          <div className="ml-16">
            <Title
              title={title}
              url={url}
              metaDescription={metaDescription}
              dark={dark || dark}
              onClickTitleHandler={onClickTitleHandler}
            />

            <p
              className={`font-body4 mt-8 ${
                dark ? 'text-gray-main' : 'text-gray-dark'
              }`}
            >
              Por {professor?.fullName}
            </p>
            <div className="flex mt-16">
              {typeof enrollments === 'number' && enrollments > 0 && (
                <Enrollments enrollments={enrollments} dark={dark} />
              )}
              <Score {...score} dark={dark} />
            </div>
            <div className="flex items-center mt-16">
              {prices && showPrice && !customBundle && (
                <Prices {...prices} dark={dark} />
              )}
            </div>
          </div>
        </div>

        <div className="mt-16">
          {customBundle ? (
            <AddToPack
              courseInPack={courseInPack}
              validateAddCustomBundle={validateAddCustomBundle}
              fullCustomBundle={fullCustomBundle}
              dark={dark}
            />
          ) : (
            <div>
              {/* Buy buttons */}
              <CallToActions
                title={title}
                loadingCart={loadingCart}
                inShoppingCart={inShoppingCart}
                addCourseToCart={addCourseToCart}
                removeCourseFromCart={removeCourseFromCart}
                originalId={originalId}
                urlToBuyNow={urlToBuyNow}
                onClickBuyNow={onClickBuyNow}
                // props to render differents buttons
                isEnrollment={isEnrollment}
                pageType={pageType}
                courseType={courseType}
                enrollmentAccessPreviewUrl={enrollmentAccessPreviewUrl}
                showOnlyShoppingCartButton={showOnlyShoppingCartButton}
                showBuyNowButtonOnly={showBuyNowButtonOnly}
                url={url}
                pageId={pageId}
                temporalAccessDurationLabel={temporalAccessDurationLabel}
                product={product}
                isDarkMode={dark}
                renderCustomCallbackButtons={renderCustomCallbackButtons}
                customButtonLabel={customButtonLabel}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Mobile;
