import React from 'react';

import { PrimaryButton } from '@crehana/ui';

import { MinusSquare, PlusSquare } from '@crehana/icons';

import { useTranslation } from '@crehana/i18n';

import { ButtonSizeType } from '@crehana/ui';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const AddToPack: React.FC<
  Partial<CourseCardProps> & { size?: ButtonSizeType }
> = ({
  courseInPack,
  validateAddCustomBundle,
  fullCustomBundle,
  dark,
  size,
}) => {
  const { t } = useTranslation('main-menu');

  return (
    <div className="flex items-center w-full">
      <PrimaryButton
        full
        gray={courseInPack}
        onClick={validateAddCustomBundle}
        disabled={fullCustomBundle && !courseInPack}
        leftIcon={{
          component: courseInPack ? MinusSquare : PlusSquare,
          size: 24,
        }}
        label={
          courseInPack
            ? t('REMOVE_COURSE_FROM_PACK', 'Quitar del pack')
            : t('ADD_COURSE_TO_PACK', 'Agregar al pack')
        }
        size={size}
        className="w-full rounded-10"
        isDark={dark}
      />
    </div>
  );
};

export default AddToPack;
