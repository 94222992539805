// @ts-nocheck
import React, { FunctionComponent } from 'react';
import ReactMedia, {
  MediaQueryObject,
  QueryResults,
  MultiQueryProps,
  SingleQueryProps,
} from 'react-media';
import { screens } from 'crehana-ui/styles/breakpoints';
import { BreakpointKeyType } from 'crehana-ui/types';

type QueriesType = {
  [key in BreakpointKeyType | 'xs']: string;
};

type DefaultMatchesQueriesType = Partial<
  {
    [key in keyof QueriesType]: boolean;
  }
>;

interface MediaProps {
  /*
   * Breakpoint from where the component should match.
   */
  from?: 'xs' | BreakpointKeyType;
  /*
   * Breakpoint until where the component should match (NOT INCLUDED).
   */
  until?: 'xs' | BreakpointKeyType;
  /*
   * Useful if you need a component to match only on one breakpoint.
   */
  only?: 'xs' | BreakpointKeyType;
  children?:
    | ((matches: boolean) => React.ReactNode)
    | ((matches: QueryResults<{ [key: string]: string }>) => React.ReactNode)
    | React.ReactNode;
  onChange?:
    | ((matches: boolean) => void)
    | ((matches: QueryResults<{ [key: string]: string }>) => void);
  /**
   * If you render a <Media> component on the server, it will match by default. You can override the default behavior by setting the defaultMatches prop.
   * More info: https://github.com/ReactTraining/react-media#server-side-rendering-ssr
   */
  defaultMatches?: boolean | QueriesType;
}

/**
 * Component based on react-media and crehana-ui breakpoints
 * A media element with every query will be returned if none of the following props are provided: from, until or only.
 */
const Media: FunctionComponent<MediaProps> = ({
  from,
  until,
  only,
  children,
  defaultMatches,
  ...other
}) => {
  if (!from && !until && !only) {
    return (
      <ReactMedia<QueriesType>
        queries={{
          xs: `(max-width: ${Number(screens.sm.replace('px', '')) - 1}px)`,
          sm: `(min-width: ${screens.sm}) and (max-width: ${
            Number(screens.md.replace('px', '')) - 1
          }px)`,
          md: `(min-width: ${screens.md}) and (max-width: ${
            Number(screens.lg.replace('px', '')) - 1
          }px)`,
          lg: `(min-width: ${screens.lg})`,
        }}
        defaultMatches={defaultMatches as DefaultMatchesQueriesType}
        {...(other as MultiQueryProps<QueriesType>)}
      >
        {children}
      </ReactMedia>
    );
  }

  let minWidth =
    from && from !== 'xs' ? Number(screens[from].replace('px', '')) : undefined;
  let maxWidth =
    until && until !== 'xs'
      ? Number(screens[until].replace('px', '')) - 1
      : undefined;

  // If 'only' is provided, the min and max width is between that breakpoint and the next one (if any)
  if (only) {
    minWidth =
      only !== 'xs' ? Number(screens[only].replace('px', '')) : undefined;

    switch (only) {
      case 'xs':
        maxWidth = Number(screens.sm.replace('px', '')) - 1;
        break;
      case 'sm':
        maxWidth = Number(screens.md.replace('px', '')) - 1;
        break;
      case 'md':
        maxWidth = Number(screens.lg.replace('px', '')) - 1;
        break;
      default:
        maxWidth = undefined;
    }
  }

  let query: MediaQueryObject = {};

  if (minWidth) {
    query = { minWidth };
  }
  if (maxWidth) {
    query = { ...query, maxWidth };
  }

  return (
    <ReactMedia
      query={query}
      defaultMatches={defaultMatches as boolean}
      {...(other as SingleQueryProps)}
    >
      {children}
    </ReactMedia>
  );
};

export default Media;
