function getStaticUrl(assetUrl: string) {
  if (process.env.NODE_ENV === 'development' && assetUrl[0] === '/') {
    console.error('Your image path should not include a / at the begining');
  }
  if (typeof window !== 'undefined') {
    return `${window.STATIC_URL}${assetUrl}`;
  }
  if (process.env.STATIC_URL) {
    return `${process.env.STATIC_URL}${assetUrl}`;
  }
  return `/static/${assetUrl}`;
}

export default getStaticUrl;
