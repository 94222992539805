import React from 'react';

import {
  Dialog,
  Container,
  Row,
  Col,
  Radio,
  PrimaryButton,
  colors,
} from '@crehana/ui';
import { useTranslation } from '@crehana/i18n';

import {
  UList,
  Filter as FilterIcon,
  Search,
  ArrowLeft2,
} from '@crehana/icons';

import { useFilterContext } from 'Jsx/CampaignLanding/hooks/useFilters';

import Filters from './Filters';
import SearchField from './SeachField';

enum VIEWS {
  FILTERS = 'filter',
  ORDER_BY = 'order_by',
}

/**
 * Remember, this components only have local states to show/hide components.
 * The click events on categories, softwares, etc are passed from the parent component to this children components
 */

const MobileFilters: React.FC<{ isBlackTheme: boolean }> = ({
  isBlackTheme,
}) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = React.useState({
    open: false,
    view: VIEWS.FILTERS,
  });
  const [showFullSearchInput, setShowFullSearchInput] = React.useState(false);

  const {
    categories,
    categorySelected,
    setCategory,
    // ,
    levels,
    levelSelected,
    selectLevel,
    // ,
    languages,
    languageSelected,
    selectLanguage,
    // ,
    softwares,
    softwaresSelected,
    selectSoftware,
    // ,
    orderByOptions,
    orderBySelected,
    setOrderBy,
    // ,
    searchTextDebouncedValue,
    clearAllFilters,
  } = useFilterContext();

  const closeModalAndUpdateQueryVariables = () => {
    setModalState({
      open: false,
      view: VIEWS.FILTERS,
    });
  };

  const openModal = (view: VIEWS) => () => {
    setModalState({
      open: true,
      view: view || VIEWS.FILTERS,
    });
  };

  const showSearchInput = () => setShowFullSearchInput(true);
  const hideSearchInput = () => setShowFullSearchInput(false);

  const isFilterButtonActive = Boolean(
    categorySelected ||
      levelSelected.length ||
      languageSelected.length ||
      softwaresSelected.length,
  );

  const filterSelectedCount =
    (categorySelected ? 1 : 0) +
    levelSelected.length +
    languageSelected.length +
    softwaresSelected.length;

  return (
    <div
      className={`relative  ${
        isBlackTheme
          ? 'bg-black'
          : 'bg-white border-gray-main border-t border-b border-solid'
      }  ${showFullSearchInput ? '' : 'py-16'}`}
    >
      <Container>
        {/* Search input */}
        {showFullSearchInput ? (
          <SearchField
            hideSearchInput={hideSearchInput}
            isBlackTheme={isBlackTheme}
          />
        ) : (
          // ==================== Filter/OrderBy buttons ===================== //
          <Row>
            {/* Filter button */}
            <Col xs={5}>
              <button
                type="button"
                className="flex items-center"
                onClick={openModal(VIEWS.FILTERS)}
                data-testid="page-filter-filterBtn"
              >
                <FilterIcon
                  size={20}
                  color={
                    isFilterButtonActive
                      ? colors.secondaryMain
                      : isBlackTheme
                      ? 'white'
                      : ''
                  }
                />
                <span
                  className={`font-subtitle2 ml-8 ${
                    isFilterButtonActive
                      ? 'text-secondary-main'
                      : isBlackTheme
                      ? 'text-white'
                      : 'text-base-main'
                  }`}
                >
                  {t('FILTER_LABEL')}
                  {filterSelectedCount > 0 ? `(${filterSelectedCount})` : ''}
                </span>
              </button>
            </Col>
            {/* Order button */}
            <Col xs={5}>
              <button
                type="button"
                className="flex items-center"
                onClick={openModal(VIEWS.ORDER_BY)}
                data-testid="page-filter-orderByBtn"
              >
                <UList
                  size={20}
                  color={
                    orderBySelected
                      ? colors.secondaryMain
                      : isBlackTheme
                      ? 'white'
                      : ''
                  }
                />
                <span
                  className={`font-subtitle2 ml-8 ${
                    orderBySelected
                      ? 'text-secondary-main'
                      : isBlackTheme
                      ? 'text-white'
                      : 'text-base-main'
                  }`}
                >
                  {t('ORDER_BY_LABEL')}
                  {orderBySelected ? '(1)' : ''}
                </span>
              </button>
            </Col>

            <Col xs={1} offset={{ xs: 1 }}>
              <button
                type="button"
                className="flex items-center"
                onClick={showSearchInput}
              >
                <Search
                  size={20}
                  color={
                    searchTextDebouncedValue.length > 1
                      ? colors.secondaryMain
                      : ''
                  }
                />
              </button>
            </Col>
          </Row>
        )}
        <Dialog
          open={modalState.open}
          onDismiss={closeModalAndUpdateQueryVariables}
          size="full"
          containerHTMLProps={{
            'data-testid': 'page-filter-mobile-filterBox',
          }}
          hideCloseBtn
          style={{ backgroundColor: isBlackTheme ? '#1C1C23' : '' }}
        >
          <Container className="fixed inset-0 h-screen max-h-screen w-screen overflow-auto">
            <button
              type="button"
              onClick={closeModalAndUpdateQueryVariables}
              className="flex w-full items-center mt-24"
            >
              <ArrowLeft2
                className="mr-12"
                size={12}
                color={colors.secondaryLight}
              />
              <span className="font-link text-secondary-light">
                {modalState.view === VIEWS.FILTERS
                  ? `
                     Filtrar por ${
                       filterSelectedCount > 0 ? `(${filterSelectedCount})` : ''
                     }
                  `
                  : `${t('ORDER_BY_LABEL')} por ${
                      orderBySelected ? '(1)' : ''
                    }`}
              </span>
            </button>
            {modalState.view === VIEWS.FILTERS ? (
              <div style={{ paddingBottom: 160 }}>
                <Filters
                  // categories props - radio buttons
                  categories={categories}
                  categorySelected={categorySelected}
                  setCategory={setCategory}
                  // level props - checkbox buttons
                  levels={levels}
                  levelSelected={levelSelected}
                  selectLevel={selectLevel}
                  // language filter - checkbox buttons
                  languages={languages}
                  languageSelected={languageSelected}
                  selectLanguage={selectLanguage}
                  // softwares
                  softwares={softwares}
                  softwaresSelected={softwaresSelected}
                  selectSoftware={selectSoftware}
                  // tag filters
                  orderByOptions={orderByOptions}
                  orderBySelected={orderBySelected}
                  setOrderBy={setOrderBy}
                  isBlackTheme={isBlackTheme}
                />
                <div
                  className={`${
                    isFilterButtonActive ? 'opacity-100' : 'opacity-0'
                  } fixed right-0 left-0 bottom-0 py-16 ${
                    isBlackTheme ? '' : 'bg-white'
                  } border-solid border-t border-gray-light`}
                  style={{
                    transform: isFilterButtonActive
                      ? 'translateY(0)'
                      : 'translateY(100%)',
                    transition:
                      'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                    backgroundColor: isBlackTheme ? '#1C1C23' : '',
                  }}
                >
                  <Container>
                    <button
                      type="button"
                      onClick={clearAllFilters}
                      className="w-full flex justify-center pb-16"
                    >
                      <span className="font-label text-base-lighter">
                        {t('CLEAN_FILTERS')}
                      </span>
                    </button>
                    <PrimaryButton
                      className="w-full"
                      label={t('SHOW_RESULTS')}
                      onClick={closeModalAndUpdateQueryVariables}
                      darkMode={isBlackTheme}
                    />
                  </Container>
                </div>
              </div>
            ) : (
              <div className="mt-24">
                {orderByOptions.map(orderByOption => {
                  const isSelected = Boolean(
                    orderBySelected &&
                      orderByOption.value === orderBySelected.value,
                  );

                  return (
                    <Radio
                      data-testid="page-filter-orderBy-radio"
                      key={orderByOption.value}
                      checked={isSelected}
                      label={orderByOption.label}
                      className="py-16 w-full"
                      inputProps={{
                        onClick: () => {
                          if (isSelected) {
                            setOrderBy(undefined);
                          } else {
                            setOrderBy(orderByOption);
                          }
                        },
                      }}
                      darkMode={isBlackTheme}
                      // just a dummy function to remove react warnings
                      onChange={() => {}}
                    />
                  );
                })}
              </div>
            )}
          </Container>
        </Dialog>
      </Container>
    </div>
  );
};

export default MobileFilters;
