import React from 'react';

import { install } from '@crehana/devtools';
import { Unpacked } from '@crehana/ts-types';

import getRenderMethod from 'Jsx/Utils/getRenderMethod';
import loadServiceWorker from 'Jsx/ServiceWorker/loadServiceWorker';
import { FeatureFlags, __INITIAL_STATE__TYPE } from 'Jsx/global.d';

import getBaseInitialState, {
  GetInitialStateInner,
  InitialStateResult,
  Options,
} from 'Jsx/Utils/getBaseInitialState';

import Layout, { LayoutProps } from './Layout';

export type Params<TInitialState = any, TRawPageData = {}, TExtraState = any> =
  {
    /** Main App component */
    App: React.FC<InitialStateResult<TInitialState & TExtraState>>;
    /** needed to apply the fallback for the specific translation group */
    i18nConfig?: Options['i18nConfig'];
    getInitialState?: GetInitialStateInner<TInitialState, TRawPageData>;
    /**
     * The window.__INITIAL__[initialStatePageKey] Ex: catalog, courseDetail,
     * lessons, checkout, etc.
     */
    initialStatePageKey?: string;
    layoutProps?: Omit<LayoutProps, 'children'>;
    extraState?: TExtraState;
    /** Enables Datadog RUM ask the Web Performance squad before enabling it */
    enableBrowserRUM?: boolean;
    /** A callback to trigger actions after the main React Application is rendered */
    onReactDOMRender?: () => void;
  };

const render = getRenderMethod('#mainNode');

function initializeApp<TInitialState = {}, TRawPageData = {}>({
  App,
  getInitialState = () => ({} as TInitialState),
  initialStatePageKey,
  layoutProps,
  i18nConfig,
  extraState: extraStateProp,
  enableBrowserRUM = false,
  onReactDOMRender,
}: Params<TInitialState, TRawPageData>): void {
  if (enableBrowserRUM) {
    import('./datadog/enableDatadogRUM')
      .then(({ default: enableDatadogRUM }) => enableDatadogRUM())
      .catch(() => console.error('Error loading Datadog RUM'));
  }
  const rawInitialState = window.__INITIAL_STATE_UNPARSED__
    ? JSON.parse(window.__INITIAL_STATE_UNPARSED__)
    : window.__INITIAL_STATE__;
  const initialState = getBaseInitialState<TInitialState, TRawPageData>(
    getInitialState,
    { i18nConfig },
  )(
    initialStatePageKey ? rawInitialState[initialStatePageKey] : undefined,
    rawInitialState.user,
    rawInitialState,
  );

  const mainJsx = (
    <Layout
      crehanaI18n={initialState.crehanaI18n}
      colorTheme={initialState.colorTheme}
      countryCode={initialState.countryPrefix}
      {...layoutProps}
      pageKey={layoutProps?.pageKey || i18nConfig?.pageKey}
      flagClassicShoppingCart={
        rawInitialState.feature_flags?.find(
          (f: Unpacked<FeatureFlags>) => f.key === 'flag_classic_shopping_cart',
        )?.is_active
      }
    >
      <div>
        <App {...initialState} {...extraStateProp} />
      </div>
    </Layout>
  );

  return render(
    mainJsx,
    document.querySelector('#mainNode') as HTMLDivElement,
    () => {
      window.addEventListener('DOMContentLoaded', () => {
        if (onReactDOMRender) {
          onReactDOMRender();
        }
      });
      // service worker setup
      window.addEventListener('load', () => {
        if (window.requestIdleCallback) {
          window.requestIdleCallback(loadServiceWorker);
        }

        const { host } = window.location;
        const hostIsNotProduction = host !== 'www.crehana.com';

        if (hostIsNotProduction) {
          require('react-select/dist/react-select.css');
          install();
        }
      });
    },
  );
}

export default initializeApp;
