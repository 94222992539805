import React from 'react';

import { colors } from '@crehana/ui';

import { TextField } from '@crehana/ui';

import { useTranslation } from '@crehana/i18n';
import { ClearO, Search } from '@crehana/icons';

const SearchField: React.FC<{
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  placeholder?: string;
  isBlackTheme: boolean;
}> = ({ value, onClear, onChange, placeholder, isBlackTheme = false }) => {
  const { t } = useTranslation();

  return (
    <TextField
      className="w-full"
      darkMode={isBlackTheme}
      inputProps={{
        placeholder: placeholder || t('SEARCH_PLACEHOLDER_2'),
        'aria-label': 'search',
      }}
      autoFocus
      noBorders
      innerClassName={`items-center rounded-5 ${
        isBlackTheme ? 'bg-base-lighter-dark-mode' : 'bg-gray-light'
      } `}
      startAdornment={
        <span className="mr-8">
          <Search size={16} color={colors.baseLighter} />
        </span>
      }
      endAdornment={
        value.length > 1 ? (
          <button
            type="button"
            className="block w-full h-full"
            onClick={onClear}
          >
            <ClearO size={18} />
          </button>
        ) : (
          <div />
        )
      }
      value={value}
      onChange={onChange}
    />
  );
};

export default SearchField;
