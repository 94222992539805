// @ts-nocheck
import CustomEvent from 'custom-event';

type Params = {
  target: HTMLElement;
  type: string;
  detail: {
    [key: string]: any;
  };
};

/**
 * dispatch custom events
 * source1: https://github.com/loryjs/lory/blob/master/src/utils/dispatch-event.js
 * custom-event lib docs: https://github.com/webmodules/custom-event
 *
 * @param  {HTMLElement} el         slideshow element
 * @param  {string}  type       custom event name
 * @param  {object}  detail     custom detail information
 */

export default function dispatchEvent({ target, type, detail }: Params) {
  const event = new CustomEvent(type, {
    bubbles: true,
    cancelable: true,
    detail,
  });

  target.dispatchEvent(event);
}
