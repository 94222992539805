import * as React from 'react';
import differenceInDays from 'date-fns/difference_in_days';

import { useMe } from '@crehana/web';
import { useTranslation } from '@crehana/i18n';

import { CourseProps } from 'Jsx/Shared/CourseCardV3/types';

import CustomWrapper from '../utils/CustomWrapper';
import getEnrollmentUrlWithSource from '../utils/getEnrollmentUrlWithSource';

import {
  Wrapper,
  Image,
  Tag,
  CardBody,
  Title,
  Enrollments,
  Professor,
  Prices,
  AddToCart,
  AddToPack,
  AddToCartMobile,
  BrasilianTag,
} from '../components';

import CourseCardV3Wrapper from './CourseCardV3Wrapper';

const CourseCardV3Full: React.FC<CourseProps> = ({
  title,
  url,
  metaDescription,
  affiliateCommission,
  affiliateUrl,
  dark,
  duration,
  enrollments,
  imagePlaceholder,
  isEnrollment,
  level,
  hidePreview,
  showOnlyAddToCardButton,
  prices,
  promoImage,
  promoImageAnimated,
  urlToBuyNow,
  validateAddCustomBundle,
  courseInPack,
  originalId,
  professor,
  preSale,
  trailerSource,
  customBundle,
  courseType,
  clickTitleHandler,
  lazyImageProps,
  categoryObj,
  principalSoftware,
  principalSoftwareObj,
  courseTag,
  imgxParams,
  shoppingCartAdded,
  urlToAddToCart,
  score,
  description,
  enrollmentUrl,
  enrollmentAccessPreviewUrl,
  audio,
  subtitle,
  hidePreviewCTA,
  hideEnrollmentSection,
  isInSlider,
  courseCardPage,
  fullCustomBundle,
  launchDate,
  // We must avoid using custom addToCart and removeFromCart functions.
  // Especial cases are for shopping cart and shopping cart pop up
  customInShoppingCart,
  customAddToCart,
  customRemoveFromCart,
  pageType,
  onClickBuyNow,
  onClickEnrollNow,
  courses,
  showProfessor = true,
  classification,
  languageTag,
  lazyImage = true,
  priority = false,
  showButtons = true,
  showPrice = true,
  isBundle = false,
  product,
  beforeBuy,
  beforePreview,
  showOnlyShoppingCartButton,
  showBuyNowButtonOnly,
  customOnClickImage,
  pageId,
  temporalAccessDurationLabel,
  isBlackTheme,
  renderCustomCallbackButtons,
  source,
  sourceDetail,
  jobToBeDoneData,
  isSavedCourse,
  customButtonLabel,
  coursePreviewProps,
}) => {
  const { languageCode } = useTranslation();
  const showBrasilianTag = languageCode === 'pt' && languageTag;
  const { me: user } = useMe();

  const numberOfDaysPublished =
    launchDate && differenceInDays(new Date(), launchDate);
  const isUserSofeMembership = Boolean(user && user?.isSofeMembership);
  const courseIsOneMonthOld = numberOfDaysPublished
    ? numberOfDaysPublished <= 30
    : true;
  const isUserSofeAndCourseIsOneMonthOld =
    isUserSofeMembership && courseIsOneMonthOld;

  return (
    <CourseCardV3Wrapper
      audio={audio}
      subtitle={subtitle}
      title={title}
      metaDescription={metaDescription}
      url={url}
      affiliateCommission={affiliateCommission}
      affiliateUrl={affiliateUrl}
      courseInPack={courseInPack}
      dark={dark}
      duration={duration}
      enrollments={enrollments}
      showOnlyAddToCardButton={showOnlyAddToCardButton}
      imagePlaceholder={imagePlaceholder}
      isEnrollment={isEnrollment}
      level={level}
      professor={professor}
      preSale={preSale}
      trailerSource={trailerSource}
      hidePreview={hidePreview}
      prices={prices}
      promoImage={promoImage}
      promoImageAnimated={promoImageAnimated}
      urlToBuyNow={urlToBuyNow}
      validateAddCustomBundle={validateAddCustomBundle}
      originalId={originalId}
      courseType={courseType}
      principalSoftware={principalSoftware}
      principalSoftwareObj={principalSoftwareObj}
      courseTag={courseTag}
      imgxParams={imgxParams}
      clickTitleHandler={clickTitleHandler}
      lazyImageProps={lazyImageProps}
      lazyImage={lazyImage}
      priority={priority}
      categoryObj={categoryObj}
      enrollmentUrl={getEnrollmentUrlWithSource({
        originalId,
        enrollmentUrl,
        source,
        sourceDetail,
      })}
      enrollmentAccessPreviewUrl={getEnrollmentUrlWithSource({
        originalId,
        enrollmentUrl: enrollmentAccessPreviewUrl,
        source,
        sourceDetail,
      })}
      source={source}
      sourceDetail={sourceDetail}
      score={score}
      description={description}
      customBundle={customBundle}
      urlToAddToCart={urlToAddToCart}
      shoppingCartAdded={shoppingCartAdded}
      customAddToCart={customAddToCart}
      customInShoppingCart={customInShoppingCart}
      customRemoveFromCart={customRemoveFromCart}
      hidePreviewCTA={hidePreviewCTA}
      isInSlider={isInSlider}
      courseCardPage={courseCardPage}
      fullCustomBundle={fullCustomBundle}
      pageType={pageType}
      pageId={pageId}
      onClickBuyNow={onClickBuyNow}
      onClickEnrollNow={onClickEnrollNow}
      bundleCoursesLength={courses ? courses.length : 0}
      showProfessor={showProfessor}
      classification={classification}
      languageTag={languageTag}
      product={product}
      beforeBuy={beforeBuy}
      beforePreview={beforePreview}
      isBundle={isBundle}
      showOnlyShoppingCartButton={showOnlyShoppingCartButton}
      showBuyNowButtonOnly={showBuyNowButtonOnly}
      customOnClickImage={customOnClickImage}
      temporalAccessDurationLabel={temporalAccessDurationLabel}
      isBlackTheme={isBlackTheme}
      isDark={isBlackTheme}
      renderCustomCallbackButtons={renderCustomCallbackButtons}
      isUserSofeAndCourseIsOneMonthOld={isUserSofeAndCourseIsOneMonthOld}
      jobToBeDoneData={jobToBeDoneData}
      customButtonLabel={customButtonLabel}
      coursePreviewProps={coursePreviewProps}
    >
      <Wrapper>
        <Tag />
        <Image>{showBrasilianTag && <BrasilianTag />}</Image>
        <CardBody>
          <Title
            isSavedCourse={isSavedCourse}
            source={source}
            sourceDetail={sourceDetail}
          />
          {showProfessor && <Professor />}
          {!hideEnrollmentSection && <Enrollments />}
          {showPrice && <Prices />}
          {showButtons &&
            !user?.hasOrganization &&
            (customBundle ? (
              <AddToPack />
            ) : (
              <CustomWrapper>
                <CustomWrapper className="hidden sm:block">
                  <AddToCart />
                </CustomWrapper>
                <CustomWrapper className="block sm:hidden">
                  <AddToCartMobile jobToBeDoneData={jobToBeDoneData} />
                </CustomWrapper>
              </CustomWrapper>
            ))}
        </CardBody>
      </Wrapper>
    </CourseCardV3Wrapper>
  );
};

export default CourseCardV3Full;
