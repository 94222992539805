import React from 'react';

import { useTranslation } from '@crehana/i18n';
import { PrimaryButton, Dialog } from '@crehana/ui';

const WarningDialog = () => {
  const { t } = useTranslation();

  return (
    <Dialog open size="s" hideCloseBtn>
      <div className="py-64 px-16 sm:px-64 md:px-96 text-center flex flex-col align-center">
        <p className="font-h4">
          {t(
            'WARNING_DIALOG_TITLE',
            'Lo sentimos, este cupón solo es válido para usuarios nuevos 😔',
          )}
        </p>
        <p className="font-body3 mt-16">
          {t(
            'WARNING_DIALOG_BODY',
            '¡Descuida! Aún tienes promociones disponibles para empezar a aprender.',
          )}
        </p>
        <PrimaryButton
          size="l"
          href="/premium/"
          className="mt-24"
          label={t('WARNING_DIALOG_BUTTON_LABEL', 'Descúbrelas')}
        />
      </div>
    </Dialog>
  );
};

export default WarningDialog;
