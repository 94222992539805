import React from 'react';

import { AppProps } from './types';

export const PageContext = React.createContext<AppProps | undefined>(undefined);

export const usePageContext = () => {
  const context = React.useContext(PageContext);

  if (context === undefined) {
    throw new Error('usePageContext must be used within a CountProvider');
  }

  return context;
};
