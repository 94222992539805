import React from 'react';
import _throttle from 'lodash/throttle';

const ConditionalRenders: React.FC<{
  topThreshold?: number;
  id?: string;
  children: (data: {
    isOnTop: boolean;
    isMounted: boolean;
  }) => React.ReactElement | null;
}> = ({ children, topThreshold = 0, id }) => {
  const refEl = React.useRef<HTMLDivElement>(null);
  const [filterBarTopPosition, setFilterBarTopPosition] = React.useState(NaN);
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    const checkForPosition = _throttle(() => {
      if (refEl.current) {
        setFilterBarTopPosition(refEl.current.getBoundingClientRect().top);
      }
    }, 100);

    setIsMounted(true);

    document.addEventListener('scroll', checkForPosition);
    return () => {
      document.removeEventListener('scroll', checkForPosition);
    };
  }, []);

  const isOnTop = filterBarTopPosition < topThreshold;

  return (
    <div id={id} ref={refEl}>
      {children({ isOnTop, isMounted }) || null}
    </div>
  );
};

export default ConditionalRenders;
