import initializeApp, { Params } from 'Jsx/Shared/LayoutV2/initializeApp';
import loadThirdParty from 'Jsx/Utils/thirdPartyLoader/loadThirdParty';

import App from './App';

import localeEs from './i18n/localeEs';
import localePt from './i18n/localePt';

import getInitialState from './getInitialState';

import { initialStatePageKey, CAMPAIGN_LANDING_PAGE_KEY } from './constants';
import { AppProps, RawPageData } from './types';

const appConfig: Params<AppProps, RawPageData> = {
  App,
  getInitialState,
  initialStatePageKey,
  i18nConfig: {
    pageKey: CAMPAIGN_LANDING_PAGE_KEY,
    defaults: {
      es: localeEs.es,
      pt: localePt.pt,
    },
  },
  extraState: {},
  layoutProps: {
    enableWebPerformanceTracking: true,
  },
  onReactDOMRender: loadThirdParty,
};

// if (module.hot) {
//   module.hot.accept('./App.tsx', function() {
//     initializeApp(appConfig);
//   });
// }

initializeApp(appConfig);
