import React, { FC } from 'react';

import type { BackgroundXPosition } from 'Jsx/CampaignLanding/types';

import GenericHero from './components/GenericHero';
import InfluencerHero from './components/InfluencerHero';
import TrackHero from './components/TrackHero';

type HeroSectionProps = {
  type: string;
  isBlackTheme: boolean;
  isStepsInDarkMode: boolean;
  minHeight: boolean;
  backgroundXPosition: BackgroundXPosition;
};

const HeroSection: FC<HeroSectionProps> = ({
  type,
  isBlackTheme,
  isStepsInDarkMode,
  minHeight,
  backgroundXPosition,
}) => {
  if (type === 'Influencer' || type === 'Free Influencer') {
    return <InfluencerHero />;
  }

  if (type === 'Specialization') {
    return <TrackHero />;
  }

  return (
    <GenericHero
      isBlackTheme={isBlackTheme}
      isStepsInDarkMode={isStepsInDarkMode}
      minHeight={minHeight}
      backgroundXPosition={backgroundXPosition}
    />
  );
};

export default HeroSection;
