// @ts-nocheck
import React from 'react';
import _debounce from 'lodash/debounce';

import { LANGUAGES } from '@crehana/ts-types';
import { parseQueryStrings } from '@crehana/utils';

import {
  Level,
  Category,
  Language,
  Software,
  OrderBy,
  DefaultFilters,
} from 'Jsx/CampaignLanding/types';

type DefaultOptions = {
  categories: Category[];
  levels: Level[];
  languages: Language[];
  softwares: Software[];
  orderByOptions: OrderBy[];
  search?: string;
};

type FilterState = {
  categories: Category[];
  categorySelected?: Category;
  setCategory: (category: Category | undefined) => void;
  // level props - radio buttons
  levels: Level[];
  levelSelected: Level[];
  selectLevel: (level: Level) => void;
  // language filter - radio buttons
  languages: Language[];
  languageSelected: Language[];
  selectLanguage: (language: Language) => void;
  // softwares
  softwares: Software[];
  softwaresSelected: Software[];
  selectSoftware: (oftware: Software) => void;
  // tag filters
  orderByOptions: OrderBy[];
  orderBySelected?: OrderBy;
  setOrderBy: (orderBy?: OrderBy) => void;
  clearAllFilters(): void;
  allFilterSelectedCount: number;
  searchTextDebouncedValue: string | '';
  setSearchTextDebouncedValue(search: string): void;
  geAllSelectedFiltersStringified(): {
    category: string;
    level: string;
    language: string;
    software: string;
    orderBy: string;
    searchText: string;
  };
};

/**
 * This is a helper function to:
 * - Add an element to array if it does not exists in the current array
 * - If the element exists, remove it
 */
function addOrRemoveElementToArray<T>({
  arr,
  el,
  keyToCompare,
}: {
  arr: Array<T>;
  el: T;
  keyToCompare: string;
}) {
  const isInTheArray = arr.some(
    arrEl => arrEl[keyToCompare] === el[keyToCompare],
  );

  // remove if the element is already in the array
  if (isInTheArray) {
    return arr.filter(arrEl => arrEl[keyToCompare] !== el[keyToCompare]);
  }
  // add the element to the original array and return it
  return [...arr, el];
}

/**
 * So, this stringifyArrayByKey({ arr: [{value: 'hello'}, {value: 'world'}], key: value })
 * will returns 'hello,world'.
 * We need this string-format in the graphql query courses variables
 */
function stringifyArrayByKey<T>({
  arr,
  key = 'value',
}: {
  arr: Array<T>;
  key?: string;
}) {
  return arr.length > 0
    ? arr.reduce((prev, next) => {
        return `${prev}${prev ? ',' : ''}${next[key]}`;
      }, '')
    : '';
}

/**
 * Find elements in an array using a string
 */

function findElementsByString<T>({
  arr,
  arrKey,
  str,
  callback,
}: {
  /* ex: ' */
  arr: T[];
  str: string;
  arrKey: string;
  callback(items: T[]): void;
}) {
  const itemsArr: T[] = [];

  str.split(',').forEach(levelSlug => {
    const item = arr.find(l => l[arrKey] === levelSlug);

    if (item) {
      itemsArr.push(item);
    }
  });

  if (itemsArr.length) {
    callback(itemsArr);
  }
}

const useFilters = (
  { levels, categories, languages, softwares, orderByOptions }: DefaultOptions,
  defaultFiltersSelected?: DefaultFilters,
  languageKey?: LANGUAGES,
): FilterState => {
  const languageSelected = languages.find(l => l.value === languageKey);

  const filtersSelected = defaultFiltersSelected || {
    categorySelected: undefined,
    levelsSelected: [],
    languagesSelected: languageSelected ? [languageSelected] : [],
    softwaresSelected: [],
  };
  const [categoriesState, setCategoriesState] = React.useState<{
    items: Category[];
    selected?: Category;
  }>({
    items: categories,
    selected: filtersSelected.categorySelected,
  });

  const [levelState, setLevelState] = React.useState<{
    items: Level[];
    selected: Level[];
  }>({
    items: levels,
    selected: filtersSelected.levelsSelected,
  });

  const [languageState, setLanguageState] = React.useState<{
    items: Language[];
    selected: Language[];
  }>({
    items: languages,
    selected: filtersSelected.languagesSelected,
  });

  const [softwaresState, setSoftwaresState] = React.useState<{
    items: Software[];
    selected: Software[];
  }>({
    items: softwares,
    selected: filtersSelected.softwaresSelected,
  });

  const [orderByState, setOrderByState] = React.useState<{
    items: OrderBy[];
    selected?: OrderBy;
  }>({
    items: orderByOptions,
    selected: undefined,
  });

  const [searchTextDebouncedValue, setSearchTextDebouncedValue] =
    React.useState('');

  const setSearchTextDebounced = React.useRef(
    _debounce((text: string) => {
      setSearchTextDebouncedValue(text);
    }, 200),
  );

  const setCategory = (category?: Category) => {
    setCategoriesState({
      ...categoriesState,
      selected: category,
    });
  };

  const selectLevel = (level: Level) => {
    const newArray = addOrRemoveElementToArray<Level>({
      arr: levelState.selected,
      el: level,
      keyToCompare: 'value',
    });

    setLevelState({
      ...levelState,
      selected: newArray,
    });
  };

  const selectLanguage = (language: Language) => {
    const newArray = addOrRemoveElementToArray<Language>({
      arr: languageState.selected,
      el: language,
      keyToCompare: 'value',
    });

    setLanguageState({
      ...languageState,
      selected: newArray,
    });
  };

  const selectSoftware = (software: Software) => {
    const newArray = addOrRemoveElementToArray<Software>({
      arr: softwaresState.selected,
      el: software,
      keyToCompare: 'value',
    });

    setSoftwaresState({
      ...softwaresState,
      selected: newArray,
    });
  };

  const setOrderBy = (orderBy?: OrderBy) => {
    setOrderByState({
      ...orderByState,
      selected: orderBy,
    });
  };

  const clearAllFilters = () => {
    setCategory(undefined);
    setLevelState(currentState => ({
      ...currentState,
      selected: [],
    }));
    setLanguageState(currentState => ({
      ...currentState,
      selected: [],
    }));
    setSoftwaresState({
      ...softwaresState,
      selected: [],
    });
    setOrderBy(undefined);
    setSearchTextDebouncedValue('');
  };

  const dependencyList = [
    categoriesState.selected ? categoriesState.selected.slug : '',
    levelState.selected.length,
    languageState.selected.length,
    softwaresState.selected.length,
    orderByState.selected ? orderByState.selected.value : '',
    searchTextDebouncedValue,
  ];
  const geAllSelectedFiltersStringified = React.useCallback(() => {
    return {
      category: categoriesState.selected ? categoriesState.selected.slug : '',
      level: stringifyArrayByKey<Level>({ arr: levelState.selected }),
      language: stringifyArrayByKey<Language>({ arr: languageState.selected }),
      software: stringifyArrayByKey<Software>({
        arr: softwaresState.selected,
      }),
      orderBy: orderByState.selected ? orderByState.selected.value : 'online',
      searchText: searchTextDebouncedValue,
    };
  }, dependencyList);

  React.useEffect(() => {
    const Stickyfill = require('stickyfilljs');
    const elements = document.querySelectorAll('.sticky');

    Stickyfill.add(elements);

    // rehydrate the filters from query params
    try {
      const filtersFromQueryParams = parseQueryStrings(window.location.search);

      if (filtersFromQueryParams) {
        const categoryQueryParam =
          typeof filtersFromQueryParams.categories === 'string'
            ? filtersFromQueryParams.categories
            : '';
        const softwareQueryParam =
          typeof filtersFromQueryParams.softwares === 'string'
            ? filtersFromQueryParams.softwares
            : '';
        const levelQueryParam =
          typeof filtersFromQueryParams.levels === 'string'
            ? filtersFromQueryParams.levels
            : '';
        const languageQueryParam =
          typeof filtersFromQueryParams.languages === 'string'
            ? filtersFromQueryParams.languages
            : '';

        if (categoryQueryParam) {
          const categorySelected = categoriesState.items.find(
            c => c.slug === categoryQueryParam,
          );

          if (categorySelected) {
            setCategory(categorySelected);
          }
        }

        if (levelQueryParam) {
          findElementsByString<Level>({
            arr: levelState.items,
            arrKey: 'value',
            str: levelQueryParam,
            callback: items => {
              setLevelState(prev => ({
                ...prev,
                selected: items,
              }));
            },
          });
        }

        if (languageQueryParam) {
          findElementsByString<Language>({
            arr: languageState.items,
            arrKey: 'value',
            str: languageQueryParam,
            callback: items => {
              setLanguageState(prev => ({
                ...prev,
                selected: items,
              }));
            },
          });
        }

        if (softwareQueryParam) {
          findElementsByString<Software>({
            arr: softwaresState.items,
            arrKey: 'value',
            str: softwareQueryParam,
            callback: items => {
              setSoftwaresState(prev => ({
                ...prev,
                selected: items,
              }));
            },
          });
        }
      }
    } catch (error) {
      console.error(
        'Error to rehydrate the filters from query params :(',
        error,
      );
    }
  }, []);

  /**
   * update the url query params if the filters changes
   * This hook will react to the clearAllFilters function too
   * Because if `queryParams.length === 0` we will set the location.search to ''
   */

  React.useEffect(() => {
    try {
      const filtersStringified = geAllSelectedFiltersStringified();
      const queryParams: string[] = [];

      if (filtersStringified.category) {
        queryParams.push(`categories=${filtersStringified.category}`);
      }

      if (filtersStringified.software) {
        queryParams.push(`softwares=${filtersStringified.software}`);
      }

      if (filtersStringified.level) {
        queryParams.push(`levels=${filtersStringified.level}`);
      }

      if (filtersStringified.language) {
        queryParams.push(`languages=${filtersStringified.language}`);
      }

      /**
       * Here we select the thrid party query params and concat it to the filters-query-params
       */
      const queryParamsObject = parseQueryStrings(window.location.search) || {};
      const customQueryParamsArr = [
        'categories',
        'softwares',
        'levels',
        'languages',
      ];
      const thirdPartyQueryParams = Object.keys(queryParamsObject)
        .filter(queryParam => !customQueryParamsArr.includes(queryParam))
        .map(queryParam => `${queryParam}=${queryParamsObject[queryParam]}`);
      const thirdPartySearch = thirdPartyQueryParams.length
        ? thirdPartyQueryParams.join('&')
        : '';

      const search = queryParams.length ? `${queryParams.join('&')}` : '';
      const searchWithThirdParyQueryParams = `?${search}${
        thirdPartySearch ? `&${thirdPartySearch}` : ''
      }`;
      const thirdPartyQueryParamsFormatted = thirdPartySearch
        ? `?${thirdPartySearch}`
        : '';

      window.history.pushState(
        null,
        document.title,
        window.location.pathname + search
          ? searchWithThirdParyQueryParams
          : thirdPartyQueryParamsFormatted,
      );
    } catch (error) {
      console.error('Error to sync filters values with query params', error);
    }
  }, dependencyList);

  const allFilterSelectedCount =
    (categoriesState.selected ? 1 : 0) +
    levelState.selected.length +
    languageState.selected.length +
    softwaresState.selected.length;

  return {
    categories: categoriesState.items,
    categorySelected: categoriesState.selected,
    setCategory,
    // level props - radio buttons
    levels: levelState.items,
    levelSelected: levelState.selected,
    selectLevel,
    // language filter - radio buttons
    languages: languageState.items,
    languageSelected: languageState.selected,
    selectLanguage,
    // softwares
    softwares: softwaresState.items,
    softwaresSelected: softwaresState.selected,
    selectSoftware,
    // order by
    orderByOptions: orderByState.items,
    orderBySelected: orderByState.selected,
    setOrderBy,

    allFilterSelectedCount,
    clearAllFilters,
    geAllSelectedFiltersStringified,
    searchTextDebouncedValue,
    setSearchTextDebouncedValue: setSearchTextDebounced.current,
  };
};

const FilterContext = React.createContext<FilterState | undefined>(undefined);

export const useFilterContext = () => {
  const filterState = React.useContext(FilterContext);

  if (filterState === undefined) {
    throw new Error(
      'useFilterContext must be used within a FilterContextProvider.',
    );
  }

  return filterState;
};

export const FilterContextProvider: React.FC<{
  initialOptions: DefaultOptions;
  languageKey: LANGUAGES;
  defaultFiltersSelected?: DefaultFilters;
}> = ({ children, initialOptions, defaultFiltersSelected, languageKey }) => {
  const filterState = useFilters(
    initialOptions,
    defaultFiltersSelected,
    languageKey,
  );

  return (
    <FilterContext.Provider value={filterState}>
      {children}
    </FilterContext.Provider>
  );
};

export default useFilters;
