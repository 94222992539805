import React, { FC } from 'react';
import styled from 'styled-components';

import { ShoppingCartAdded, ShoppingCartLight, Check } from '@crehana/icons';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';
import { InverseButton, PrimaryButton, colors } from '@crehana/ui';
import { useAuthDialog } from '@crehana/web/dist/ReactContexts/AuthDialog';
import { Intent } from '@crehana/layouts/dist/TrackingContext/types';
import { useTrackingContext } from '@crehana/web/dist/ReactContexts/TrackingContext';
import { useProductsContainer } from '@crehana/web/dist/ReactContexts/Products';

import { useTranslation } from '@crehana/i18n';

import { trackUserActivity, UserActivityEvent } from '@crehana/analytics';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

import buyProduct, { trackProduct } from 'Jsx/Utils/buyProduct';

import { checkIfIsRockstarCourse } from '../../utils';

const Wrapper = styled.div`
  & .icon-button {
    width: 48px;
    height: 48px;
  }
  & .buy-button {
    width: calc(100% - 56px);
  }
`;

const ButtonWithIcon = styled.button<{ isChecked: boolean; white: boolean }>`
  ${props => {
    if (props.white) {
      return `border: 1px solid ${props.theme.colors.white}`;
    }
    if (props.isChecked) {
      return `
        border: 1px solid ${props.theme.colors.gray.light};
      `;
    }
    return `border: 1px solid ${props.theme.colors.purple.main}`;
  }}
`;

const AddToCartMobile: FC<
  Partial<CourseCardProps> & {
    jobToBeDoneData?: Omit<Intent, 'urlSource'>;
  }
> = props => {
  const { setIntent } = useTrackingContext();
  const { t } = useTranslation('main-menu');
  const {
    originalId,
    title,
    addCourseToCart,
    removeCourseFromCart,
    loadingCart,
    inShoppingCart,
    prices,
    isEnrollment,
    urlToBuyNow,
    courseCardPage,
    pageType,
    courseType,
    enrollmentAccessPreviewUrl,
    onClickBuyNow,
    onClickEnrollNow,
    showOnlyAddToCardButton,
    product,
    beforeBuy,
    isBundle,
    enrollmentUrl,
    url,
    dark,
    temporalAccessDurationLabel,
    isUserSofeAndCourseIsOneMonthOld,
    jobToBeDoneData,
  } = props;
  const { setBasicDataForm } = useProductsContainer();
  const { showRegisterModal } = useAuthDialog();
  const { me: user } = useMe();

  const handleDirectBuy = () => {
    if (beforeBuy) {
      beforeBuy();
    }

    setTimeout(() => {
      if (onClickBuyNow) {
        if (product) {
          trackProduct(product);
        }

        // @ts-ignore
        onClickBuyNow({
          itemData: {
            itemId: originalId || 0,
            itemType: 'course',
          },
        });
      } else if (product && urlToBuyNow) {
        buyProduct(urlToBuyNow, product);
      } else {
        trackUserActivity({
          event: UserActivityEvent.CLICK,
          data: {
            product_id: originalId!,
            product_name: title!,
            item_type: 'course',
            item_path: window.location.pathname,
          },
          nextUrl: urlToBuyNow,
        });
      }
    }, 0);
  };

  const buyUrl = `/shopping-cart/course/${originalId}`;
  const classesUrl = `/clases/${originalId}/`;

  const getUrl = (): string => {
    if (
      isBundle ||
      (prices && prices.price === 0) ||
      courseType === 'temporal-access' ||
      courseType === 'free'
    ) {
      return enrollmentUrl as string;
    }
    return urlToBuyNow as string;
  };

  // @ts-ignore
  const handleBuy = e => {
    if (prices && prices.price === 0) {
      if (!user) {
        e.preventDefault();
        showRegisterModal(isBundle ? enrollmentUrl : getUrl());
      } else {
        window.location.href = getUrl() || '';
      }
    } else if (!courseCardPage || courseCardPage !== 'CAMPAIGN_LANDINGS') {
      handleDirectBuy();
    } else {
      window.location.href = getUrl() || '';
    }
  };

  const removeCourseFromCartWithTrack = () => {
    if (removeCourseFromCart) {
      removeCourseFromCart({
        courseId: originalId as number,
      });
    }
  };

  const addCourseToCartWithTrack = () => {
    if (addCourseToCart) {
      addCourseToCart({
        courseId: originalId as number,
      });
    }
  };

  const handleAddToCart = () => {
    if (inShoppingCart) {
      return removeCourseFromCartWithTrack();
    }
    if (addCourseToCart) {
      return addCourseToCartWithTrack();
    }

    window.location.href = buyUrl;
  };

  const styledCart = (
    <Wrapper className="flex items-center justify-between w-full mt-16">
      {/* @ts-ignore */}
      <ButtonWithIcon
        isChecked={inShoppingCart}
        className="flex items-center justify-center w-48 h-48 mr-12"
        onClick={() => handleAddToCart()}
        data-testid="add-to-cart"
        white={dark}
      >
        {!inShoppingCart ? (
          <ShoppingCartLight
            size={20}
            color={dark ? colors.white : colors.primaryMain}
          />
        ) : (
          <Check size={16} color={dark ? colors.white : colors.primaryMain} />
        )}
      </ButtonWithIcon>
      <InverseButton
        className="buy-button"
        // @ts-ignore
        onClick={e => handleBuy(e)}
        size="m"
        label={t('BUY_NOW', {
          defaultValue: 'Comprar ahora',
        })}
        white={dark}
      />
    </Wrapper>
  );

  const FullAddToCardButton = (
    <PrimaryButton
      className={`w-full mt-16 ${inShoppingCart ? 'bg-base-lighter' : ''}`}
      darkMode={dark}
      onClick={handleAddToCart}
      disabled={loadingCart}
      leftIcon={{
        component: inShoppingCart ? ShoppingCartAdded : ShoppingCartLight,
        size: inShoppingCart ? 20 : 24,
      }}
      label={
        inShoppingCart
          ? t('REMOVE_FROM_CART', {
              defaultValue: 'Quitar del carrito',
            })
          : t('ADD_ITEM', {
              defaultValue: 'Agregar al carrito	',
            })
      }
      size="m"
    />
  );

  if (isEnrollment) {
    return (
      <InverseButton
        href={classesUrl}
        full
        size="m"
        label={t('GO_TO_COURSE', {
          defaultValue: 'Ir al curso',
        })}
        white={dark}
      />
    );
  }

  // Button for premium users
  if (user && (user.isPremiumMember || user.membership)) {
    return (
      <InverseButton
        onClick={() => {
          if (onClickEnrollNow) {
            onClickEnrollNow();
          } else {
            window.location.href = enrollmentUrl as string;
          }
        }}
        white={dark}
        data-testid="watch-course"
        size="m"
        full
        label={t('SUBSCRIBE_NOW', {
          defaultValue: '¡Inscríbete ahora!',
        })}
        disabled={isUserSofeAndCourseIsOneMonthOld}
      />
    );
  }

  if (pageType === 'COURSE_ACCESS_PREVIEW' && courseType === 'freemium') {
    return (
      <InverseButton
        href={enrollmentAccessPreviewUrl}
        size="m"
        data-testid="watch-course"
        label={t('TRY_COURSE', {
          defaultValue: '¡Pruébalo ahora!',
        })}
        white={dark}
      />
    );
  }

  if (courseType === 'temporal-access' || user?.canEnrollFreemium) {
    return (
      <InverseButton
        full
        className="mt-16"
        href={url}
        data-testid="watch-course"
        disabled={isUserSofeAndCourseIsOneMonthOld}
        label={temporalAccessDurationLabel || 'Acceso gratuito por 24 horas'}
        white={dark}
      />
    );
  }

  if (courseType === 'free') {
    return (
      <InverseButton
        className="mt-16"
        onClick={() => {
          if (onClickEnrollNow) {
            onClickEnrollNow();
          } else if (user && user.originalId) {
            if (user.phoneNumber) {
              window.location.href = isBundle
                ? (enrollmentUrl as string)
                : getUrl();
            } else {
              setBasicDataForm({
                isOpen: true,
                // @ts-ignore
                phoneNumberOptions: { isRequired: true },
                redirectUrl: isBundle ? (enrollmentUrl as string) : getUrl(),
              });
            }
          } else {
            if (jobToBeDoneData) {
              setIntent(jobToBeDoneData);
            }

            showRegisterModal(
              isBundle ? enrollmentUrl : getUrl(),
              {},
              { isVersion2: true },
              'Course card - free course',
            );
          }
        }}
        white={dark}
        data-testid="watch-course"
        size="m"
        full
        label={t('SUBSCRIBE_FREE', {
          defaultValue: '¡Inscríbete gratis!',
        })}
      />
    );
  }

  if (courseType === 'membership') {
    return (
      <InverseButton
        className="mt-16"
        full
        size="m"
        white={dark}
        href={url}
        label="Acceso Premium"
      />
    );
  }

  if (checkIfIsRockstarCourse(courseType)) {
    return (
      <PrimaryButton
        onClick={handleDirectBuy}
        className="mt-16"
        full
        label={t('BUY_NOW', {
          defaultValue: 'Comprar ahora',
        })}
      />
    );
  }

  return showOnlyAddToCardButton ? FullAddToCardButton : styledCart;
};

export default AddToCartMobile;
