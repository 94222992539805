import { LANGUAGES } from '@crehana/ts-types';
import { notEmpty } from '@crehana/utils';

import { GetInitialStateInner } from 'Jsx/Utils/getBaseInitialState';

import { RawPageData, AppProps } from './types';
import { getCategoryName } from './utils';

const getInitialState: GetInitialStateInner<AppProps, RawPageData> = (
  {
    page,
    metaTags,
    is_coupon_campaign: isCouponCampaign,
    page_theme: pageTheme,
  },
  _,
  { COUNTRY_PREFIX, LANGUAGE_KEY, feature_flags: featureFlags },
) => {
  const countryPrefix = COUNTRY_PREFIX ? COUNTRY_PREFIX.toLowerCase() : '';
  const isSpecializationPage =
    page.pageType === 'TRACK TEMPORAL ACCESS' ||
    page.pageType === 'TRACK COMPLETE TEMPORAL ACCESS';
  const isCompleteTemporalAccess =
    page.pageType === 'TRACK COMPLETE TEMPORAL ACCESS';
  const isInfluencerLandingPage =
    page.partnerType === 'A_1' || page.partnerType === 'A_2';

  const isApplyCouponWithRequirements =
    page?.pageCouponFlow === 'FLOW_PRODUCT_REGISTER';
  const isApplyCoupon = page?.pageCouponFlow === 'FLOW_COUPON_DIRECT_APPLY';
  const isQuickPackEnrollment =
    (isApplyCouponWithRequirements || isApplyCoupon) &&
    page.pageType === 'FABRICA';
  const isQuickCourseEnrollment =
    (isApplyCouponWithRequirements || isApplyCoupon) &&
    page.pageType === 'CURSO';
  const isQuickEnrollment = isQuickPackEnrollment || isQuickCourseEnrollment;

  // ========================== Start ====================================//
  // TODO: remove this logic once the LATAM campaing has ended
  const bannedCategoriesForLatamLandingBR = [
    'soft-skills',
    'data-y-analitica',
    'manualidades-y-cocina',
    'transformacion-digital-e-innovacion',
    'ventas',
    'emprendimiento-y-negocios',
    'productividad-y-software',
    'negocios',
    'lifestyle',
    'arquitectura-digital',
    'servicio-y-atencion-al-cliente',
    'diseno-de-productos',
    'liderazgo',
    'web',
  ];
  const allowedSoftwaresForLatamLandingBR = [
    'procreate',
    'after-effects',
    'wordpress',
    'adobe-premiere',
    'adobe-photoshop',
    'adobe-illustrator',
  ];

  const _categoriesPage =
    page.categories?.filter(notEmpty).filter(category => {
      return !bannedCategoriesForLatamLandingBR.includes(category.slug);
    }) || [];

  const isLatamBR = COUNTRY_PREFIX === 'br' && page.slug === 'LATAM';
  const categories = isLatamBR
    ? _categoriesPage
    : page.categories?.filter(notEmpty) || [];

  const _softwares =
    page.softwares
      ?.filter(notEmpty)
      .filter(software =>
        allowedSoftwaresForLatamLandingBR.includes(software.slug),
      ) || [];

  const softwares = isLatamBR
    ? _softwares
    : page.softwares?.filter(notEmpty) || [];
  // ========================== END ====================================//

  const categoriesPage =
    categories.map(c => ({
      originalId: c.originalId!,
      name: getCategoryName({
        countryPrefix,
        originalName: c.name,
        slug: c.slug,
      }),
      slug: c.slug,
      subCategories:
        c.subCategories?.edges
          .map(s => s?.node)
          .filter(notEmpty)
          .map(s => ({
            slug: s.slug,
            originalId: s.originalId!,
            name: getCategoryName({
              countryPrefix,
              originalName: s.name,
              slug: s.slug,
            }),
          })) || [],
    })) || [];

  return {
    pageSlug: page.slug!,
    pageId: page.originalId!,
    pageType: page.pageType!,
    bannerMobile: page.bannerMobileUrl,
    bannerTablet: page.bannerTabletUrl,
    bannerDesktop: page.bannerPcUrl,
    logoImageUrl: page.logoImageUrl,
    logoColor: page.logoColor,
    title: page.title,
    titleColor: page.titleCustomColor,
    subtitle: page.subtitle,
    subtitleColor: page.subtitleCustomColor,
    categoriesPage,
    metaTags,
    description: page.description,
    customBundleItems: page.customBundleItems,
    timer: page.timer
      ? {
          identifier: page.timer.identifier,
          expireDate: page.timer.expireDate,
        }
      : {},
    timerCustomColor: page.timerCustomColor,
    timerBackgroundColor: page.timerBackgroundColor,
    showIconsSection: !!page.showIconsSection,
    pageSize:
      typeof window === 'undefined'
        ? 12
        : window.innerWidth <= 768
        ? 6
        : page.pageSize > 12
        ? 12
        : page.pageSize,
    levels:
      page.levels?.filter(notEmpty).map(l => ({
        value: l.slug,
        label: l.name,
      })) || [],
    orderByOptions:
      page.orderByOptions?.filter(notEmpty).map(t => ({
        value: t.slug!,
        label: t.name!,
      })) || [],
    softwaresFilter:
      softwares.map(s => ({
        value: s.slug,
        label: s.name,
      })) || [],
    prices: page.prices,
    pageSteps:
      page.pageSteps?.filter(notEmpty).map(p => ({
        content: p.content!,
        iconImageUrl: p.iconImageUrl!,
        title: p.title!,
      })) || [],
    stepsTitle: page.stepsTitle,
    languages: [
      {
        value: LANGUAGES.ES,
        label: countryPrefix === 'br' ? 'Espanhol' : 'Español',
      },
      {
        value: LANGUAGES.PT,
        label: countryPrefix === 'br' ? 'Português' : 'Portugués',
      },
    ],
    isAffiliate: !!page.isAffiliate,
    temporalAccessDurationLabel: page.temporalAccessDurationLabel,
    enableLinks: !!page.enableLinks,
    isCouponCampaign,
    ctaButtonText: page.ctaButtonText,
    bannerButonTextColor: page.bannerButonTextColor,
    bannerButtonText: page.bannerButtonText,
    legalsCopy: page.legalsCopy,
    isFreeInfluencerLandingPage: page.partnerType === 'A_2',
    isInfluencerLandingPage,
    campaignName: isSpecializationPage
      ? 'Specialization'
      : page.partnerTypeDisplay!,
    isSpecializationPage,
    isCompleteTemporalAccess,
    checkoutGallery:
      page.checkoutGallery?.filter(notEmpty).map(g => ({
        image: g.image!,
        subtitle: g.subtitle!,
        title: g.title!,
      })) || [],
    formIsComplete: !!page.formIsComplete,
    isNewUserRequired: !!page.isNewUserRequired,
    isUserRequired: !!page.isUserRequired,
    showPopupIsNewUserRequired: !!page.showPopupIsNewUserRequired,
    isPageForAbTest: page.slug
      ? ['arma-tu-pack', 'promos-pack'].includes(page.slug)
      : false,
    userLanguage: LANGUAGE_KEY,
    customButtonLabel: page.buttonLabel,
    pageTheme: pageTheme?.theme,
    stepTheme: pageTheme?.step_theme,
    featureFlags: {
      isEnableCallCenterInMainMenu: Boolean(
        featureFlags?.find(({ key }) => key === 'enable_call_center_menu_input')
          ?.is_active,
      ),
    },
    isApplyCouponWithRequirements,
    isQuickPackEnrollment,
    isQuickCourseEnrollment,
    isQuickEnrollment,
    tycTextColor: pageTheme?.tyc_text_color,
  };
};

export default getInitialState;
