import * as React from 'react';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const Tag: React.FC<Partial<CourseCardProps>> = props => {
  const { isOrganization, courseTag } = props;

  if (isOrganization) {
    return null;
  }

  if (courseTag) {
    return (
      <div
        className="flex items-center TagComponent rounded-5"
        css={`
          position: absolute;
          z-index: 2;
          left: 12px;
          top: 12px;
          padding: 4px 9px;
          background-color: ${courseTag.backgroundColor || '#06f'};
        `}
      >
        {courseTag.icon && (
          <img
            src={courseTag.icon}
            alt={courseTag.textLabel as string}
            style={{
              width: 'auto',
              height: 15,
            }}
            className="mr-4"
          />
        )}
        <span
          style={{ color: courseTag.textColor || '' }}
          className="font-caption"
        >
          {courseTag.textLabel}
        </span>
      </div>
    );
  }
  return null;
};

export default Tag;
