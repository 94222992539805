import * as React from 'react';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const CardBody: React.FC<Partial<CourseCardProps & { isMobile: boolean }>> =
  props => {
    const { children, isMobile, ...rest } = props;

    return (
      <div
        className={`CardBodyComponent relative rounded-b-10 ${
          rest.dark ? 'bg-base-light-dark-mode' : 'bg-white'
        } w-full ${isMobile ? 'px-0' : 'p-16'}`}
      >
        {React.Children.toArray(children).map(child =>
          React.cloneElement(child as React.ReactElement<any>, rest),
        )}
      </div>
    );
  };

export default CardBody;
