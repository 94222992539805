/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useTranslation } from '@crehana/i18n';

import Media from 'Jsx/Shared/ui/Media';
import { isTimeExpired } from 'Jsx/CampaignLanding/utils';

import { usePageContext } from 'Jsx/CampaignLanding/Context';

import { FilterContextProvider } from 'Jsx/CampaignLanding/hooks/useFilters';

import { useCoursesInBundleState } from 'Jsx/ContextApis/CoursesInBundle';

import Courses from '../components/Courses/Courses';
import MiniTimer from '../components/MiniTimer';

import ConditionalRenders from '../components/ConditionalRenders';
import MobileFilters from '../components/MobileFilters';
import TabletFilters from '../components/TabletFilters';
import DesktopFilters from '../components/DesktopFilters';

const StickyWrapper = styled.div<{
  top?: number;
}>`
  position: sticky;
  top: 0;
  ${props => props.theme.breakpoints.up('md')} {
    top: ${props => props.top || 0}px;
  }
`;

const CourseAndFilters: React.FC<{
  isBlackTheme: boolean;
  showWarningDialog(): void;
  isNotLogged: boolean;
}> = ({ isBlackTheme, showWarningDialog, isNotLogged }) => {
  const {
    timerBackgroundColor,
    timerCustomColor,
    userLanguage,
    categoriesPage: categories,
    levels,
    languages,
    softwaresFilter: softwares,
    orderByOptions,
    timer,
    title,
    isQuickPackEnrollment,
  } = usePageContext();

  const { coursesInBundle } = useCoursesInBundleState();

  const { t } = useTranslation();

  useEffect(() => {
    const Stickyfill = require('stickyfilljs');
    const elements = document.querySelectorAll('.sticky');

    Stickyfill.add(elements);
  }, []);
  const [showBorderBottom, setShowBorderBottom] =
    React.useState<boolean>(false);

  const handleScroll = () => {
    if (window.scrollY > 450) {
      setShowBorderBottom(true);
    } else {
      setShowBorderBottom(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const timerProps = {
    date: timer.expireDate,
    textColor: timerCustomColor,
    backgroundColor: timerBackgroundColor,
  };

  return (
    <FilterContextProvider
      languageKey={userLanguage}
      initialOptions={{
        levels,
        categories,
        languages,
        softwares,
        orderByOptions,
      }}
    >
      {/* The course list wrapper has a zIndex: 10 */}
      {/* So, we need to use a greater  z-index to not conflict with the course card */}
      <StickyWrapper className="sticky" top={0} style={{ zIndex: 15 }}>
        <ConditionalRenders topThreshold={24}>
          {({ isOnTop, isMounted }) =>
            isMounted ? (
              <React.Fragment>
                <Media from="xs" until="md">
                  {timer.expireDate &&
                    !isTimeExpired(timer.expireDate) &&
                    !isQuickPackEnrollment && (
                      <MiniTimer
                        date={timer.expireDate}
                        textColor={timerCustomColor}
                        backgroundColor={timerBackgroundColor}
                        show={isOnTop}
                      />
                    )}
                </Media>
                <Media from="xs" until="sm">
                  <MobileFilters isBlackTheme={isBlackTheme} />
                </Media>
                <Media from="sm" until="md">
                  <TabletFilters
                    title={t('CAMPAIGN_LANDING_FILTERS_TIMER_TITLE', {
                      defaultValue: title || '',
                    })}
                    timerProps={timerProps}
                    isFilterBarOnTop={isOnTop}
                    isBlackTheme={isBlackTheme}
                  />
                </Media>
                <Media from="md">
                  <DesktopFilters
                    title={t('CAMPAIGN_LANDING_FILTERS_TIMER_TITLE', {
                      defaultValue: title || '',
                    })}
                    timerProps={timerProps}
                    isFilterBarOnTop={isOnTop}
                    isBlackTheme={isBlackTheme}
                    showFixedCustomBundle={coursesInBundle?.length! > 0}
                  />
                </Media>
                <div
                  className="absolute inset-0"
                  css={`
                    z-index: -1;
                    transition: box-shadow 0.2s ease-in-out;
                    box-shadow: ${isOnTop
                      ? '0px 4px 18px rgba(92,84,122,0.14)'
                      : ''};
                  `}
                />
              </React.Fragment>
            ) : (
              <div style={{ height: 88 }} />
            )
          }
        </ConditionalRenders>
      </StickyWrapper>

      <div
        className="flex flex-col justify-center mx-auto w-full px-0 sm:px-32 lg:px-0"
        style={{ maxWidth: 1152 }}
      >
        <Courses
          isBlackTheme={isBlackTheme}
          showWarningDialog={showWarningDialog}
          showBorderBottom={!showBorderBottom}
          isNotLogged={isNotLogged}
        />
      </div>
    </FilterContextProvider>
  );
};

export default CourseAndFilters;
