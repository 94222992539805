// @ts-nocheck
import React, { useRef, useEffect } from 'react';

import InfiniteScroll from 'react-infinite-scroller';

import { useTranslation } from '@crehana/i18n';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';

import { OpenCoursePreviewProps } from '@crehana/web';

import Loader from 'Jsx/Inbox/ui/Loader';

import { formatProduct } from 'Jsx/Utils/buyProduct';

import {
  CourseCardSkeletonMobile,
  CourseCardSkeleton,
} from 'Jsx/Shared/CourseCardV3/skeletons';
import CourseCardMobile from 'Jsx/Shared/CourseCardV3/Variants/Mobile';
import { CourseCardV3 } from 'Jsx/Shared/CourseCardV3';
import Media from 'Jsx/Shared/ui/Media';
import views from 'Jsx/Auth/views';

import {
  CAMPAIGN_LANDING_AMPLITUDE_PAGE_NAME,
  ENROLLMENT_URL_SOURCES,
} from 'Jsx/CampaignLanding/constants';
import { Course, PAGE_TYPES } from 'Jsx/CampaignLanding/types';
import { usePageContext } from 'Jsx/CampaignLanding/Context';
import { CatalogTagNode } from 'Jsx/Types/__generated__/types';
import { CourseTag } from 'Jsx/Shared/CourseCardV3/types/Course';

export type NavigateToCourseDetail = (courseUrl: string) => () => void;

interface CourseListProps {
  courses: Array<Course>;
  loading: boolean;
  customFetchMoreData(): Promise<any>;
  navigateToCourseDetail: NavigateToCourseDetail;
  hasNextPage?: boolean;
  isCustomBundle: boolean;
  isCampaignLanding: boolean;
  onClickCustomBundleButton: (course: Course) => void;
  coursesInPack: Course[];
  checkIfItemIsInPack: (courseId: number) => boolean;
  onClickBuyNow: (nextUrl: string, next?: (() => any) | undefined) => void;
  isBlackTheme: boolean;
  renderFreeInfluencerButton?: (id: number) => JSX.Element;
  hasCoupon: boolean;
}

const skeletonsMobile = Array.from(Array(4)).map((_, i) => i);
const skeletonsDesktop = Array.from(Array(8)).map((_, i) => i);

const CourseList: React.FC<CourseListProps> = ({
  loading,
  hasNextPage,
  courses,
  isCustomBundle,
  customFetchMoreData,
  navigateToCourseDetail,
  coursesInPack,
  checkIfItemIsInPack,
  onClickCustomBundleButton,
  onClickBuyNow,
  isBlackTheme,
  renderFreeInfluencerButton,
  hasCoupon,
}) => {
  const {
    pageId,
    pageType,
    pageSlug,
    isAffiliate,
    temporalAccessDurationLabel,
    enableLinks,
    userLanguage,
    customBundleItems,
    customButtonLabel,
    isQuickEnrollment,
  } = usePageContext();

  const courseCardMobileOffsetHeight = useRef(194);
  const commonInfiniteScrollProps = {
    loadMore: customFetchMoreData,
    hasMore: hasNextPage,
    loader: (
      <Loader key="campaign-landing-course-list-loader" className="pb-32" />
    ),
    initialLoad: false,
    threshold: 820,
    useWindow: true,
  };
  const { me: user } = useMe();
  const { t } = useTranslation();

  useEffect(() => {
    if (pageSlug === 'sinenrollment') {
      // @ts-expect-error
      window.__auth_dialog_crehana_current_form = views.LOGIN_VIEW;
    }
  }, []);

  useEffect(() => {
    const courseCardMobile = document.getElementById('course-card-mobile');

    courseCardMobileOffsetHeight.current = courseCardMobile?.offsetHeight || 0;
  }, [courses.length]);

  const fullCustomBundle = coursesInPack.length === customBundleItems;

  const getCourseTag = (
    courseTag: CatalogTagNode | null | undefined,
  ): CourseTag | null => {
    if (
      courseTag &&
      courseTag.backgroundColor &&
      courseTag.textColor &&
      courseTag.textLabel
    ) {
      const formatedTag = {
        icon: null,
        name: courseTag?.name || '',
        textLabel: t('CAMPAIGN_LANDING_CARD_TAG_LABEL', {
          defaultValue: courseTag.textLabel,
        }),
        backgroundColor: t('CAMPAIGN_LANDING_CARD_TAG_BG_COLOR', {
          defaultValue: courseTag.backgroundColor || '',
        }),
        textColor: t('CAMPAIGN_LANDING_CARD_TAG_TEXT_COLOR', {
          defaultValue: courseTag.textColor || '',
        }),
      };

      return formatedTag;
    }
    return null;
  };

  const showPrice =
    // Temporarily hardcoded condition for LATAM campaign page
    pageSlug === 'LATAM'
      ? false
      : pageType !== 'COURSE TEMPORAL ACCESS' && !hasCoupon;

  if (loading) {
    return (
      <React.Fragment>
        <div className="block w-full sm:hidden">
          {skeletonsMobile.map(el => (
            <CourseCardSkeletonMobile
              key={el}
              className="mb-16"
              data-testid="page-course-skeleton-mobile"
              isBlackTheme={isBlackTheme}
            />
          ))}
        </div>
        <div className="hidden w-full sm:block">
          <div className="flex flex-wrap -m-4 sm:-m-8 md:-m-12">
            {skeletonsDesktop.map(el => (
              <div
                key={el}
                className="w-full p-4 mb-32 sm:p-8 md:p-12 sm:w-4/12 md:w-3/12"
              >
                <CourseCardSkeleton
                  data-testid="page-course-skeleton-desktop"
                  isBlackTheme={isBlackTheme}
                  dark={isBlackTheme}
                />
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }

  const coursePreviewProps: OpenCoursePreviewProps = {
    pageId,
    pageType,
    onlyTrailer:
      pageType === PAGE_TYPES.COURSE_TEMPORAL_ACCESS ||
      pageType === PAGE_TYPES.CUSTOM_BUNDLE ||
      pageType === PAGE_TYPES.PACKS_NEW_COUPON_FLOW ||
      hasCoupon,
  };

  /**
   * Remember, in SSR, the Media query matches and render the content by default.
   * So we need to implement the defaultMatches in some components to hide/show them in SSR.
   */
  return (
    <React.Fragment>
      <Media from="xs" until="sm">
        <React.Fragment>
          {/* Uncomment this code after the november 2021 */}
          {/* <button
            type="button"
            className="fixed flex items-start justify-end bg-white rounded-full z-dialog"
            onClick={scrollToNextPage}
            style={{ bottom: '16px', right: '14px' }}
          >
            <ArrowBottomO size={40} color={secondaryMain} />
          </button> */}
          <div id="course-card-mobile">
            {/** =============================== Mobile ============================== */}
            <InfiniteScroll
              {...commonInfiniteScrollProps}
              className="pt-24 pb-48"
            >
              {courses.map((course, index) => (
                <div className="mb-16" key={course.originalId!}>
                  <CourseCardMobile
                    validateAddCustomBundle={() =>
                      onClickCustomBundleButton(course)
                    }
                    courseInPack={checkIfItemIsInPack(course.originalId!)}
                    customBundle={isCustomBundle}
                    user={user}
                    userLanguage={userLanguage}
                    fullCustomBundle={fullCustomBundle}
                    clickTitleHandler={
                      enableLinks
                        ? navigateToCourseDetail(course.url!)
                        : () => {}
                    }
                    customOnClickImage={() =>
                      navigateToCourseDetail(course.url!)
                    }
                    product={formatProduct(course, 'course')}
                    {...course}
                    priority={index === 0}
                    courseTag={
                      isQuickEnrollment ? null : getCourseTag(course.courseTag)
                    }
                    lazyImage={false}
                    showBuyNowButtonOnly={isAffiliate}
                    showOnlyShoppingCartButton
                    pageType={pageType}
                    showPrice={showPrice}
                    pageId={pageId}
                    temporalAccessDurationLabel={temporalAccessDurationLabel}
                    onClickBuyNow={() => {
                      onClickBuyNow(`${course.urlToBuyNow}&page=${pageSlug}`);
                    }}
                    dark={isBlackTheme}
                    renderCustomCallbackButtons={
                      renderFreeInfluencerButton
                        ? () => renderFreeInfluencerButton(course)
                        : undefined
                    }
                    source={CAMPAIGN_LANDING_AMPLITUDE_PAGE_NAME}
                    sourceDetail={ENROLLMENT_URL_SOURCES.COURSE_LIST}
                    customButtonLabel={customButtonLabel}
                  />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </React.Fragment>
      </Media>
      {/** =============================== DESKTOP ============================== */}
      {/* defaultMatches={false} because we don't want to render this component in the server (SSR)
      The Mobile version has the two placeholders, desktop and mobile, and it show the correct one using tailwind classes (hidden/block) */}
      <Media from="sm" defaultMatches={false}>
        <div>
          <InfiniteScroll {...commonInfiniteScrollProps}>
            <div className="flex flex-wrap -mx-4 sm:-mx-8 md:-mx-12">
              {courses.map(course => (
                <div
                  className="w-full px-4 mb-40 sm:px-8 md:px-12 sm:w-4/12 md:w-3/12"
                  key={course.originalId!}
                >
                  <CourseCardV3
                    validateAddCustomBundle={() =>
                      onClickCustomBundleButton(course)
                    }
                    courseInPack={checkIfItemIsInPack(course.originalId!)}
                    customBundle={isCustomBundle}
                    user={user}
                    userLanguage={userLanguage}
                    fullCustomBundle={fullCustomBundle}
                    clickTitleHandler={
                      enableLinks
                        ? navigateToCourseDetail(course.url!)
                        : () => {}
                    }
                    {...course}
                    courseTag={
                      isQuickEnrollment ? null : getCourseTag(course.courseTag)
                    }
                    product={formatProduct(course, 'course')}
                    showOnlyShoppingCartButton
                    showBuyNowButtonOnly={isAffiliate}
                    pageType={pageType}
                    showPrice={showPrice}
                    pageId={pageId}
                    temporalAccessDurationLabel={temporalAccessDurationLabel}
                    onClickBuyNow={() => {
                      onClickBuyNow(`${course.urlToBuyNow}&page=${pageSlug}`);
                    }}
                    dark={isBlackTheme}
                    isBlackTheme={isBlackTheme}
                    renderCustomCallbackButtons={
                      renderFreeInfluencerButton
                        ? () => renderFreeInfluencerButton(course)
                        : undefined
                    }
                    source={CAMPAIGN_LANDING_AMPLITUDE_PAGE_NAME}
                    sourceDetail={ENROLLMENT_URL_SOURCES.COURSE_LIST}
                    customButtonLabel={customButtonLabel}
                    coursePreviewProps={coursePreviewProps}
                  />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </Media>
    </React.Fragment>
  );
};

export default CourseList;
