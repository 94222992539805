import React from 'react';

import { Star } from '@crehana/icons';

import { Score as ScoreProps } from '../../../types/Course';

const Score: React.FC<ScoreProps> = ({ average, dark }) => {
  return (
    <p className="flex items-center">
      <Star ignoreColor className="fill-current text-yellow-main" size={20} />
      <span
        className={`font-body4 ml-4 sm:ml-8 ${
          dark ? 'text-gray-main' : 'text-gray-dark'
        }`}
      >
        {(Math.ceil((average === 0 ? 5 : average) * 100) / 100).toFixed(2)}
      </span>
    </p>
  );
};

export default Score;
