import React from 'react';

import { Radio, colors } from '@crehana/ui';
import { UList } from '@crehana/icons';
import { useTranslation } from '@crehana/i18n';
import { useOnClickOutside } from '@crehana/react-hooks';

import { OrderByCommonProps } from 'Jsx/CampaignLanding/types';

const OrderByButton: React.FC<
  OrderByCommonProps & {
    onClick: () => void;
    className?: string;
    style?: React.CSSProperties;
    isOrderByOpen: boolean;
    isFilterBarOnTop: boolean;
    active: boolean;
    isBlackTheme: boolean;
  }
> = ({
  orderBySelected,
  orderByOptions,
  setOrderBy,
  isOrderByOpen,
  isFilterBarOnTop,
  onClick,
  className,
  active,
  style,
  isBlackTheme,
}) => {
  const { t } = useTranslation();
  const containerRef = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(
    containerRef,
    () => {
      if (isOrderByOpen) {
        onClick();
      }
    },
    [],
  );

  const innerClassName = isFilterBarOnTop ? 'py-24 px-32' : 'py-32 px-24';

  return (
    <div className="w-full relative" ref={containerRef}>
      <button
        type="button"
        className={`flex justify-center items-center  w-full ${
          className || ''
        }`}
        onClick={onClick}
        style={style}
        data-testid="page-filter-orderByBtn"
      >
        <UList
          size={16}
          color={active ? colors.secondaryMain : isBlackTheme ? 'white' : ''}
        />
        <span
          className={`font-subtitle3 ml-8 ${
            active
              ? 'text-secondary-main'
              : isBlackTheme
              ? 'text-white'
              : 'text-base-main'
          }`}
        >
          {t('ORDER_BY_LABEL')} {orderBySelected ? '(1)' : ''}
        </span>
      </button>
      <div
        className="absolute"
        data-testid="page-filter-desktop-orderByBox"
        css={`
          left: 0;
          background: ${isBlackTheme ? '#1c1c23' : '#fff'};
          min-width: ${isFilterBarOnTop ? 205 : 270}px;
          top: ${isFilterBarOnTop ? 62 : 44}px;
          box-shadow: ${
            isOrderByOpen ? '1px 10px 18px rgba(92,84,122,0.14)' : ''
          };
          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          box-shadow 0.3s ease-in-out;
          opacity: 0;
          transform: translateY(-15%);
          pointer-events: none;
          z-index: -1;
          ${
            isOrderByOpen &&
            `
          opacity: 1;
          transform: translateY(0);
          pointer-events: all;
          z-index: 1;
          `
          }
          `}
      >
        <div className={innerClassName}>
          {orderByOptions.map(orderByOption => {
            const isSelected = Boolean(
              orderBySelected && orderByOption.value === orderBySelected.value,
            );

            return (
              <Radio
                data-testid="page-filter-orderBy-radio"
                key={orderByOption.value}
                checked={isSelected}
                label={orderByOption.label}
                darkMode={isBlackTheme}
                className="py-8 w-full"
                inputProps={{
                  onClick: () => {
                    if (isSelected) {
                      setOrderBy(undefined);
                    } else {
                      setOrderBy(orderByOption);
                    }
                  },
                }}
                onChange={() => {}}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderByButton;
