import * as React from 'react';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const CustomWrapper: React.FC<
  Partial<CourseCardProps> & { className?: string }
> = props => {
  const { children, className, ...rest } = props;

  return (
    <div className={className}>
      {React.Children.toArray(children).map(child =>
        React.cloneElement(child as React.ReactElement<any>, rest),
      )}
    </div>
  );
};

export default CustomWrapper;
