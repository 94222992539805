import { useMutation } from '@apollo/client';

import {
  QuickEnrollMutation,
  QuickEnrollMutationVariables,
} from 'Jsx/CheckoutV4/components/QuickEnroll/graphql/types/QuickEnrollMutation';

import QUICK_ENROLL_MUTATION from 'Jsx/CheckoutV4/components/QuickEnroll/graphql/QuickEnrollMutation.payments.graphql';

const useQuickEnroll = () => {
  const [applyQuickEnrollmentMutation] = useMutation<
    QuickEnrollMutation,
    QuickEnrollMutationVariables
  >(QUICK_ENROLL_MUTATION, {
    context: { clientName: 'payments' },
  });

  return { applyQuickEnrollmentMutation };
};

export default useQuickEnroll;
