// @ts-nocheck
import { render as BaseRender, hydrate, Renderer } from 'react-dom';
import logger from 'Jsx/Utils/logger';

/**
 * Use this function if your component need to have SSR
 */
const getRenderMethod = (
  targetNode = '#mainNode',
  options: { ssr: boolean } = { ssr: true },
): Renderer => {
  if (typeof targetNode === 'string') {
    const root = document.querySelector(targetNode);

    if (root && root.innerHTML.trim() && options.ssr) {
      if (process.env.NODE_ENV === 'development') {
        logger({
          type: 'success',
          title: '[React SSR]',
          content: `Hydrating react components, using the ${targetNode} selector.`,
        });
      }
      return hydrate;
    }
    return BaseRender;
  }

  if (process.env.NODE_ENV === 'development') {
    logger({
      type: 'error',
      title: '[React SSR]',
      content: `getRenderMethod: You are passing an incorrect type for targetNode param. It should be string but the current value ${targetNode} is of type ${typeof targetNode}`,
    });
  }

  return BaseRender;
};

export const render = BaseRender;

export default getRenderMethod;
