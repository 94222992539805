import React from 'react';

import { LazyImage } from '@crehana/ui';
import { getImgixUrl } from '@crehana/utils';
import { ImgxParams } from '@crehana/ts-types';
import { PlayArrow, EyeOutline } from '@crehana/icons';
import { useCoursePreviewContext } from '@crehana/web';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const Image: React.FC<Partial<CourseCardProps>> = props => {
  const {
    children,
    lazyImageProps,
    lazyImage = true,
    priority,
    hidePreview,
    beforePreview,
    inShoppingCart,
    originalId,
    removeCourseFromCart,
    addCourseToCart,
    ...rest
  } = props;
  const { openCoursePreview } = useCoursePreviewContext();

  const imgxParams: ImgxParams = {
    w: 322,
    h: 219,
    ...rest.imgxParams,
  };

  const showPreview = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    if (beforePreview) {
      beforePreview();
    }

    const handleAddToCart = () => {
      const buyUrl = `/shopping-cart/course/${originalId}`;

      if (inShoppingCart && removeCourseFromCart) {
        return removeCourseFromCart({
          courseId: originalId as number,
        });
      }
      if (addCourseToCart) {
        return addCourseToCart({
          courseId: originalId as number,
        });
      }
      window.location.href = buyUrl;
    };

    if (!hidePreview) {
      openCoursePreview({
        // @ts-expect-error
        course: props,
        ...props.coursePreviewProps,
        shoppingCartProp: {
          inShoppingCart: !!inShoppingCart,
          handleAddToCart,
          onDismissPreview: () => {},
        },
      });
    } else if (rest.url) {
      window.location.href = rest.url;
    }
  };

  const onClickImage = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.customOnClickImage) {
      event.preventDefault();
      // only run if it come from the props
      props.customOnClickImage(event);
    }
  };

  return (
    <a
      href={rest.url ? rest.url : ''}
      onClick={onClickImage}
      className="block ImageComponent"
      arial-label={rest.title}
    >
      <div
        className="relative ImageComponentWrapper"
        css={`
          &:hover {
            .before-preview {
              opacity: 0;
            }
          }
          .before-preview {
            z-index: 3;
            opacity: 1;

            &:hover {
              opacity: 0;
              transition: ease-in 0.3s;
            }
          }
          .preview {
            z-index: 4;
            opacity: 0;
            border-radius: 10px 10px 0 0;
            &:hover {
              opacity: 1;
              transition: ease-in 0.3s;
            }
          }
          @media (min-width: 1140px) {
            height: ${!lazyImage ? '182px' : 'unset'};
          }
        `}
      >
        {!hidePreview && (
          <>
            <div
              onClick={showPreview}
              onKeyPress={showPreview}
              className="absolute items-center justify-center hidden w-full h-full md:flex bg-base-main-80 preview"
            >
              <EyeOutline size={24} color="#fff" />
              <span className="ml-8 text-white tw-font-link2">Ver Trailer</span>
            </div>
            <div className="absolute items-center justify-center hidden w-full h-full md:flex before-preview">
              <div
                style={{
                  backgroundColor: 'rgba(75, 34, 244, 0.6)',
                }}
                className="flex items-center justify-center w-32 h-32 rounded-full"
              >
                <PlayArrow
                  size={18}
                  ignoreColor
                  className="text-white fill-current"
                />
              </div>
            </div>
          </>
        )}
        {priority ? (
          <div
            css={`
              background-image: url(${rest.imagePlaceholder});
              background-position: center;
              background-size: cover;
              padding-bottom: 70%;
            `}
            className="relative course-image"
          >
            <img
              src={getImgixUrl(
                rest.promoImageAnimated || rest.promoImage || '',
                {
                  auto: 'format',
                  q: 55,
                  ...imgxParams,
                },
              )}
              alt={rest.title}
              className="absolute inset-0 object-cover w-full h-full"
            />
          </div>
        ) : (
          <LazyImage
            placeholder={rest.imagePlaceholder}
            src={(rest.promoImageAnimated || rest.promoImage) as string}
            imgxParams={imgxParams}
            css={`
              height: auto;
              padding-bottom: 70%;
            `}
            alt={rest.title as string}
            className="course-image"
            {...lazyImageProps}
            imgClassName={`${
              lazyImageProps?.imgClassName || ''
            } object-cover rounded-t-10`}
          />
        )}
        {React.Children.toArray(children).map(child =>
          React.cloneElement(child as React.ReactElement<any>, rest),
        )}
      </div>
    </a>
  );
};

export default Image;
