import facebookPixelProvider from './facebookPixelProvider';
import gtagProvider from './gtagProvider';
import gtmProvider from './gtmProvider';
import thirdPartyLoader from './thirdPartyLoader';

const requestIdleCallbackShim =
  window.requestIdleCallback ||
  function (cb) {
    const start = Date.now();

    return setTimeout(() => {
      cb({
        didTimeout: false,
        timeRemaining() {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };

/**
 * This is a utiity function to load everything and avoid boilerplate
 *
 * This function should only be used in entries that extend the react_template_optimized.html
 */
function loadThirdParty() {
  requestIdleCallbackShim(() => {
    thirdPartyLoader({
      providers: [gtagProvider(), gtmProvider(), facebookPixelProvider()],
    });
  });
}

export default loadThirdParty;
