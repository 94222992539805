import React, { FC, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import { notEmpty } from '@crehana/utils';

import GET_CUSTOM_BUNDLE_QUERY from './graphql/GetCustomBundle.graphql';
import ADD_COURSE_IN_CUSTOM_BUNDLE_MUTATION from './graphql/AddCourseInCustomBundleMutation.graphql';
import REMOVE_COURSE_FROM_CUSTOM_BUNDLE_MUTATION from './graphql/RemoveCourseFromCustomBundle.graphql';

import {
  GetCustomBundle,
  GetCustomBundleVariables,
  GetCustomBundle_customBundle_page_prices as Prices,
  GetCustomBundle_customBundle_courses as Course,
  GetCustomBundle_customBundle_page_timer as Timer,
} from './graphql/types/GetCustomBundle';

import {
  AddCourseInCustomBundleMutation,
  AddCourseInCustomBundleMutationVariables,
} from './graphql/types/AddCourseInCustomBundleMutation';
import {
  RemoveCourseFromCustomBundle,
  RemoveCourseFromCustomBundleVariables,
} from './graphql/types/RemoveCourseFromCustomBundle';

type BundleInfo = {
  slug: string;
  size: number;
  prices: Omit<Prices, '__typename'> | null;
  formatedPrice: string;
  ctaButtonText?: string;
  isDark?: boolean;
  timer: Timer | null;
};

type Initialize = Omit<BundleInfo, 'formatedPrice'>;
interface State {
  bundleInfo: BundleInfo;
  coursesInBundle: (Course | null)[] | null;
  isCoursesBundleComplete: boolean;
  toggleCourseInBundle(course: Course): void;
  addCourseToCustomBundle(course: Course): void;
  hasInitialized: boolean;
  initialize(options: Initialize): void;
  customBundle: CustomBundle;
  cleanCustomBundle(): void;
  addAllCoursesInCustomBundle: (ids: number[], courses: Course[]) => void;
  checkIfItemIsInPack: (courseId: number) => boolean;
  customBundleTitle?: string;
}

interface CustomBundle {
  originalId: number | null;
  redirectUrl: string;
  isCompleted: boolean;
  loading: boolean;
}

export const BundleContext = React.createContext<State | undefined>(undefined);

interface CoursesInBundleProviderProps {
  campaignSlug?: string;
  ctaButtonText?: string;
  customBundleSize?: number;
  isDark?: boolean;
  getCoursesInBundleSize?: (value: number) => void;
  customBundleTitle?: string;
}

const CoursesInBundleProvider: FC<CoursesInBundleProviderProps> = ({
  campaignSlug = '',
  children,
  customBundleSize,
  ctaButtonText,
  isDark,
  getCoursesInBundleSize,
  customBundleTitle,
}) => {
  const [bundleInfo, setBundleInfo] = useState<BundleInfo>({
    slug: '',
    size: customBundleSize || 0,
    prices: { price: 0, currencySymbol: 'US$' },
    formatedPrice: '',
    ctaButtonText,
    isDark,
    timer: null,
  });

  const [hasInitialized, setInitialized] = useState(false);

  const [customBundle, setCustomBundle] = useState<CustomBundle>({
    originalId: 0,
    redirectUrl: '',
    isCompleted: false,
    loading: true,
  });

  const [coursesInBundle, setCoursesInBundle] = useState<
    (Course | null)[] | null
  >([]);

  const [getBundleData, { data: customBundleData, loading }] = useLazyQuery<
    GetCustomBundle,
    GetCustomBundleVariables
  >(GET_CUSTOM_BUNDLE_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [removeCustomBundleItemMutation] = useMutation<
    RemoveCourseFromCustomBundle,
    RemoveCourseFromCustomBundleVariables
  >(REMOVE_COURSE_FROM_CUSTOM_BUNDLE_MUTATION);
  const [addedCustomBundleItem] = useMutation<
    AddCourseInCustomBundleMutation,
    AddCourseInCustomBundleMutationVariables
  >(ADD_COURSE_IN_CUSTOM_BUNDLE_MUTATION);

  useEffect(() => {
    if (!loading && customBundleData?.customBundle) {
      const { isActive, originalId, courses, redirectUrl, page, isCompleted } =
        customBundleData.customBundle;

      setCustomBundle({
        originalId,
        redirectUrl: redirectUrl || '',
        isCompleted: !!isCompleted,
        loading: false,
      });
      if (isActive) {
        if ((courses?.length || 0) > 0) {
          setCoursesInBundle(courses);
        }

        if (page?.prices) {
          setBundleInfo(prev => ({
            ...prev,
            prices: page.prices,
            formatedPrice: `${page.prices?.currencySymbol} 
                ${page.prices?.price?.toLocaleString()}`,
            size: page.customBundleItems || 0,
            slug: campaignSlug!,
            timer: page?.timer || null,
          }));
        }
        if (getCoursesInBundleSize) {
          getCoursesInBundleSize(courses?.length || 0);
        }

        setInitialized(true);
      }
    } else {
      setBundleInfo(prev => ({
        ...prev,
        size: customBundleSize || 0,
        slug: campaignSlug,
        ctaButtonText,
        isDark,
      }));
    }
  }, [loading, customBundleData, campaignSlug, getCoursesInBundleSize]);

  const checkIfItemIsInPack = (courseId: number) => {
    return Boolean(coursesInBundle?.find(el => el?.originalId === courseId));
  };
  const addCourseToCustomBundle = (course: Course) => {
    setCoursesInBundle(prev => (prev ? [...prev, course] : []));
    setCustomBundle(prev => ({ ...prev, loading: true }));
    addedCustomBundleItem({
      variables: {
        pageSlug: campaignSlug,
        courseIds: [course.originalId!],
      },
    }).then(({ data }) => {
      if (
        data?.addCustomBundleItem?.success &&
        data.addCustomBundleItem.customBundle
      ) {
        const {
          redirectUrl,
          customBundle: { originalId, courses, isCompleted },
        } = data.addCustomBundleItem;
        const addedCourse = courses?.find(
          c => c?.originalId === course.originalId,
        );

        if (getCoursesInBundleSize) {
          getCoursesInBundleSize(courses?.length || 0);
        }
        if (addedCourse) {
          setCoursesInBundle(
            prev =>
              prev
                ?.filter(notEmpty)
                .map(c =>
                  c.originalId === addedCourse.originalId
                    ? { ...c, trailerSource: addedCourse.trailerSource }
                    : c,
                ) || [],
          );
        }

        const prices = data.addCustomBundleItem.customBundle.page?.prices;

        if (prices) {
          setBundleInfo(prev => ({
            ...prev,
            prices,
            formatedPrice: `${
              prices?.currencySymbol
            }${prices.price?.toLocaleString()}`,
          }));
        }

        setCustomBundle({
          originalId,
          redirectUrl: redirectUrl || '',
          isCompleted: !!isCompleted,
          loading: false,
        });
      }
    });
  };
  const removeCourseFromPack = (courseId: number) => {
    if (customBundle?.originalId && courseId) {
      setCustomBundle(prev => ({ ...prev, loading: true }));
      removeCustomBundleItemMutation({
        variables: {
          customBundleId: customBundle.originalId.toString(),
          courseIds: [courseId],
        },
      }).then(({ data }) => {
        if (data?.removeCustomBundleItem?.success) {
          const courses =
            coursesInBundle?.filter(el => el?.originalId !== courseId) || [];

          setCoursesInBundle(courses);
          if (getCoursesInBundleSize) {
            getCoursesInBundleSize(courses?.length || 0);
          }
          setCustomBundle({
            ...customBundle,
            redirectUrl: '',
            isCompleted: false,
            loading: false,
          });
        }
      });
    }
  };
  const toggleCourseInBundle = (course: Course) => {
    if (checkIfItemIsInPack(course.originalId!)) {
      removeCourseFromPack(course.originalId!);
    } else {
      addCourseToCustomBundle(course);
    }
  };
  const cleanCustomBundle = () => {
    if (
      coursesInBundle &&
      coursesInBundle.length > 0 &&
      customBundle?.originalId
    ) {
      setCustomBundle(prev => ({ ...prev, loading: true }));
      let courseIds: number[] = [];

      for (let i = 0; i < coursesInBundle.length; i += 1) {
        courseIds = [...courseIds, coursesInBundle?.[i]?.originalId!];
      }
      removeCustomBundleItemMutation({
        variables: {
          customBundleId: customBundle.originalId.toString(),
          courseIds,
        },
      }).then(({ data }) => {
        if (data?.removeCustomBundleItem?.success) {
          setCoursesInBundle([]);
          setCustomBundle({
            ...customBundle,
            redirectUrl: '',
            isCompleted: false,
            loading: false,
          });
        }
      });
    }
  };
  const addAllCoursesInCustomBundle = (
    courseIds: number[],
    courses: Course[],
  ) => {
    setCustomBundle(prev => ({ ...prev, loading: true }));
    addedCustomBundleItem({
      variables: {
        pageSlug: campaignSlug,
        courseIds,
      },
    }).then(({ data }) => {
      if (
        data?.addCustomBundleItem?.success &&
        data.addCustomBundleItem.customBundle
      ) {
        const {
          redirectUrl,
          customBundle: { originalId, isCompleted },
        } = data.addCustomBundleItem;

        setCustomBundle({
          originalId,
          redirectUrl: redirectUrl || '',
          isCompleted: !!isCompleted,
          loading: false,
        });
        setCoursesInBundle(courses);
      }
    });
  };

  const initialize = ({
    slug,
    size,
    prices,
    ctaButtonText: ctaLabel,
  }: Initialize) => {
    setBundleInfo({
      slug,
      size,
      prices,
      formatedPrice: `${
        prices?.currencySymbol
      }${prices?.price?.toLocaleString()}`,
      ctaButtonText: ctaLabel,
      timer: null,
    });
    setInitialized(true);
  };

  useEffect(() => {
    if (campaignSlug) {
      getBundleData({
        variables: {
          pageSlug: campaignSlug,
        },
      });
      setInitialized(true);
    }
  }, [campaignSlug]);

  return (
    <BundleContext.Provider
      value={{
        coursesInBundle,
        toggleCourseInBundle,
        bundleInfo,
        hasInitialized,
        initialize,
        isCoursesBundleComplete: coursesInBundle?.length === bundleInfo.size,
        customBundle,
        cleanCustomBundle,
        addCourseToCustomBundle,
        addAllCoursesInCustomBundle,
        checkIfItemIsInPack,
        customBundleTitle,
      }}
    >
      {children}
    </BundleContext.Provider>
  );
};

export const useCoursesInBundleState = () => {
  const context = React.useContext(BundleContext);

  if (context === undefined) {
    throw new Error(
      'useCoursesInBundleState must be used within a CoursesInBundleProvider',
    );
  }

  return context;
};

export default CoursesInBundleProvider;
