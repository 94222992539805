import { createUrlWithSourceQueryParams } from 'Jsx/Analytics/Amplitude';

import {
  CAMPAIGN_LANDING_AMPLITUDE_PAGE_NAME,
  ENROLLMENT_URL_SOURCES,
} from './constants';
import { CourseDetailEnrollmentUrlSources } from './types';

// This is a temporary translation function, this should be improved later via BACKEND
export const getCategoryName = ({
  countryPrefix,
  originalName,
  slug,
}: {
  countryPrefix: string;
  originalName: string;
  slug: string;
}): string => {
  if (countryPrefix !== 'br') return originalName;

  if (slug === 'marketing') return 'Marketing Digital';
  if (slug === 'marketing-digital') return 'Marketing Digital';
  if (slug === 'publicidad') return 'Anúncio';

  if (slug === 'growth-marketing') return 'Growth Marketing';
  if (slug === 'negocios') return 'O negócio';
  if (slug === 'data') return 'Data';
  if (slug === 'liderazgo') return 'Liderança';
  if (slug === 'emprendimiento') return 'Empreendedorismo';
  if (slug === 'innovacion') return 'Inovação';
  if (slug === 'ventas') return 'Vendas';
  if (slug === 'retail') return 'Retail';
  if (slug === 'arquitectura-de-la-informacion')
    return 'Arquitetura de informação';
  if (slug === 'recursos-humanos') return 'Recursos humanos';
  if (slug === 'customer-research') return 'Customer Research';
  if (slug === 'soft-technology-tools') return 'Soft Technology Tools';
  if (slug === 'ilustracion-dibujo') return 'Ilustração e desenho';
  if (slug === 'dibujo') return 'Desenho';
  if (slug === 'ilustracion-digital') return 'Ilustração digital';
  if (slug === 'video') return 'Video';
  if (slug === 'postproduccion') return 'Pós-produção';
  if (slug === 'locucion') return 'Locução';
  if (slug === 'produccion') return 'Produção';
  if (slug === 'diseno') return 'Projeto';
  if (slug === 'branding') return 'Branding';
  if (slug === 'diseno-editorial') return 'Desenho editorial';
  if (slug === 'retoque-digital') return 'Retoque digital';
  if (slug === 'diseno-industrial') return 'Desenho industrial';
  if (slug === 'lettering') return 'Lettering';
  if (slug === 'tipografia') return 'Tipografia';
  if (slug === 'diseno-de-moda') return 'Design de Moda';
  if (slug === 'diseno-grafico') return 'Desenho gráfico';
  if (slug === 'fotografia') return 'Fotografia';
  if (slug === 'fotografia-de-moda-y-belleza')
    return 'Fotografia de moda e beleza';
  if (slug === 'retoque-fotografico') return 'Retoque fotográfico';
  if (slug === 'fotografia-de-alimentos') return 'Fotografia de comida';
  if (slug === 'fotografia-de-familia') return 'Fotografia de família';
  if (slug === 'fotografia-artistica') return 'Fotografia artística';
  if (slug === 'fotografia-lifestyle') return 'Fotografia lifestyle';
  if (slug === 'fundamentos-de-fotografia')
    return 'Noções básicas de fotografia';
  if (slug === 'web') return 'Web';
  if (slug === 'front-end') return 'Front-end';
  if (slug === 'ecommerce') return 'Ecommerce';
  if (slug === 'diseno-mobile') return 'Design móvel';
  if (slug === 'ui-ux') return 'UI-UX';
  if (slug === 'diseno-web') return 'Desenho web';
  if (slug === 'animacion-3d') return 'Animação 3D';
  if (slug === '3d') return '3D';
  if (slug === 'animacion-2d') return 'Animação 2D';
  if (slug === 'animacion-3d') return 'Animação 3D';
  if (slug === 'videojuegos') return 'Videogames';
  if (slug === 'direccion-de-arte') return 'Direção da arte';
  if (slug === 'arquitectura-digital') return 'Arquitetura digital';
  if (slug === 'diseno-de-interiores') return 'Design de interiores';
  if (slug === 'manualidades') return 'Trabalhos manuais';
  if (slug === 'papercraft') return 'Papercraft';
  if (slug === 'manualidades') return 'Trabalhos manuais';
  if (slug === 'bordado-costura') return 'Bordado e costura';
  if (slug === 'musica') return 'Música';
  if (slug === 'produccion-musical') return 'Produção musical';
  if (slug === 'lifestyle') return 'Lifestyle';
  if (slug === 'escritura') return 'Escrevendo';

  if (slug === 'desarrollo-web-y-tecnologia')
    return 'Desenvolvimento web e tecnologia';
  if (slug === 'data-y-analitica') return 'Dados e análises';
  if (slug === 'manualidades-y-cocina') return 'Artesanato e culinária';
  if (slug === 'transformacion-digital-e-innovacion')
    return 'Transformação digital e inovação';
  if (slug === 'emprendimiento-y-negocios')
    return 'Empreendedorismo e negócios';
  if (slug === 'productividad-y-software') return 'Produtividade e software';
  if (slug === 'modelado-y-animacion-3d') return 'Modelagem e animação 3D';
  if (slug === 'servicio-y-atencion-al-cliente')
    return 'Atendimento e suporte ao cliente';
  if (slug === 'diseno-de-productos') return 'Projeto de produtos';
  if (slug === 'administracion-y-finanzas') return 'Administração e Finanças';

  return originalName;
};

export const enrollmentUrlWithSourceDetail = {
  SOURCES: ENROLLMENT_URL_SOURCES,
  getUrl: ({
    originalId,
    enrollmentUrl,
    sourceDetail,
  }: {
    originalId?: number | null;
    enrollmentUrl?: string | null;
    sourceDetail: CourseDetailEnrollmentUrlSources;
  }) => {
    const url = enrollmentUrl || `/enrollment/${originalId}/`;

    return createUrlWithSourceQueryParams({
      url,
      source: CAMPAIGN_LANDING_AMPLITUDE_PAGE_NAME,
      sourceDetail,
    });
  },
};

export const urlWithSourceDetail = {
  SOURCES: ENROLLMENT_URL_SOURCES,
  getUrl: ({
    url,
    sourceDetail,
  }: {
    url: string;
    sourceDetail: CourseDetailEnrollmentUrlSources;
  }) => {
    return createUrlWithSourceQueryParams({
      url,
      source: CAMPAIGN_LANDING_AMPLITUDE_PAGE_NAME,
      sourceDetail,
    });
  },
};

export const isTimeExpired = (date: string) =>
  !new Date(date).getTime() ||
  new Date(date).getTime() - new Date().getTime() <= 0;
