import * as React from 'react';

import { useProductsContainer } from '@crehana/web/dist/ReactContexts/Products';

import { CourseProps } from 'Jsx/Shared/CourseCardV3/types';

const CourseCardV3Wrapper: React.FC<CourseProps> = React.memo(
  ({ children, ...course }) => {
    const {
      shoppingCartState: { loadingCart, courseIdsInCart },
      addCourseToCart,
      removeCourseFromCart,
    } = useProductsContainer();

    return (
      <React.Fragment>
        {React.Children.toArray(children).map(child => (
          <React.Fragment key={course.originalId!}>
            {React.cloneElement(child as React.ReactElement<any>, {
              ...course,
              loadingCart,
              // eslint-disable-next-line no-nested-ternary
              inShoppingCart: course.customAddToCart
                ? course.customInShoppingCart
                : course.originalId
                ? courseIdsInCart.includes(course.originalId)
                : false,
              addCourseToCart: course.customAddToCart
                ? course.customAddToCart
                : addCourseToCart,
              removeCourseFromCart: course.customRemoveFromCart
                ? course.customRemoveFromCart
                : removeCourseFromCart,
            })}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  },
);

export default CourseCardV3Wrapper;
