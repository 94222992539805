import * as React from 'react';
import styled from 'styled-components';

import { redMain } from 'crehana-ui/styles/colors';

import { useTranslation } from '@crehana/i18n';

import useCountdown from 'Jsx/Shared/Hooks/useCountdown';
import { isTimeExpired } from 'Jsx/CampaignLanding/utils';

const StyledWrapper = styled.div<{
  disabled: boolean;
  timerBackgroundColor?: string | null;
}>`
  background-color: ${props => props.timerBackgroundColor || redMain};
  opacity: ${props => (props.disabled ? 0 : 1)};
  max-width: 224px;
  transition: opacity 0.3s ease-in-out;
  ${props => props.theme.breakpoints.up('md')} {
    max-width: 280px;
  }
`;

const TimerItem: React.FC<{
  count: string;
  label: string;
  textColor: string;
  showDots?: boolean;
  dataTestId: string;
}> = ({ count, label, textColor, showDots = true, dataTestId }) => (
  <React.Fragment>
    <div className="flex flex-col">
      <span
        className="font-h4 md:mb-4"
        style={{ color: textColor }}
        data-testid={dataTestId}
      >
        {count}
      </span>
      <span className="font-label" style={{ color: textColor }}>
        {label}
      </span>
    </div>
    {showDots && (
      <span className="font-h4 mr-4 ml-4" style={{ color: textColor }}>
        {' : '}
      </span>
    )}
  </React.Fragment>
);

type CountdownTimerProps = {
  date: string;
  timerCustomColor?: string | null;
  timerBackgroundColor?: string | null;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  date,
  timerCustomColor,
  timerBackgroundColor,
}) => {
  const { t } = useTranslation();
  const [mounted, setMounted] = React.useState(false);
  const { days, hours, minutes, seconds } = useCountdown(new Date(date));

  React.useEffect(() => {
    setMounted(true);
  }, []);

  // if date has experied just return null
  if (isTimeExpired(date)) {
    return null;
  }

  const textColor = timerCustomColor || '#fff';

  return (
    <StyledWrapper
      disabled={!mounted}
      timerBackgroundColor={timerBackgroundColor}
      className="w-full text-center flex flex-col items-center py-8 md:p-12 rounded-10"
      data-testid="page-timer"
    >
      <span
        className="font-label"
        style={{ color: timerCustomColor || '#fff' }}
        data-testid="page-timer-title"
      >
        {Number(days) === 0 && Number(hours) < 2
          ? t('TIMER_TITLE_1')
          : t('TIMER_TITLE_2')}
      </span>
      <div className="mt-4 md:mt-8 flex flex-nowrap">
        <TimerItem
          label="Días"
          count={days}
          textColor={textColor}
          dataTestId="page-timer-days"
        />
        <TimerItem
          label="Hrs"
          count={hours}
          textColor={textColor}
          dataTestId="page-timer-hours"
        />
        <TimerItem
          label="Min"
          count={minutes}
          textColor={textColor}
          dataTestId="page-timer-minutes"
        />
        <TimerItem
          label="Seg"
          count={seconds}
          textColor={textColor}
          showDots={false}
          dataTestId="page-timer-seconds"
        />
      </div>
    </StyledWrapper>
  );
};

export default CountdownTimer;
