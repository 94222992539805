import * as React from 'react';
import styled from 'styled-components';

import { Container } from '@crehana/ui';

const Skeleton = styled.div<{ dark?: boolean; black?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props =>
    props.black
      ? '#20222C'
      : props.dark
      ? 'rgba(160,167,203,.32)'
      : props.theme.colors.gray.lighter};
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 66%;
  z-index: 4;
`;

const Title = styled(Skeleton)<{ dark?: boolean; black?: boolean }>`
  position: relative;
  height: 16px;
  background-color: ${props =>
    props.black
      ? '#20222C'
      : props.dark
      ? 'rgba(160,167,203,.2)'
      : props.theme.colors.gray.lighter};
`;

const DesktopWrapper = styled.div<{ dark?: boolean; isMobile?: boolean }>`
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  background-color: ${props => {
    if (props.isMobile && props.dark) {
      return '#000';
    }

    if (props.dark) {
      return props.theme.colors.base['light-dark-mode'];
    }

    return '#fff';
  }};
  width: 100%;
`;

const SecondTitleLine = styled(Skeleton)`
  position: relative;
  height: 15px;
  margin-top: 12px;
`;

const CardBody = styled.div<{ black?: boolean }>`
  width: 100%;
  padding: 24px;
  ${props =>
    props.black &&
    `
    background-color: ${props.theme.colors.base['light-dark-mode']};
  `}
`;

const Price = styled(Skeleton)`
  position: relative;
  width: 40%;
  margin-top: 16px;
  height: 24px;
`;

const Duration = styled(Skeleton)`
  position: relative;
  height: 12px;
  width: 40%;
  margin-top: 20px;
`;

const AddToCart = styled.div<{ dark?: boolean; black?: boolean }>`
  position: relative;
  background: white;
  height: 48px;
  background-color: ${props =>
    props.black
      ? '#20222C'
      : props.dark
      ? 'rgba(160,167,203,.2)'
      : props.theme.colors.gray.main};
`;

const WrapperMobile = styled.div`
  .imageMobile {
    width: 88px;
    height: 88px;
    padding-bottom: 0;
    margin-right: 8px;
  }
  .cardBody {
    width: calc(100% - 88px);
    padding: 0;
    border-radius: 10px;
  }
`;

interface CardSkeletonPorps {
  className?: string;
  dark?: boolean;
  isBlackTheme?: boolean;
}

export const CourseCardSimpleSkeletion = ({ className }: CardSkeletonPorps) => (
  <DesktopWrapper className={className}>
    <ImageContainer>
      <Skeleton />
    </ImageContainer>
    <CardBody>
      <Title />
      <SecondTitleLine />
      <Duration />
      <Price />
    </CardBody>
  </DesktopWrapper>
);

export const CourseCardSkeleton = ({
  className,
  dark,
  isBlackTheme,
  ...rest
}: CardSkeletonPorps) => (
  <DesktopWrapper className={className} dark={dark} {...rest}>
    <ImageContainer>
      <Skeleton dark={dark} />
    </ImageContainer>
    <CardBody black={isBlackTheme}>
      <Title dark={dark} />
      <SecondTitleLine dark={dark} />
      <Duration dark={dark} />
      <Price dark={dark} />
      <AddToCart className="mt-24" dark={dark} />
    </CardBody>
  </DesktopWrapper>
);

export const CourseCardSkeletonMobile = ({
  className,
  dark,
  isBlackTheme,
  ...rest
}: CardSkeletonPorps) => (
  <div
    className={`w-full ${isBlackTheme ? 'bg-black' : 'bg-white'}  ${className}`}
    {...rest}
  >
    <Container className="relative py-12">
      <WrapperMobile className="flex">
        <ImageContainer className="imageMobile">
          <Skeleton dark={dark} black={isBlackTheme} className="rounded-10" />
        </ImageContainer>
        <CardBody className="cardBody">
          <Title dark={dark} black={isBlackTheme} />
          <SecondTitleLine dark={dark} black={isBlackTheme} />
          <Price dark={dark} black={isBlackTheme} />
        </CardBody>
      </WrapperMobile>
      <AddToCart className="mt-16" dark={dark} black={isBlackTheme} />
    </Container>
  </div>
);
