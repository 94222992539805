import React from 'react';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';

import useTrustPulseNotification from 'Jsx/Shared/Hooks/useTrustPulseNotification';

const TrustPulseNotification = () => {
  const { loading, me } = useMe();
  const { isOpenTrustPulse, trustPulseUser, SocialNotification } =
    useTrustPulseNotification({
      isActive: !loading && me?.userTagV2 === null,
    });

  return (
    <SocialNotification
      isOpen={isOpenTrustPulse}
      user={trustPulseUser}
      notificationMessage="¡Ha hecho una compra!"
      className="hidden lg:flex"
    />
  );
};

export default TrustPulseNotification;
