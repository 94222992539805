import { createUrlWithSourceQueryParams } from '@crehana/analytics';

const getEnrollmentUrlWithSource = ({
  originalId,
  enrollmentUrl,
  source,
  sourceDetail,
}: {
  originalId?: number | null;
  enrollmentUrl?: string | null;
  source: string;
  sourceDetail?: string;
}) => {
  const url = enrollmentUrl || `/enrollment/${originalId}/`;

  return createUrlWithSourceQueryParams({
    url,
    source,
    sourceDetail: `${sourceDetail ? `${sourceDetail} - ` : ''}CourseCard`,
  });
};

export default getEnrollmentUrlWithSource;
