/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Container } from '@crehana/ui';
import { useTranslation } from '@crehana/i18n';

import getStaticUrl from 'Jsx/Utils/getStaticUrl';
import { Course } from 'Jsx/CampaignLanding/types';
import useQueryCourse from 'Jsx/CampaignLanding/hooks/useCourseQuery';

import RetryFetch from '../RetryFetch';

export type NavigateToCourseDetail = (course: Course) => () => void;

export type OnClickBuyNow = {
  itemData: { itemType: string; itemId: number };
};

type Props = {
  pageSlug: string;
  pageSize: number;
  searchText?: string;
  children(params: {
    courses: Course[];
    loading: boolean;
    hasNextPage?: boolean;
    customFetchMoreData(): Promise<any>;
  }): React.ReactElement;
  isBlackTheme: boolean;
};

const CoursesNotFoundFallback: React.FC<Props> = ({
  pageSlug,
  pageSize,
  searchText,
  children,
  isBlackTheme,
}) => {
  const { t } = useTranslation();

  const { courses, loading, error, hasNextPage, customFetchMoreData, refetch } =
    useQueryCourse({
      page: pageSlug,
      pageSize,
    });

  if (error) {
    return <RetryFetch refetch={() => refetch()} />;
  }

  return (
    <div>
      <Container className="mb-12 md:mb-24">
        <div className="flex items-center w-full pt-12">
          <h4
            className={`font-h4 ${
              isBlackTheme ? 'text-white' : 'text-base-main'
            }`}
          >
            {t('NO_RESULTS')}
            {searchText && searchText.length > 1 && ` "${searchText}"`}
          </h4>
          <img
            width="25"
            height="25"
            className="hidden ml-4 md:flex"
            src={getStaticUrl('img/emoji/crying_face_emoji.png')}
            // @ts-ignore
            alt={t('NO_RESULTS', { searchText })}
          />
        </div>
      </Container>
      {children({
        courses,
        loading,
        hasNextPage,
        customFetchMoreData,
      })}
    </div>
  );
};

export default CoursesNotFoundFallback;
