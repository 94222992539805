import React from 'react';
import { Helmet } from 'react-helmet';

import { getImgxUrl } from '@crehana/utils';

import { BackgroundXPosition } from 'Jsx/CampaignLanding/types';

interface HeroImageProps {
  srcDesktop: string;
  srcTablet: string;
  srcMobile: string;
  backgroundXPosition?: BackgroundXPosition;
}

const HeroImage: React.FC<HeroImageProps> = ({
  srcDesktop,
  srcMobile,
  srcTablet,
  backgroundXPosition = 'center',
}) => {
  const mobileImage = getImgxUrl(srcMobile, {
    auto: 'format',
    h: 434,
    q: 75,
  });
  const tabletImage = getImgxUrl(srcTablet, {
    auto: 'format',
    h: 434,
    q: 75,
  });
  const desktopImage = getImgxUrl(srcDesktop, {
    auto: 'format',
    h: 426,
    q: 75,
  });

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href={mobileImage}
          as="image"
          media="(max-width: 767px)"
        />
        <link
          rel="preload"
          href={tabletImage}
          as="image"
          media="(min-width: 768px) and (max-width: 1365px)"
        />
        <link
          rel="preload"
          href={desktopImage}
          as="image"
          media="(min-width: 1366px)"
        />
      </Helmet>
      <div className="absolute top-0 h-full w-full">
        <picture>
          <source
            srcSet={mobileImage}
            type="image/webp"
            media="(max-width: 767px)"
          />
          <source
            srcSet={tabletImage}
            media="(min-width: 768px) and (max-width: 1365px)"
          />
          <source srcSet={desktopImage} media="(min-width: 1366px)" />
          <img
            src={desktopImage}
            alt=""
            className="h-full w-full object-cover"
            decoding="async"
            css={`
              object-position: center;
              @media (min-width: 1024px) {
                object-position: ${backgroundXPosition};
              }
            `}
          />
        </picture>
      </div>
    </>
  );
};

export default HeroImage;
