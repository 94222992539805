import React, { FC } from 'react';
import styled from 'styled-components';

import { ShoppingCartLight, ShoppingCartAdded } from '@crehana/icons';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';
import { useAuthDialog } from '@crehana/web/dist/ReactContexts/AuthDialog';
import { useTrackingContext } from '@crehana/web/dist/ReactContexts/TrackingContext';
import { useProductsContainer } from '@crehana/web/dist/ReactContexts/Products';
import { Intent } from '@crehana/layouts/dist/TrackingContext/types';
import { useTranslation } from '@crehana/i18n';

import { InverseButton, PrimaryButton, IconButton, colors } from '@crehana/ui';

import { trackUserActivity, UserActivityEvent } from '@crehana/analytics';

import buyProduct, { trackProduct } from 'Jsx/Utils/buyProduct';

import getEnrollmentUrlWithSource from 'Jsx/Shared/CourseCardV3/utils/getEnrollmentUrlWithSource';
import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

import { checkIfIsRockstarCourse } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledIconButton = styled(({ inShoppingCart, darkMode, ...rest }) => (
  <IconButton {...rest} />
))<{
  inShoppingCart: boolean | undefined;
}>`
  border-color: ${props =>
    props.darkMode
      ? props.theme.colors.white
      : props.theme.colors.primary.main} !important;
  ${props =>
    props.inShoppingCart &&
    `
      background: ${props.theme.colors.primary.main};
    `}
  @media (min-width: 1024px) {
    transform: translate(0, 0);
    transition: all 0.2s ease-out !important;
    &:hover {
      transform: translate(0, -2px);
      -webkit-box-shadow: 0px 2px 6px rgba(92, 84, 122, 0.2);
      box-shadow: 0px 2px 6px rgba(92, 84, 122, 0.2);
    }
  }
`;

const AddToCart: FC<
  Partial<
    CourseCardProps &
      Pick<CourseCardProps, 'source'> & {
        jobToBeDoneData?: Omit<Intent, 'urlSource'>;
      }
  >
> = props => {
  const { setIntent } = useTrackingContext();
  const {
    inShoppingCart,
    loadingCart,
    courseType,
    isEnrollment,
    originalId,
    pageType,
    enrollmentAccessPreviewUrl,
    showOnlyAddToCardButton,
    showOnlyShoppingCartButton,
    isBundle,
    enrollmentUrl,
    showBuyNowButtonOnly,
    onClickEnrollNow,
    url,
    dark,
    pageId,
    temporalAccessDurationLabel,
    renderCustomCallbackButtons,
    isUserSofeAndCourseIsOneMonthOld,
    source,
    sourceDetail,
    jobToBeDoneData,
    customButtonLabel,
  } = props;
  const isRockstarCourse = checkIfIsRockstarCourse(courseType);
  const { setBasicDataForm } = useProductsContainer();
  const { t } = useTranslation('main-menu');
  const { showRegisterModal } = useAuthDialog();
  const { me: user } = useMe();

  const removeCourseFromCartWithTrack = () => {
    const { removeCourseFromCart } = props;

    if (removeCourseFromCart) {
      removeCourseFromCart({
        courseId: originalId as number,
      });
    }
  };

  const addCourseToCartWithTrack = () => {
    const { addCourseToCart } = props;

    if (addCourseToCart) {
      addCourseToCart({
        courseId: originalId as number,
      });
    }
  };

  const handleAddToCart = () => {
    const { addCourseToCart } = props;
    const buyUrl = `/shopping-cart/course/${originalId}`;

    if (inShoppingCart) {
      return removeCourseFromCartWithTrack();
    }
    if (addCourseToCart) {
      return addCourseToCartWithTrack();
    }

    window.location.href = buyUrl;
  };

  const handleDirectBuyButton = () => {
    const { urlToBuyNow, onClickBuyNow, product, beforeBuy, title } = props;

    if (beforeBuy) {
      beforeBuy();
    }

    setTimeout(() => {
      if (onClickBuyNow) {
        if (product) {
          trackProduct(product);
        }

        onClickBuyNow({
          itemId: originalId as number,
          itemType: 'course',
          itemData: {
            itemId: originalId || 0,
            itemType: 'course',
          },
        });
      } else if (product && urlToBuyNow) {
        buyProduct(urlToBuyNow, product);
      } else {
        trackUserActivity({
          event: UserActivityEvent.CLICK,
          data: {
            product_id: originalId!,
            product_name: title!,
            item_type: 'course',
            item_path: window.location.pathname,
          },
          nextUrl: urlToBuyNow,
        });
      }
    }, 0);
  };

  const getEnrollmentOrBuyUrl = () => {
    const { prices } = props;
    const buyUrl = `/shopping-cart/course/${originalId}`;

    if (
      isBundle ||
      (prices && prices.price === 0) ||
      courseType === 'temporal-access' ||
      courseType === 'free'
    ) {
      return enrollmentUrl;
    }
    return buyUrl;
  };

  const courseEnrollmentUrl = getEnrollmentOrBuyUrl();
  const classesUrl = `/clases/v2/${originalId}/`;

  const ClassicAddToCartBtn = (
    <React.Fragment>
      {props.showAddToCartButtonOnly ? (
        <div className="pt-16">
          <InverseButton
            full
            label={t('ADD_ITEM', {
              defaultValue: 'Agregar al carrito	',
            })}
            onClick={handleAddToCart}
            leftIcon={{
              component: inShoppingCart ? ShoppingCartAdded : ShoppingCartLight,
              size: 24,
            }}
            className="pl-0 pr-0"
            disabled={loadingCart}
            white={dark}
          />
        </div>
      ) : (
        <div className="flex items-center justify-between pt-16 AddToCartComponent">
          <StyledIconButton
            onClick={handleAddToCart}
            customIcon={inShoppingCart ? ShoppingCartAdded : ShoppingCartLight}
            customColor={
              dark
                ? colors.white
                : inShoppingCart
                ? colors.purpleLighter
                : colors.primaryMain
            }
            customSize={inShoppingCart ? 20 : 24}
            disabled={loadingCart}
            inShoppingCart={inShoppingCart}
            darkMode={dark}
          />
          <InverseButton
            onClick={handleDirectBuyButton}
            full={false}
            label={t('BUY_NOW', {
              defaultValue: 'Comprar ahora',
            })}
            white={dark}
          />
        </div>
      )}
    </React.Fragment>
  );

  const FullAddToCardButton = (
    <PrimaryButton
      className={`${
        inShoppingCart ? 'bg-base-lighter' : ''
      } w-full mt-16 rounded-10`}
      darkMode={dark}
      onClick={handleAddToCart}
      disabled={loadingCart}
      leftIcon={{
        component: inShoppingCart ? ShoppingCartAdded : ShoppingCartLight,
        size: inShoppingCart ? 20 : 24,
      }}
      label={
        inShoppingCart
          ? t('REMOVE_FROM_CART', {
              defaultValue: 'Quitar del carrito',
            })
          : t('ADD_ITEM', {
              defaultValue: 'Agregar al carrito	',
            })
      }
      size="m"
    />
  );

  if (isEnrollment) {
    return (
      <InverseButton
        full
        className="mt-16"
        href={classesUrl}
        // @ts-expect-error
        testId="watch-course"
        label={t('GO_TO_COURSE', {
          defaultValue: 'Ir al curso',
        })}
        white={dark}
      />
    );
  }

  // Button for Premium users
  if (user && ((user.isPremiumMember && user.membership) || user?.isStaff)) {
    return (
      <InverseButton
        full
        className="mt-16"
        onClick={() => {
          if (onClickEnrollNow) {
            onClickEnrollNow();
          } else {
            window.location.href = enrollmentUrl as string;
          }
        }}
        label={t('SUBSCRIBE_NOW', {
          defaultValue: '¡Inscríbete ahora!',
        })}
        disabled={isUserSofeAndCourseIsOneMonthOld}
        white={dark}
      />
    );
  }

  // Custom render
  if (renderCustomCallbackButtons) {
    return renderCustomCallbackButtons();
  }

  // Button used at CampaignLanding entry
  if (pageType === 'COURSE TEMPORAL ACCESS') {
    return (
      <InverseButton
        full
        className="mt-16"
        onClick={() => {
          if (source) {
            const freeEnrollmentUrl = getEnrollmentUrlWithSource({
              enrollmentUrl: `/enrollment/temporal-access/${pageId}/${originalId}/`,
              source,
              sourceDetail,
            });

            if (user && user.originalId) {
              if (user.phoneNumber) {
                window.location.href = freeEnrollmentUrl;
              } else {
                setBasicDataForm({
                  isOpen: true,
                  redirectUrl: freeEnrollmentUrl,
                });
              }
            } else {
              showRegisterModal(
                freeEnrollmentUrl,
                {},
                {
                  isVersion2: true,
                },
                'Course card - temporal access',
              );
            }
          }
        }}
        // @ts-expect-error
        testId="watch-course"
        label={temporalAccessDurationLabel || 'Acceso gratuito por 24 horas'}
        disabled={isUserSofeAndCourseIsOneMonthOld}
        white={dark}
      />
    );
  }

  // Button for 24hours access courses
  if (courseType === 'temporal-access' || user?.canEnrollFreemium) {
    return (
      <InverseButton
        full
        className="mt-16"
        href={url}
        // @ts-expect-error
        testId="watch-course"
        label={temporalAccessDurationLabel || 'Acceso gratuito por 24 horas'}
        disabled={isUserSofeAndCourseIsOneMonthOld}
        white={dark}
      />
    );
  }

  if (pageType === 'COURSE_ACCESS_PREVIEW' && courseType === 'freemium') {
    return (
      <InverseButton
        href={enrollmentAccessPreviewUrl}
        full
        className="mt-16"
        // @ts-expect-error
        testId="watch-course"
        label={t('TRY_COURSE', {
          defaultValue: '¡Pruébalo ahora!',
        })}
        disabled={isUserSofeAndCourseIsOneMonthOld}
        white={dark}
      />
    );
  }

  // Button for free courses
  if (courseType === 'free') {
    return (
      <InverseButton
        full
        className="mt-16"
        // @ts-expect-error
        testId="course-enrollement-button-free"
        onClick={() => {
          if (onClickEnrollNow) {
            onClickEnrollNow();
          } else if (user && user.originalId) {
            if (user.phoneNumber) {
              window.location.href = isBundle
                ? (enrollmentUrl as string)
                : (courseEnrollmentUrl as string);
            } else {
              setBasicDataForm({
                isOpen: true,
                // @ts-expect-error
                phoneNumberOptions: { isRequired: true },
                redirectUrl: isBundle
                  ? (enrollmentUrl as string)
                  : (courseEnrollmentUrl as string),
              });
            }
          } else {
            if (jobToBeDoneData) {
              setIntent(jobToBeDoneData);
            }

            showRegisterModal(
              isBundle ? enrollmentUrl : courseEnrollmentUrl,
              {},
              { isVersion2: true },
              'Course card - free course',
            );
          }
        }}
        label={t('SUBSCRIBE_FREE', {
          defaultValue: '¡Inscríbete gratis!',
        })}
        white={dark}
      />
    );
  }

  if (courseType === 'membership') {
    return (
      <InverseButton
        className="mt-16"
        full
        href={url}
        label="Acceso Premium"
        white={dark}
      />
    );
  }

  /**
   * Guys! We need to refactor this conditions. Actually we have 3 use-cases
   * 1- The course card with the two buttons, add to card and buy now
   * 2- The course card with one button, the add-to-cart (shoppingCart)
   * 3- The course card with one button, the buy-now.
   * We need a refactor because it's complicated to use the current prop
   * <CourseCard
   *  showBuyNowButtonOnly
   *  showOnlyShoppingCartButton
   * />
   * What the dev would expect?
   * Maybe we can add a new prop? ButtonGroupType: 1 | 2 | 3 ?
   */
  if (showBuyNowButtonOnly || isRockstarCourse) {
    return (
      <PrimaryButton
        onClick={handleDirectBuyButton}
        className="mt-16"
        full
        label={
          customButtonLabel ||
          t('BUY_NOW', {
            defaultValue: 'Comprar ahora',
          })
        }
      />
    );
  }

  return showOnlyAddToCardButton || showOnlyShoppingCartButton
    ? FullAddToCardButton
    : ClassicAddToCartBtn;
};

export default AddToCart;
