import * as React from 'react';
import styled from 'styled-components';

import { getImgixUrl } from '@crehana/utils';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const StyledTag = styled.div`
  background: rgba(0, 0, 0, 0.3);
  left: 12px;
  bottom: 12px;
  padding: 2px 8px;
`;

const BrasilianTag: React.FC<Partial<CourseCardProps>> = props => {
  if (!props.languageTag) {
    return null;
  }

  return (
    <StyledTag className="absolute flex items-center px-8 z-2 bg-base-main-80">
      <img
        alt={`curso ${props.languageTag.text}`}
        src={getImgixUrl(props.languageTag.flagImageUrl, {
          w: 20,
          h: 14,
        })}
        style={{ width: 20, height: 14 }}
        className="mr-4"
      />
      <span className="font-caption te-white">{props.languageTag.text}</span>
    </StyledTag>
  );
};

export default BrasilianTag;
