// @ts-nocheck
/**
 *
 * # Considerations:
 *
 *  ## Why isn't Amplitude here?
 *  Simple, to prevent excesive tracks traffic to Amplitude (It's really $$$ expensive) and not neccesary.
 *  We can get the sales performance metrics with google analytics or other tools.
 *  Only use Amplitude to understand specific flows, a/b tests or funnels to understand where are we churning.
 *  It's really important no send tracks to Amplitude if you don't have a specific planning for them.
 *
 * ## Google analytics
 * We support Enhanced E-commerce so we track every try to add to cart or product selling to google analytics.
 *
 */

import {
  trackUserActivity,
  UserActivityEvent,
} from 'Jsx/Analytics/UserActivity';

const GOOGLE_ANALYTICS_PROPERTY_ID = 'UA-60829200-1';

export type ProductType = {
  id: number;
  productId: string;
  name: string;
  category: string;
  type: 'membership' | 'course' | 'certification' | 'custom_bundle' | 'error';
};

export type RawCourseType = {
  originalId?: number | null;
  title: string;
  categoryObj: {
    slug?: string;
  };
  subcategory: {
    slug?: string;
  };
};

export type RawCertificationType = {
  originalId: number;
  title: string;
};

export interface RawMembershipType {
  originalId: number;
  tag: string;
  name: string;
  prices: {
    currencySymbol: string;
    price: number;
    monthlyPrice?: number;
    total: number;
  };
  urlCheckout: string;
  months: number;
}

export type CustomBundle = {
  originalId: number;
  name: string;
};

export const formatProduct = (
  data: RawCourseType | RawCertificationType | RawMembershipType | CustomBundle,
  productType: 'membership' | 'course' | 'certification' | 'customBundle',
): ProductType => {
  try {
    if (productType === 'membership') {
      return {
        id: data.originalId,
        productId: `MEMBERSHIP_${data.originalId}`,
        name: data.name,
        category: `membership/${data.tag}`,
        type: 'membership',
      };
    }

    if (productType === 'course') {
      const subcategory = data.subCategoryObj || data.subcategory;

      return {
        id: data.originalId,
        productId: `COURSE_${data.originalId}`,
        name: data.title,
        category: `course/${data.categoryObj.slug}/${subcategory.slug}`,
        type: 'course',
      };
    }

    if (productType === 'certification') {
      return {
        id: data.originalId,
        productId: `CERTIFICATION_${data.originalId}`,
        name: data.title,
        category: `certification`,
        type: 'certification',
      };
    }

    if (productType === 'customBundle') {
      return {
        id: data.originalId,
        productId: `CUSTOM_BUNDLE_${data.originalId}`,
        name: data.name,
        category: `customBundle`,
        type: 'custom_bundle',
      };
    }

    return {
      id: 0,
      productId: 'Error',
      name: 'Error',
      category: 'Error',
      type: 'error',
    };
  } catch (error) {
    console.error('Error while formating product');
    return {
      id: 0,
      productId: 'Error',
      name: 'Error',
      category: 'Error',
      type: 'error',
    };
  }
};

const sendPurchaseToUserActivity = (product: ProductType) => {
  return trackUserActivity({
    event: UserActivityEvent.CLICK,
    data: {
      product_id: product.id,
      product_name: product.name,
      item_type: product.type,
      item_path: location.pathname,
    },
  });
};

const GoogleAnalytics = {
  addToCart: (product: ProductType) => {
    return new Promise(resolve => {
      try {
        if (window && typeof window.gtag === 'function') {
          // Add product to be tracked
          const productFieldObject = {
            id: product.productId,
            name: product.name,
            category: product.category,
            quantity: 1,
          };

          if (process.env.NODE_ENV !== 'production') {
            console.group('------ Google Analytics - Add to cart ------');
            console.log('Product: ', productFieldObject);
            console.groupEnd();
          }

          window.gtag('event', 'add_to_cart', {
            send_to: GOOGLE_ANALYTICS_PROPERTY_ID,
            items: [productFieldObject],
            event_callback: () => {
              resolve({ name: 'Google Analytics', success: true });
            },
          });

          setTimeout(() => {
            resolve({ name: 'Google Analytics', success: true });
          }, 0);
        } else {
          console.error("Google analytics couldn't be loaded");
          resolve({ name: 'Google Analytics', success: false });
        }
      } catch (error) {
        console.log("Google Analytics: Can't track product", error);
        resolve({ name: 'Google Analytics', success: false });
      }
    });
  },
};

/**
 * Sends tracks to google analytics and user activity
 * @param product
 */
export const trackProduct = (product: ProductType) => {
  const sendToAnalytics = () => {
    return GoogleAnalytics.addToCart(product);
  };

  const asyncFunctions = [
    sendPurchaseToUserActivity(product),
    sendToAnalytics(),
  ];

  return Promise.all(asyncFunctions);
};

const buyProduct = (buyUrl: string, product: ProductType) => {
  if (product.id !== 0) {
    try {
      if (process.env.NODE_ENV !== 'production') {
        console.group('------ Buying product ------');
        console.log('Product: ', product);
        console.groupEnd();
      }

      /** TODO: Any error in this promise.all will be catched at the top try/catch.
         We can improve it later, implementing specific exception handling, but is not neccesary right now.
      */
      trackProduct(product)
        .then(() => {
          location.href = buyUrl;
        })
        .catch(err => {
          console.error('Error while tracking product', err);
          location.href = buyUrl;
        });
    } catch (error) {
      // If any track fails we should move on, and don't stop the shopping process
      location.href = buyUrl;
    }
  } else {
    location.href = buyUrl;
  }
};

export default buyProduct;
