import * as React from 'react';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const Wrapper: React.FC<Partial<CourseCardProps>> = props => {
  const mountRef = React.useRef(false);
  const { children, ...rest } = props;
  const previewWrapper = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    mountRef.current = true;
    return () => {
      mountRef.current = false;
    };
  }, []);

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.keyCode === 27) {
      event.stopPropagation();
    }
  };

  React.useEffect(() => {
    // @ts-expect-error
    document.addEventListener('keyup', handleKeyUp);
    // @ts-expect-error
    return () => document.removeEventListener('keyup', handleKeyUp);
  }, []);

  return (
    <div
      ref={previewWrapper}
      className="CourseCardComponent relative w-full"
      data-testid="course-card-wrapper"
      // this id is used as a item "reference" in some SEO components
      id={`course-item-${rest.originalId}`}
      style={{
        border: props.isDark ? 0 : '1px solid #d2faf6',
      }}
      css={`
        @media (min-width: 1024px) {
          border-radius: 10px;
          &:hover {
            box-shadow: 0px 4px 18px rgba(92, 84, 122, 0.14);
            transition: all ease-in 0.3s;
          }
        }
      `}
    >
      {React.Children.toArray(children).map(child =>
        React.cloneElement(child as React.ReactElement<any>, rest),
      )}
    </div>
  );
};

export default Wrapper;
