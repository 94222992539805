import React, { FC } from 'react';
import cn from 'classnames';
import GifLoader from 'crehana-ui/Loader';

const Loader: FC<React.HTMLAttributes<HTMLDivElement> & { isWhite?: boolean }> =
  ({ className, isWhite, ...props }) => (
    <div className={cn('w-full flex justify-center', className)} {...props}>
      <GifLoader isWhite={isWhite} />
    </div>
  );

export default Loader;
