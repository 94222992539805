export default {
  es: {
    ORDER_BY_LABEL: 'Ordenar',
    FILTER_LABEL: 'Filtrar',
    SEARCH_PLACEHOLDER: 'Buscar cursos',
    SEARCH_PLACEHOLDER_2: 'Busca tu curso preferido',
    TIMER_TITLE_1: '¡Quedan menos de dos horas!',
    TIMER_TITLE_2: 'La oferta termina en:',
    ENDS_IN: 'Termina en',
    SHOW_RESULTS: 'Ver resultados',
    CLEAN_FILTERS: 'Limpiar filtros',
    CATEGORIES: 'Categorías',
    LEVELS: 'Niveles',
    SOFTWARES: 'Softwares',
    LANGUAGES: 'Idiomas',
    REMOVE_COURSE_FROM_PACK: 'Quitar del pack',
    ADD_COURSE_TO_PACK: 'Agregar al pack',
    NO_RESULTS: 'No encontramos resultados para tu búsqueda',
    LETS_MEET: 'Empecemos por conocernos',
    COUNT_COURSES: 'Cuéntanos un poco más sobre ti',
    MY_NAME_IS: 'Mi nombre es',
    MY_LAST_NAME_IS: 'Mi apellido es',
    MY_PHONE_NUMBER_IS: 'Mi teléfono es',
    READY_TO_PLAY: '¡Casi está todo listo para la partida!',
    AGE: 'Edad',
    COURSE_BEFORE: '¿Has llevado cursos online antes?',
    SELLER_INTERNET_BEFORE: '¿Has comprado por internet antes?',
    NEWS: 'Deseo recibir noticias/promociones',
    GO_MARATHON: '3, 2, 1 ¡tu maratón de cursos gratis empezó!',
    VIEW_GOAL:
      'Recuerda visualizar siempre tu meta. ¡Que tengas una experiencia increíble!',

    CAMPAIGN_LANDING_STEP_1_TITLE: 'Aprende a tu ritmo',
    CAMPAIGN_LANDING_STEP_1_CONTENT: 'Cursos las 24h sin caducidad',
    CAMPAIGN_LANDING_STEP_2_TITLE: 'Crea proyectos',
    CAMPAIGN_LANDING_STEP_2_CONTENT: 'Refuerza tu portafolio',
    CAMPAIGN_LANDING_STEP_3_TITLE: 'Certifícate',
    CAMPAIGN_LANDING_STEP_3_CONTENT: 'Certificaciones oficiales',

    // Buenfin
    STEPS_COURSES_STEP_1: '1. Busca tus cursos favoritos',
    STEPS_COURSES_STEP_2: '2. Agrega los que quieras al carrito',
    STEPS_COURSES_STEP_3: '3. Dale a comprar y ¡aprovecha!',
    STEPS_PACK_STEP_1: '1. Busca o filtra tus cursos preferidos',
    STEPS_PACK_STEP_2: '2. Agrégalos a tu pack solo aquí',
    STEPS_PACK_STEP_3: '3. Cómpralos y disfrútalos sin límites',

    REDEEM: 'Canjéalo ahora',

    // Warning dialog
    WARNING_DIALOG_TITLE:
      'Lo sentimos, este cupón solo es válido para usuarios nuevos 😔',
    WARNING_DIALOG_BODY:
      '¡Descuida! Aún tienes promociones disponibles para empezar a aprender.',
    WARNING_DIALOG_BUTTON_LABEL: 'Descúbrelas',

    REGISTER_TITLE: 'Únete a la comunidad Crehana',
    REGISTER_MESSAGE: 'Ingresa tus datos para canjear tu curso gratis',

    // Hack, do not change this, is to render empty backgrounds with image as texts instead titles and subtitles
    CAMPAIGN_LANDING_TITLE: '   ',
    CAMPAIGN_LANDING_SUBTITLE: '    ',

    // Legals
    LEGAL_LABEL: 'Ver términos y condiciones',

    // Timer
    TIMER_LABEL: '¡Aprovecha la promo!',

    // coupon quick component
    COUPON_ERROR_MESSAGE: 'Error: El cupón es erróneo',
    INVALID_COUPON: 'Cupón inválido',
    REQUIRED_COUPON: 'Cupón obligatorio',
    ENROLLING_TITLE: 'Estamos activando tu cupón',
    ENROLLING_SUBTITLE: 'Esto puede tomar unos segundos',
    QUICK_ENROLL_TITLE: 'Canjea tu cupón aquí',
    HAVE_NOT_COUPON: '¿No tienes un cupón? Adquiérelo ',
    HAVE_NOT_COUPON_HERE: 'aquí',
    COUPON_FIELD_TITLE: 'Ingresa tu cupón en el campo de abajo',
    COUPON_FIELD_LABEL: 'Ingresa tu cupón',
    QUICK_ENROLL_CANCEL: 'Cancelar',
    QUICK_ENROLL_ACCEPT: 'Aceptar',
  },
};
