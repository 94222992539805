export default {
  pt: {
    ORDER_BY_LABEL: 'Organizar',
    FILTER_LABEL: 'Filtrar',
    SEARCH_PLACEHOLDER: 'Pesquisar cursos',
    SEARCH_PLACEHOLDER_2: 'Procure seu curso preferido',
    TIMER_TITLE_1: '¡Restam menos de dois horas!',
    TIMER_TITLE_2: 'A oferta termina em:',
    ENDS_IN: 'Termina em',
    SHOW_RESULTS: 'Veja os resultados',
    CLEAN_FILTERS: 'Filtros limpos',
    CATEGORIES: 'Categorías',
    LEVELS: 'Nível',
    SOFTWARES: 'Softwares',
    LANGUAGES: 'Idiomas',
    ADD_COURSE_TO_PACK: 'Adicionar curso',
    REMOVE_COURSE_FROM_PACK: 'Retirar del pack',
    NO_RESULTS: 'Não encontramos resultados para sua pesquisa',
    LETS_MEET: 'Vamos nos conhecer',
    COUNT_COURSES: 'Conte-nos um pouco mais sobre você',
    MY_NAME_IS: 'Meu nome é',
    MY_LAST_NAME_IS: 'Meu sobrenome é',
    MY_PHONE_NUMBER_IS: 'Meu telefone é',
    READY_TO_PLAY: 'Estamos quase lá!',
    AGE: 'Idade',
    COURSE_BEFORE: 'Já fez cursos online antes?',
    SELLER_INTERNET_BEFORE: 'Já comprou online antes?',
    NEWS: 'Quero receber novidades e promoções',
    GO_MARATHON: 'Titulo: 3, 2, 1 a maratona de cursos grátis começou!',
    VIEW_GOAL:
      'Lembre-se de visualizar seu objetivo. Tenha uma experiência incrível!',

    CAMPAIGN_LANDING_STEP_1_TITLE: 'Aprenda em seu ritmo',
    CAMPAIGN_LANDING_STEP_1_CONTENT: 'Cursos 24 horas sem validade',
    CAMPAIGN_LANDING_STEP_2_TITLE: 'Crie projetos',
    CAMPAIGN_LANDING_STEP_2_CONTENT: 'Reforce seu portfólio',
    CAMPAIGN_LANDING_STEP_3_TITLE: 'Certificados',
    CAMPAIGN_LANDING_STEP_3_CONTENT: 'Certificados oficiais',

    // Buenfin - This is not important, because BuenFin is not for BRAZIL
    STEPS_COURSES_STEP_1: '1. Busca tus cursos favoritos',
    STEPS_COURSES_STEP_2: '2. Agrega los que quieras al carrito',
    STEPS_COURSES_STEP_3: '3. Dale a comprar y ¡aprovecha!',
    STEPS_PACK_STEP_1: '1. Busca o filtra tus cursos preferidos',
    STEPS_PACK_STEP_2: '2. Agrégalos a tu pack solo aquí',
    STEPS_PACK_STEP_3: '3. Cómpralos y disfrútalos sin límites',

    REDEEM: 'Resgate agora',

    // Warning dialog
    WARNING_DIALOG_TITLE:
      'Desculpe, este cupom é válido apenas para novos usuários 😔',
    WARNING_DIALOG_BODY:
      'Não se preocupe! Você ainda tem promoções disponíveis para começar a aprender.',
    WARNING_DIALOG_BUTTON_LABEL: 'Descubra-os',

    REGISTER_TITLE: 'Junte-se à comunidade Crehana',
    REGISTER_MESSAGE: 'Insira seus dados para resgatar seu curso gratuito',
    // Hack, do not change this, is to render empty backgrounds with image as texts instead titles and subtitles
    CAMPAIGN_LANDING_TITLE: '   ',
    CAMPAIGN_LANDING_SUBTITLE: '    ',

    // Legals
    LEGAL_LABEL: 'Veja os termos e condições',

    // Timer
    TIMER_LABEL: '¡Aprovecha la promo!',

    // coupon quick component
    COUPON_ERROR_MESSAGE: 'Erro: o cupom está errado',
    INVALID_COUPON: 'Cupom inválido',
    REQUIRED_COUPON: 'cupom obrigatório',
    ENROLLING_TITLE: 'Estamos ativando seu cupom',
    ENROLLING_SUBTITLE: 'Isso pode demorar alguns segundos.',
    QUICK_ENROLL_TITLE: 'Resgate seu cupom aqui',
    HAVE_NOT_COUPON: 'Não tem cupom? Venha',
    HAVE_NOT_COUPON_HERE: 'aqui',
    COUPON_FIELD_TITLE: 'Insira seu cupom no campo abaixo',
    COUPON_FIELD_LABEL: 'Insira seu cupom',
    QUICK_ENROLL_CANCEL: 'Cancelar',
    QUICK_ENROLL_ACCEPT: 'Aceitar',
  },
};
