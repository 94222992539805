export const initialStatePageKey = 'crehanaApp';
export const CAMPAIGN_LANDING_AMPLITUDE_PAGE_NAME = 'Campaign Landing';
export const CAMPAIGN_LANDING_PAGE_KEY = 'custom-bundle';
export const CUSTOM_BUNDLE_LOCALSTORAGE_KEY = 'creh.customBundle.items';
export const prefixClass = 'cbcl';

export const ENROLLMENT_URL_SOURCES = {
  COURSE_LIST: 'Course List',
  COURSE_TRACK_CARD: 'Course Track Card',
} as const;
