import React from 'react';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';
import { useAuthDialog } from '@crehana/web/dist/ReactContexts/AuthDialog';
import { ShoppingCartLight, ShoppingCartAdded } from '@crehana/icons';
import { PrimaryButton, InverseButton, IconButton } from '@crehana/ui';
import { useTranslation } from '@crehana/i18n';
import { useProductsContainer } from '@crehana/web/dist/ReactContexts/Products';
import { trackUserActivity, UserActivityEvent } from '@crehana/analytics';

import buyProduct, { trackProduct, ProductType } from 'Jsx/Utils/buyProduct';

import { OnClikBuyNow } from '../../types';

type CallToActionsProps = {
  title: string;
  loadingCart: boolean;
  inShoppingCart?: boolean;
  addCourseToCart: (item: { courseId: number }) => Promise<any>;
  removeCourseFromCart: (item: { courseId: number }) => Promise<any>;
  originalId: number;
  urlToBuyNow: string;
  onClickBuyNow?: OnClikBuyNow;
  isEnrollment: boolean;
  pageType?: string;
  courseType?: string;
  enrollmentAccessPreviewUrl?: string;
  showOnlyShoppingCartButton?: boolean;
  showBuyNowButtonOnly?: boolean;
  url: string;
  pageId?: number;
  product?: ProductType;
  temporalAccessDurationLabel?: string | null;
  isDarkMode?: boolean;
  renderCustomCallbackButtons?: () => JSX.Element;
  isUserSofeAndCourseIsOneMonthOld?: boolean;
  customButtonLabel?: string;
};

const CallToActions: React.FC<CallToActionsProps> = ({
  title,
  loadingCart,
  inShoppingCart,
  addCourseToCart,
  removeCourseFromCart,
  originalId,
  urlToBuyNow,
  onClickBuyNow,
  // props to render differents buttons
  isEnrollment,
  pageType,
  courseType,
  enrollmentAccessPreviewUrl,
  showOnlyShoppingCartButton,
  product,
  showBuyNowButtonOnly,
  url,
  pageId,
  temporalAccessDurationLabel,
  isDarkMode,
  renderCustomCallbackButtons,
  isUserSofeAndCourseIsOneMonthOld,
  customButtonLabel,
}) => {
  const { t } = useTranslation('main-menu');
  const { me: user } = useMe();
  const { setBasicDataForm } = useProductsContainer();
  const { showRegisterModal } = useAuthDialog();

  const addCourseToCartWithTrack = () => {
    addCourseToCart({
      courseId: originalId,
    });
  };

  const removeCourseFromCartWithTrack = () => {
    removeCourseFromCart({
      courseId: originalId,
    });
  };

  const onClickBuyNowButton = () => {
    if (onClickBuyNow && product) {
      trackProduct(product);
      // TODO: onClickBuyNow should receive the data directly, not in an itemData object
      onClickBuyNow({
        itemId: originalId,
        itemType: 'course',
      });
    } else if (urlToBuyNow && product) {
      buyProduct(urlToBuyNow, product);
    } else {
      trackUserActivity({
        event: UserActivityEvent.CLICK,
        data: {
          product_id: originalId!,
          product_name: title,
          item_type: 'course',
          item_path: window.location.pathname,
        },
        nextUrl: urlToBuyNow,
      });
    }
  };

  const enrollmentUrl = `/enrollment/${originalId}/`;

  if (pageType === 'COURSE TEMPORAL ACCESS') {
    return (
      <PrimaryButton
        disabled={loadingCart || isUserSofeAndCourseIsOneMonthOld}
        onClick={() => {
          const freeEnrollmentUrl = `/enrollment/temporal-access/${pageId}/${originalId}/`;

          if (user && user.originalId) {
            if (user.phoneNumber) {
              window.location.href = freeEnrollmentUrl;
            } else {
              setBasicDataForm({
                isOpen: true,
                redirectUrl: freeEnrollmentUrl,
              });
            }
          } else {
            showRegisterModal(
              freeEnrollmentUrl,
              {},
              {
                shouldRequireExtraData: true,
              },
            );
          }
        }}
        full
        label={temporalAccessDurationLabel || 'Acceso gratuito por 24 horas'}
      />
    );
  }

  // if the user is already enrollment just redirect them to the player
  if (isEnrollment) {
    return (
      <PrimaryButton
        disabled={loadingCart}
        full
        href={`/clases/v2/${originalId}/`}
        label={t('GO_TO_COURSE', {
          defaultValue: 'Ir al curso',
        })}
        className="rounded-10"
      />
    );
  }

  if (user && user.isPremiumMember && user.membership) {
    return (
      <PrimaryButton
        full
        disabled={loadingCart || isUserSofeAndCourseIsOneMonthOld}
        href={enrollmentUrl}
        label={t('SUBSCRIBE_NOW', {
          defaultValue: '¡Inscríbete ahora!',
        })}
        className="rounded-10"
      />
    );
  }

  if (renderCustomCallbackButtons) {
    return renderCustomCallbackButtons();
  }

  if (pageType === 'COURSE_ACCESS_PREVIEW' && courseType === 'freemium') {
    return (
      <PrimaryButton
        disabled={loadingCart || isUserSofeAndCourseIsOneMonthOld}
        href={enrollmentAccessPreviewUrl}
        full
        label={t('TRY_COURSE', {
          defaultValue: '¡Pruébalo ahora!',
        })}
        className="rounded-10"
      />
    );
  }

  if (courseType === 'temporal-access' || user?.canEnrollFreemium) {
    return (
      <InverseButton
        full
        className="mt-16 rounded-10"
        href={url}
        data-testid="watch-course"
        label="Acceso gratuito por 24 horas"
        disabled={isUserSofeAndCourseIsOneMonthOld}
      />
    );
  }

  if (courseType === 'free') {
    return (
      <PrimaryButton
        disabled={loadingCart}
        full
        href={enrollmentUrl}
        label={t('SUBSCRIBE_FREE', {
          defaultValue: '¡Inscríbete gratis!',
        })}
        className="rounded-10"
      />
    );
  }

  // We have courses that only premium users can watch
  if (courseType === 'membership') {
    return (
      <PrimaryButton
        disabled={loadingCart}
        full
        href={url}
        label="Acceso Premium"
        className="rounded-10"
      />
    );
  }

  if (showBuyNowButtonOnly) {
    return (
      <PrimaryButton
        disabled={loadingCart}
        className="w-full rounded-10"
        onClick={onClickBuyNowButton}
        label={
          customButtonLabel ||
          t('BUY_NOW', {
            defaultValue: 'Comprar ahora',
          })
        }
      />
    );
  }

  if (showOnlyShoppingCartButton) {
    return (
      <PrimaryButton
        darkMode={isDarkMode}
        leftIcon={{
          component: inShoppingCart ? ShoppingCartAdded : ShoppingCartLight,
          size: 20,
        }}
        className={`rounded-10  ${inShoppingCart ? 'bg-base-lighter' : ''}`}
        disabled={loadingCart}
        full
        onClick={
          inShoppingCart
            ? removeCourseFromCartWithTrack
            : addCourseToCartWithTrack
        }
        label={
          inShoppingCart
            ? t('REMOVE_FROM_CART', {
                defaultValue: 'Quitar del carrito',
              })
            : t('ADD_TO_CART', {
                defaultValue: 'Añadir al carrito',
              })
        }
      />
    );
  }

  // Shopping cart and BuyNow buttons
  return (
    <div className="flex items-center flex-nowrap">
      <IconButton
        className="flex-shrink-0 border border-solid border-primary-main"
        onClick={
          inShoppingCart
            ? removeCourseFromCartWithTrack
            : addCourseToCartWithTrack
        }
        customIcon={inShoppingCart ? ShoppingCartAdded : ShoppingCartLight}
        disabled={loadingCart}
      />
      <PrimaryButton
        disabled={loadingCart}
        className="w-full ml-8 rounded-10"
        onClick={onClickBuyNowButton}
        label={t('BUY_NOW', {
          defaultValue: 'Comprar ahora',
        })}
      />
    </div>
  );
};

export default CallToActions;
