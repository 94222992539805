import React from 'react';

import { getImgixUrl } from '@crehana/utils';

import { LazyImageResponsive } from '@crehana/ui';

import { ImageComponentProps } from 'Jsx/Shared/CourseCardV3/types';

const Image: React.FC<ImageComponentProps> = ({
  lazyImageProps,
  url,
  promoImage,
  promoImageAnimated,
  imagePlaceholder,
  imgxParams: imgxParamsProp,
  title,
  customOnClickImage,
  priority,
}) => {
  const onClickImage = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (customOnClickImage) {
      event.preventDefault();
      // only run if it come from the props
      customOnClickImage(event);
    }
  };

  const image = (promoImage || promoImageAnimated) as string;

  return (
    <a href={url} onClick={onClickImage} className="block w-full">
      <div className="relative">
        {priority ? (
          <div
            css={`
              background-image: url(${imagePlaceholder});
              background-position: center;
              background-size: cover;
              /* 100.02% returns a 88px */
              padding-bottom: 100.02%;
            `}
            className="relative w-full h-auto course-image rounded-10"
          >
            <img
              src={getImgixUrl(image, {
                auto: 'format',
                q: 55,
                w: 172,
                h: 172,
                ...imgxParamsProp,
              })}
              alt={title}
              className="absolute inset-0 object-cover w-full h-full rounded-10"
            />
          </div>
        ) : (
          <LazyImageResponsive
            placeholder={imagePlaceholder}
            imgxParams={imgxParamsProp}
            // @ts-ignore
            css={`
              /* 100.02% returns a 88px */
              padding-bottom: 100.02%;
            `}
            alt={title as string}
            className="w-full h-auto course-image"
            imgClassName="rounded-10 w-full h-auto"
            sources={{
              default: getImgixUrl(image, {
                w: 93,
                h: 90,
              }),
              sm: getImgixUrl(image, { w: 120, h: 80 }),
              md: getImgixUrl(image, { w: 240, h: 160 }),
              lg: getImgixUrl(image, { w: 240, h: 160 }),
            }}
            {...lazyImageProps}
          />
        )}
      </div>
    </a>
  );
};

export default Image;
