const getRandomNumber = () => Math.floor(Math.random() * 7 + 1) + 1;

export const SOCIAL_NOTIFICATION_USERS = [
  {
    id: 1,
    name: 'Julio Casanova',
    image: '/static/img/membership/flags/pe.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde Perú`,
    city: 'Perú',
  },
  {
    id: 2,
    name: 'Alfredo Meza',
    image: '/static/img/membership/flags/co.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde Colombia`,
    city: 'Colombia',
  },
  {
    id: 3,
    name: 'Yazmin Betancourt',
    image: '/static/img/membership/flags/mx.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde México`,
    city: 'México',
  },
  {
    id: 4,
    name: 'Alex Burgos',
    image: '/static/img/membership/flags/ar.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde Argentina`,
    city: 'Argentina',
  },
  {
    id: 5,
    name: 'Carlos Bustamante',
    image: '/static/img/membership/flags/ec.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde Ecuador`,
    city: 'Ecuador',
  },
  {
    id: 6,
    name: 'Diego Olivares',
    image: '/static/img/membership/flags/ar.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde Argentina`,
    city: 'Argentina',
  },
  {
    id: 7,
    name: 'Agustin Sepulveda',
    image: '/static/img/membership/flags/mx.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde México`,
    city: 'México',
  },
  {
    id: 8,
    name: 'Carol Carrera',
    image: '/static/img/membership/flags/mx.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde México`,
    city: 'México',
  },
  {
    id: 9,
    name: 'Paulina Rendon',
    image: '/static/img/membership/flags/co.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde Colombia`,
    city: 'Colombia',
  },
  {
    id: 10,
    name: 'Samuel Villamarin',
    image: '/static/img/membership/flags/es.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde España`,
    city: 'España',
  },
  {
    id: 11,
    name: 'Jose Adamo',
    image: '/static/img/membership/flags/pe.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde Perú`,
    city: 'Perú',
  },
  {
    id: 12,
    name: 'Pedro Morzan',
    image: '/static/img/membership/flags/co.png',
    timeLabel: `Hace ${getRandomNumber()} minutos desde Colombia`,
    city: 'Colombia',
  },
];

export type SocialUserType = typeof SOCIAL_NOTIFICATION_USERS[number];
