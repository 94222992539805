// @ts-nocheck
import * as React from 'react';

import { Radio, Checkbox, PrimaryButton } from '@crehana/ui';
import { useTranslation } from '@crehana/i18n';

import {
  CategoryCommonProps,
  LevelCommonProps,
  LanguageCommonProps,
  SoftwareCommonProps,
} from 'Jsx/CampaignLanding/types';

type Props = CategoryCommonProps &
  LevelCommonProps &
  LanguageCommonProps &
  SoftwareCommonProps & {
    isFilterBarOnTop: boolean;
    isFilterOpen: boolean;
    clearFilters: () => void;
    isFilterButtonActive: boolean;
    closeFilterComponent: () => void;
    isBlackTheme: boolean;
  };

const Filters = ({
  closeFilterComponent,
  isFilterOpen,
  isFilterBarOnTop,
  categories,
  categorySelected,
  setCategory,
  // ,
  levels,
  levelSelected,
  selectLevel,
  // ,
  languages,
  languageSelected,
  selectLanguage,
  // ,
  softwares,
  softwaresSelected,
  selectSoftware,
  clearFilters,
  isFilterButtonActive,
  isBlackTheme,
}) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="page-filter-desktop-filterBox"
      className="absolute"
      css={`
        top: ${isFilterBarOnTop ? 80 : 62}px;
        left: 0;
        right: 0;
        transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
        opacity: 0;
        transform: translateY(-5%);
        pointer-events: none;
        z-index: -1;
        ${isFilterOpen &&
        `
          opacity: 1;
          transform: translateY(0);
          pointer-events: all;
          z-index: 2;
          `}
      `}
    >
      <div
        className="h-full flex flex-row flex-wrap py-40 px-24 overflow-auto"
        style={{
          maxHeight: 'calc(100vh - 100px)',
          boxShadow: '1px 10px 18px rgba(92,84,122,0.14)',
          background: isBlackTheme ? '#1C1C23' : '#fff',
        }}
      >
        {/* ============================================================================ */}
        {/* ===============================  Categories ================================ */}
        {/* ============================================================================ */}
        <div style={{ width: '40%' }} className="mr-16">
          <h3
            className={`font-subtitle3 mb-4 ${
              isBlackTheme ? 'text-white' : ''
            }`}
          >
            Categorías
          </h3>
          <div>
            {categories.map(category => {
              const isSelected = Boolean(
                categorySelected && category.slug === categorySelected.slug,
              );

              return (
                <Radio
                  key={category.slug}
                  checked={isSelected}
                  label={category.name}
                  className="py-8 w-6/12"
                  data-testid="page-filter-category-radio"
                  darkMode={isBlackTheme}
                  inputProps={{
                    onClick: () => {
                      if (isSelected) {
                        setCategory(undefined);
                      } else {
                        setCategory(category);
                      }
                    },
                  }}
                  // just a dummy function to remove react warnings
                  onChange={() => {}}
                  labelProps={{
                    type: 'body4',
                  }}
                />
              );
            })}
          </div>
        </div>
        {/* ============================================================================ */}
        {/* ===============================  Levels ================================ */}
        {/* ============================================================================ */}
        <div style={{ width: '20%' }} className="mr-16">
          <h3
            className={`font-subtitle3 mb-4 ${
              isBlackTheme ? 'text-white' : ''
            }`}
          >
            Niveles
          </h3>
          <div>
            {levels.map(level => {
              const isSelected = !!levelSelected.find(
                l => l.value === level.value,
              );

              return (
                <Checkbox
                  label={level.label}
                  data-testid="page-filter-level-checkbox"
                  checked={isSelected}
                  key={level.value}
                  darkMode={isBlackTheme}
                  className="py-8 w-full"
                  inputProps={{
                    onClick: () => {
                      selectLevel(level);
                    },
                  }}
                />
              );
            })}
          </div>
        </div>
        {/* ============================================================================ */}
        {/* ===============================  Languages ================================ */}
        {/* ============================================================================ */}
        <div style={{ width: '20%' }}>
          <h3
            className={`font-subtitle3 mb-4 ${
              isBlackTheme ? 'text-white' : ''
            }`}
          >
            Idiomas
          </h3>
          <div>
            {languages.map(language => {
              const isSelected = !!languageSelected.find(
                l => l.value === language.value,
              );

              return (
                <Checkbox
                  key={language.value}
                  label={language.label}
                  data-testid="page-filter-language-checkbox"
                  checked={isSelected}
                  darkMode={isBlackTheme}
                  inputProps={{
                    onClick: () => {
                      selectLanguage(language);
                    },
                  }}
                  className="py-8 w-full"
                />
              );
            })}
          </div>
        </div>
        {/* ============================================================================ */}
        {/* =============================== SEE_RESULTS AND CLEAR FILTER BUTTON ================================ */}
        {/* ============================================================================ */}
        <div
          style={{
            width: 'calc(20% - 40px)',
          }}
        >
          <PrimaryButton
            className="w-full mb-24"
            label={t('SHOW_RESULTS')}
            onClick={closeFilterComponent}
          />
          <button
            type="button"
            onClick={clearFilters}
            className="pb-32"
            style={{
              opacity: isFilterButtonActive ? 1 : 0,
              transition: 'opacity 0.2s ease-in-out',
            }}
          >
            <span className="font-label text-base-lighter">
              {t('CLEAN_FILTERS')}
            </span>
          </button>
        </div>
        {/* ============================================================================ */}
        {/* =============================== Softwares ================================ */}
        {/* ============================================================================ */}
        <div className="mt-24" style={{ width: '100%' }}>
          <h3
            className={`font-subtitle3 mb-4 ${
              isBlackTheme ? 'text-white' : ''
            }`}
          >
            Softwares
          </h3>
          <div>
            {softwares.map(software => {
              const isSelected = !!softwaresSelected.find(
                s => s.value === software.value,
              );

              return (
                <Checkbox
                  data-testid="page-filter-software-checkbox"
                  key={software.label}
                  label={software.label}
                  darkMode={isBlackTheme}
                  checked={isSelected}
                  className="py-8 w-3/12"
                  inputProps={{
                    onClick: () => {
                      selectSoftware(software);
                    },
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
