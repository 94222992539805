import React, { FC } from 'react';
import styled from 'styled-components';

import { ArrowRight } from '@crehana/icons/outline';
import { Dialog, colors } from '@crehana/ui';

import { useTranslation } from '@crehana/i18n';
import { getImgxUrl } from '@crehana/utils';

import { usePageContext } from 'Jsx/CampaignLanding/Context';

import { BackgroundXPosition } from 'Jsx/CampaignLanding/types';

import CountdownTimer from './CountdownTimer';
import HeroImage from './HeroImage';

const Section = styled.section<{
  isBlackTheme: boolean;
}>`
  background: ${({ isBlackTheme, theme }) =>
    `${
      isBlackTheme
        ? 'linear-gradient(180deg, rgba(7, 14, 39, 0) 74.86%, #000000 100%),'
        : theme.colors.gray.lighter
    } no-repeat center / cover ${isBlackTheme ? ', #101125' : ''};`};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ isBlackTheme, theme }) =>
      `${
        isBlackTheme
          ? 'linear-gradient(180deg, rgba(7, 14, 39, 0) 74.86%, #000000 100%),'
          : theme.colors.gray.lighter
      } no-repeat center / cover ${isBlackTheme ? ', #101125' : ''};`};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    background: ${({ isBlackTheme, theme }) =>
      `${
        isBlackTheme
          ? 'linear-gradient(180deg, rgba(7, 14, 39, 0) 74.86%, #000000 100%),'
          : theme.colors.gray.lighter
      } no-repeat center / cover ${isBlackTheme ? ', #101125' : ''};`};
  }
`;

type GenericHeroProps = {
  isBlackTheme: boolean;
  isStepsInDarkMode: boolean;
  minHeight: boolean;
  backgroundXPosition: BackgroundXPosition;
};

const GenericHero: FC<GenericHeroProps> = ({
  isBlackTheme,
  isStepsInDarkMode,
  minHeight,
  backgroundXPosition,
}) => {
  const { t } = useTranslation();
  const {
    title,
    timer,
    pageSteps,
    timerBackgroundColor,
    timerCustomColor,
    logoImageUrl,
    titleColor,
    bannerDesktop,
    bannerMobile,
    bannerTablet,
    legalsCopy,
    isQuickPackEnrollment,
    tycTextColor,
  } = usePageContext();

  const [isOpen, setIsOpen] = React.useState(false);

  const expirationDate = timer.expireDate;
  const translatedTitle = title || t('CAMPAIGN_LANDING_TITLE');

  return (
    <Section
      className="pt-72 pb-40 sm:pt-72 sm:pb-48 md:pt flex justify-center relative"
      css={
        minHeight
          ? `
          @media (min-width: 768px){
            min-height: 340px;
          }
          @media (min-width: 1024px){
            min-height: 360px;
          }
          @media (min-width: 1366px){
            min-height: 320px;
          }
      `
          : ``
      }
      isBlackTheme={isBlackTheme}
    >
      <HeroImage
        srcDesktop={bannerDesktop!}
        srcTablet={bannerTablet!}
        srcMobile={bannerMobile!}
        backgroundXPosition={backgroundXPosition}
      />
      <div
        className="flex flex-col items-start sm:pt-16 w-full px-20 sm:px-32 lg:px-0 relative"
        style={{ maxWidth: 1152 }}
      >
        {logoImageUrl && (
          <img
            src={getImgxUrl(logoImageUrl, {
              auto: 'format',
              q: 55,
              h: 'auto',
            })}
            alt="logo"
            height="auto"
            width="auto"
            style={{ maxHeight: 80, maxWidth: '100%' }}
            className="mb-24"
          />
        )}
        {/* Title */}
        <h1
          className="font-h2"
          style={{ color: titleColor || colors.baseMain, maxWidth: 900 }}
        >
          {translatedTitle}
        </h1>

        {/* Mobile Steps */}
        <ul className="mt-24 flex flex-col items-start sm:flex-row md:hidden">
          {pageSteps.map((step, index) => (
            <li
              key={`step-${index.toString()}`}
              className={`flex items-center ${
                index + 1 < pageSteps.length ? 'mb-12' : 'mb-0'
              }`}
            >
              {index !== 0 && (
                <ArrowRight
                  className={`mx-8 hidden sm:inline fill-current ${
                    isStepsInDarkMode ? 'text-white' : 'text-base-main'
                  }`}
                  ignoreColor
                  size={24}
                />
              )}

              <p
                className={`font-subtitle4 ${
                  isStepsInDarkMode ? 'text-white' : 'text-base-main'
                }`}
              >
                {step?.content}
              </p>
            </li>
          ))}
        </ul>
        {/* Desktop Steps */}
        <ul className="mt-24 flex-col items-center sm:flex-row hidden sm:flex">
          {pageSteps.map((step, index) => (
            <li key={`step-${index.toString()}`} className="flex items-center">
              {index !== 0 && (
                <ArrowRight
                  className={`mx-8 hidden sm:inline fill-current ${
                    isStepsInDarkMode ? 'text-white' : 'text-base-main'
                  }`}
                  ignoreColor
                  size={24}
                />
              )}
              <div
                className="my-4 sm:my-0 flex justify-center items-center py-12 px-16"
                style={{
                  borderRadius: 20,
                  backgroundColor: isStepsInDarkMode
                    ? colors.baseLightDarkMode
                    : '#dfdfdf',
                }}
              >
                <p
                  className={`font-subtitle4 ${
                    isStepsInDarkMode ? 'text-white' : 'text-base-main'
                  }`}
                >
                  {step?.content}
                </p>
              </div>
            </li>
          ))}
        </ul>
        {/* Countdown promo */}
        {expirationDate && !isQuickPackEnrollment && (
          <div className="mt-32 flex justify-start w-full sm:w-auto">
            <CountdownTimer
              date={expirationDate}
              timerBackgroundColor={timerBackgroundColor as string}
              timerCustomColor={timerCustomColor as string}
            />
          </div>
        )}

        {Boolean(legalsCopy) && (
          <React.Fragment>
            <button
              type="button"
              className="mt-24 font-link"
              style={{ color: tycTextColor || colors.secondaryMainHEX }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {t('LEGAL_LABEL')}
            </button>
            <Dialog
              size="s"
              open={isOpen}
              onDismiss={() => {
                setIsOpen(false);
              }}
            >
              <div className="py-48 px-32">
                <p className="font-legals">{legalsCopy}</p>
              </div>
            </Dialog>
          </React.Fragment>
        )}
      </div>
    </Section>
  );
};

export default GenericHero;
