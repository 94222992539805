import React from 'react';

import { InverseButton, PrimaryButton, Dialog, TextField } from '@crehana/ui';
import { useTranslation } from '@crehana/i18n';
import Gift from '@crehana/icons/dist/fill/Gift';

import useQuickEnroll from 'Jsx/CheckoutV4/shared/hooks/useQuickEnroll';

import useApplyCoupon from 'Jsx/CheckoutV4/shared/hooks/useApplyCoupon';

import Enrolling from './Enrolling';

type CourseButtonProps = {
  isDark?: boolean;
  couponCode?: string;
  itemId: number | null;
  pageSlug: string;
  customButtonLabel?: string;
};

const CourseButton: React.FC<CourseButtonProps> = ({
  isDark,
  couponCode,
  itemId,
  pageSlug,
  customButtonLabel,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { applyCouponMutation } = useApplyCoupon();
  const { applyQuickEnrollmentMutation } = useQuickEnroll();
  const [isEnrolling, setIsEnrolling] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [customCouponCode, setCustomCouponCode] = React.useState<string>('');

  const onDismiss = () => setIsOpen(false);
  const _itemId = itemId?.toString() || '';

  const onApplyCoupon = () => {
    if (!customCouponCode) {
      setErrorMessage(t('REQUIRED_COUPON'));
    } else {
      setIsEnrolling(true);
      applyCouponMutation({
        variables: {
          couponCode: couponCode || customCouponCode,
          itemId: _itemId,
          itemType: 'course',
        },
      }).then(({ data }) => {
        const errors = data?.applyCoupon?.messages || [];

        if (data?.applyCoupon?.success) {
          applyQuickEnrollmentMutation({
            variables: {
              coupon: couponCode || customCouponCode,
              itemId: _itemId,
              itemType: 'course',
              pageSlug,
            },
          }).then(({ data: quickEnrollResponse }) => {
            if (
              quickEnrollResponse?.quickEnroll?.success &&
              quickEnrollResponse?.quickEnroll?.redirect
            ) {
              window.location.href = quickEnrollResponse.quickEnroll.redirect;
            } else {
              setErrorMessage(t('COUPON_ERROR_MESSAGE'));
              setIsEnrolling(false);
            }
          });
        } else {
          setIsEnrolling(false);
          setErrorMessage(
            errors?.[errors.length - 1]?.message || t('INVALID_COUPON'),
          );
        }
      });
    }
  };

  return (
    <>
      <PrimaryButton
        className="rounded-10 mt-16"
        label={customButtonLabel || t('REDEEM')}
        onClick={() => setIsOpen(true)}
        isDark={isDark}
        size="l"
        full
      />
      <Dialog
        open={isOpen}
        onDismiss={onDismiss}
        hideCloseBtn
        size="s"
        className="rounded-10 sm:m-auto"
        style={{ maxWidth: 564 }}
      >
        <div className="p-16 h-full sm:p-40 md:px-64">
          {isEnrolling ? (
            <div>
              <div className="mb-24 flex justify-center">
                <Enrolling />
              </div>
              <h2 className="tw-font-pop tw-font-semibold text-24 leading-28 text-center mb-16">
                {t('ENROLLING_TITLE')}
              </h2>
              <p className="font-body3 text-center mb-16">
                {t('ENROLLING_SUBTITLE')}
              </p>
            </div>
          ) : (
            <div>
              <p className="text-center mb-16">
                <Gift
                  size={64}
                  className="fill-current text-primary-main"
                  ignoreColor
                />
              </p>
              <h2 className="tw-font-pop tw-font-semibold text-24 leading-28 text-center mb-16">
                {t('QUICK_ENROLL_TITLE')}
              </h2>

              {couponCode ? (
                <p className="text-center font-button mb-40">
                  <span className="p-16 border border-dashed border-secondary-main rounded-10 inline-block">
                    {couponCode}
                  </span>
                </p>
              ) : (
                <>
                  <p className="font-body3 text-center mb-16">
                    {t('COUPON_FIELD_TITLE')}
                  </p>
                  <div className="mb-16 w-full sm:max-w-280 mx-auto flex justify-center sm:justify-start">
                    <TextField
                      label={t('COUPON_FIELD_LABEL')}
                      name="couponCode"
                      value={customCouponCode}
                      onChange={event => {
                        const value = event.target.value.replace(/[^\w]+/g, '');

                        setCustomCouponCode(value);
                      }}
                    />
                  </div>
                  <p className="font-body3 text-center mb-40">
                    {t('HAVE_NOT_COUPON')}
                    <a
                      href="https://secure.paguelofacil.com/LinkDeamon.cfm?CCLW=588BA57F825D6D9F6E230C2F39C94ACE84369A887E899DE043924E0122C38FF6&CMTN=12&CDSC=Experiencia+de+cliente%3A+Fidelizando+a+nuestros+usuarios&RETURN_URL=68747470733a2f2f63697564616464656c73616265722e6f72672f6c616e64696e672d61636164656d69612d636473&PARM_1=032d1cdcf53c6c8d809232b5b6e5255a"
                      className="text-secondary-light"
                    >
                      {t('HAVE_NOT_COUPON_HERE')}
                    </a>
                  </p>
                </>
              )}
              <div className="flex flex-col sm:flex-row justify-center">
                <div className="mb-16 sm:w-212 sm:mb-0 sm:mr-16">
                  <InverseButton
                    label={t('QUICK_ENROLL_CANCEL')}
                    size="l"
                    full
                    className="rounded-10"
                    onClick={onDismiss}
                  />
                </div>
                <div className="mb-16 sm:w-212 sm:mb-0">
                  <PrimaryButton
                    label={t('QUICK_ENROLL_ACCEPT')}
                    size="l"
                    full
                    className="rounded-10"
                    onClick={onApplyCoupon}
                  />
                </div>
              </div>
              {errorMessage && (
                <div className="bg-yellow-lighter mt-40 rounded-10 w-full p-8">
                  <p className="font-legals text-red-main text-center">
                    {errorMessage}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CourseButton;
