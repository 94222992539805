import { useMutation } from '@apollo/client';

import APPLY_COUPON_MUTATION from 'Jsx/CheckoutV4/Graphql/Mutation/ApplyCoupon.payments.graphql';
import {
  ApplyCouponMutation,
  ApplyCouponMutationVariables,
} from 'Jsx/CheckoutV4/Graphql/Mutation/types/ApplyCouponMutation';

const useApplyCoupon = () => {
  const [applyCouponMutation] = useMutation<
    ApplyCouponMutation,
    ApplyCouponMutationVariables
  >(APPLY_COUPON_MUTATION, {
    context: { clientName: 'payments' },
  });

  return { applyCouponMutation };
};

export default useApplyCoupon;
