const formatPrice = (price: number, currencySymbol: string): string => {
  /**
   * Some users from Brazil reported to the team that the price was shown in USD ($).
   * Using the locales that the current browser supports fixs the problem.
   */
  if (currencySymbol === 'R$') {
    price.toLocaleString();
  }

  if (currencySymbol === 'S/') {
    if (price % 1 > 0) {
      return price.toLocaleString('en-US', { minimumFractionDigits: 2 });
    }
  }

  return price.toLocaleString('en-US');
};

export default formatPrice;
