import { useQuery } from '@apollo/client';

import GET_COUPON_VALIDATION from '../graphql/GetCouponValidation.graphql';
import {
  GetCouponValidation,
  GetCouponValidationVariables,
} from '../graphql/types/GetCouponValidation';

const useCouponValidation = (pageSlug: string, skip?: boolean) => {
  const { data, loading } = useQuery<
    GetCouponValidation,
    GetCouponValidationVariables
  >(GET_COUPON_VALIDATION, { variables: { pageSlug }, skip });

  const couponResponseCode = data?.validateCouponPageUser?.response?.code;

  const COUPON_CODE = {
    CREATED: 14700,
    ALREADY_USED: 14702,
    EXPIRED: 14703,
    INVALID: 14704,
    GENERATE: 14709,
  };
  const ANONYMOUS_USER_CODE = 3003;

  const hasExpired = couponResponseCode === COUPON_CODE.EXPIRED;
  const alreadyUsed = couponResponseCode === COUPON_CODE.ALREADY_USED;
  const hasCoupon = couponResponseCode === COUPON_CODE.CREATED;
  const canGenerate = couponResponseCode === COUPON_CODE.GENERATE;
  const isInvalid = couponResponseCode === COUPON_CODE.INVALID;

  const isAnonymouesUser = couponResponseCode === ANONYMOUS_USER_CODE;
  const nextUrl = data?.validateCouponPageUser?.nextUrl || '/';
  const dueDate = data?.validateCouponPageUser?.couponValidUntil || '';
  const couponCode = data?.validateCouponPageUser?.couponCode || '';

  return {
    loading,
    hasExpired,
    alreadyUsed,
    hasCoupon,
    canGenerate,
    isInvalid,
    isAnonymouesUser,
    couponCode,
    dueDate,
    nextUrl,
  };
};

export default useCouponValidation;
