import React from 'react';

import { customLocalStorage } from '@crehana/utils';
import { useTranslation } from '@crehana/i18n';
import { Container, PrimaryButton } from '@crehana/ui';
import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';
import { useBrowserIdle } from '@crehana/react-hooks';

import ClientSideComponent from 'Jsx/Shared/ClientSideComponent';
import Loadable from 'Jsx/Shared/Loadable';
import { useCoursesInBundleState } from 'Jsx/ContextApis/CoursesInBundle';

import {
  enrollmentUrlWithSourceDetail,
  urlWithSourceDetail,
} from 'Jsx/CampaignLanding/utils';
import { PAGE_TYPES, Course } from 'Jsx/CampaignLanding/types';
import { usePageContext } from 'Jsx/CampaignLanding/Context';

import useCourseQuery from 'Jsx/CampaignLanding/hooks/useCourseQuery';
import { useFilterContext } from 'Jsx/CampaignLanding/hooks/useFilters';

import RetryFetch from '../RetryFetch';

import CourseList from './CourseList';
import CoursesNotFoundFallback from './CoursesNotFoundFallback';
import QuickEnrollmentButton from './QuickEnrollmentButton';

// @ts-expect-error
const CouponDialog = props => (
  <Loadable
    module={() =>
      import(
        /* webpackChunkName: "crehana-app-campaign-landing-coupondialog" */
        '../CouponDialog'
      )
    }
    {...props}
  />
);

export interface CoursesProps {
  isBlackTheme: boolean;
  showWarningDialog(): void;
  showBorderBottom?: boolean;
  isNotLogged: boolean;
}

const Courses: React.FC<CoursesProps> = ({
  isBlackTheme,
  showWarningDialog,
  showBorderBottom,
  isNotLogged,
}) => {
  const { t } = useTranslation();
  const {
    pageSlug,
    pageSize,
    pageType,
    prices,
    pageId,
    isCouponCampaign,
    checkoutGallery,
    isFreeInfluencerLandingPage,
    ctaButtonText,
    isQuickPackEnrollment,
    couponCode,
    isNewUserRequired,
    isQuickEnrollment,
    customButtonLabel,
  } = usePageContext();

  const { me, loading: loadingUser } = useMe();
  const isIdle = useBrowserIdle();
  const isCampaignLanding = pageType === PAGE_TYPES.COURSE;
  const isCustomBundlePageType =
    pageType === PAGE_TYPES.CUSTOM_BUNDLE ||
    pageType === PAGE_TYPES.PACKS_NEW_COUPON_FLOW;
  const { coursesInBundle, toggleCourseInBundle, checkIfItemIsInPack } =
    useCoursesInBundleState();
  const [couponDialogOpenData, setCouponDialogData] = React.useState<{
    open: boolean;
    nextUrl: string | null;
    next?: () => void;
  }>({
    open: false,
    nextUrl: null,
    next: undefined,
  });

  const { geAllSelectedFiltersStringified } = useFilterContext();

  const filtersStringified = geAllSelectedFiltersStringified();

  const {
    courses,
    customFetchMoreData,
    refetch,
    hasNextPage,
    loading,
    totalCount,
    error,
  } = useCourseQuery({
    newOrderBy: filtersStringified.orderBy,
    newCategories: filtersStringified.category,
    softwaresSelected: filtersStringified.software,
    levelSelected: filtersStringified.level,
    search: filtersStringified.searchText,
    language: filtersStringified.language,
    page: pageSlug,
    pageSize,
    newSubCategories: '',
    cursor: '',
  });

  const canRenderFreeInfluencerButton =
    isFreeInfluencerLandingPage && !loadingUser && !me;

  const onClickBuyNow = (nextUrl: string, next?: () => any) => {
    const campaigns = customLocalStorage.getItem('campaignPopupShown');

    if (
      checkoutGallery.length > 0 &&
      (!campaigns || !JSON.parse(campaigns).includes(pageSlug))
    ) {
      setCouponDialogData(state => ({
        ...state,
        open: true,
        nextUrl,
        next,
      }));
    } else {
      window.location.href = nextUrl;
    }
  };

  const hasCoupon = ctaButtonText === 'affiliates';

  const openAuthDialog =
    isNotLogged && (isQuickEnrollment || isNewUserRequired);
  const showCustomButton = openAuthDialog || isQuickEnrollment;

  const navigateToCourseDetail = (courseUrl: string) => () => {
    if (isCustomBundlePageType) {
      const goToCourseDetail = urlWithSourceDetail.getUrl({
        url: isQuickPackEnrollment
          ? `${courseUrl}?from_campaign=${pageSlug}&custom_bundle_qe=${isQuickPackEnrollment}&couponCode=${couponCode}`
          : `${courseUrl}?from_campaign=${pageSlug}`,
        sourceDetail: urlWithSourceDetail.SOURCES.COURSE_LIST,
      });

      window.location.href = goToCourseDetail;
    } else {
      const goToCourseDetail = urlWithSourceDetail.getUrl({
        url: hasCoupon ? `${courseUrl}?affiliate_slug=${pageSlug}` : courseUrl,
        sourceDetail: urlWithSourceDetail.SOURCES.COURSE_LIST,
      });

      window.location.href = goToCourseDetail;
    }
  };

  const renderCustomButton = (course: Course) => {
    const id = course.originalId;

    if (canRenderFreeInfluencerButton) {
      return (
        <PrimaryButton
          onClick={() => {
            const freeEnrollmentUrl = enrollmentUrlWithSourceDetail.getUrl({
              enrollmentUrl: `/enrollment/free-access/${pageId}/${id}/`,
              sourceDetail: enrollmentUrlWithSourceDetail.SOURCES.COURSE_LIST,
            });

            window.location.href = `/registro/?next=/registro-extra/?enrollmenturl=${freeEnrollmentUrl}`;
          }}
          full
          className="mt-16"
          label="Inscríbete gratis"
        />
      );
    }

    if (openAuthDialog) {
      return (
        <PrimaryButton
          onClick={() => {
            window.showRegisterModal(course.urlToBuyNow, undefined, {
              // @ts-expect-error
              customHandler: res => {
                if (isNewUserRequired && res.isNewUser) {
                  res.continue({ nextUrl: course.urlToBuyNow });
                } else if (isQuickEnrollment) {
                  window.location.reload();
                } else {
                  showWarningDialog();
                }
              },
              registerTitle: t(
                'REGISTER_TITLE',
                'Únete a la comunidad Crehana',
              ),
              registerMessage: t(
                'REGISTER_MESSAGE',
                'Ingresa tus datos para canjear tu curso gratis',
              ),
              isVersion2: true,
              noSwitcher: isNewUserRequired,
            });
          }}
          full
          className="mt-16"
          label={customButtonLabel || t('REDEEM', 'Canjéalo ahora')}
        />
      );
    }
    if (isQuickEnrollment) {
      return (
        <QuickEnrollmentButton
          isDark={isBlackTheme}
          couponCode={couponCode}
          itemId={id as number}
          pageSlug={pageSlug}
          customButtonLabel={customButtonLabel || undefined}
        />
      );
    }

    return null;
  };

  if (error) {
    return <RetryFetch refetch={() => refetch()} />;
  }

  return (
    // padding top in mobile to show the bg-gray-lighter (and generate a little margin between filter-mobile and courses container)
    // in desktop we use margin-top because padding-top does not work to separate the content
    <div className="pb-160" id="courseListDesktopWrapper">
      {!loading && courses.length === 0 ? (
        <CoursesNotFoundFallback
          pageSlug={pageSlug}
          pageSize={pageSize}
          searchText={filtersStringified.searchText}
          isBlackTheme={isBlackTheme}
        >
          {({
            loading: loadingFallback,
            hasNextPage: hasNextPageFallback,
            courses: coursesFallback,
            customFetchMoreData: customFetchMoreDataFallback,
          }) => (
            <CourseList
              loading={loadingFallback}
              hasNextPage={hasNextPageFallback}
              courses={coursesFallback}
              customFetchMoreData={customFetchMoreDataFallback}
              navigateToCourseDetail={navigateToCourseDetail}
              isCustomBundle={isCustomBundlePageType}
              isCampaignLanding={isCampaignLanding}
              // @ts-expect-error
              coursesInPack={coursesInBundle}
              checkIfItemIsInPack={checkIfItemIsInPack}
              // @ts-expect-error
              onClickCustomBundleButton={toggleCourseInBundle}
              onClickBuyNow={onClickBuyNow}
              isBlackTheme={isBlackTheme}
              // @ts-expect-error
              renderFreeInfluencerButton={
                canRenderFreeInfluencerButton || showCustomButton
                  ? renderCustomButton
                  : undefined
              }
              hasCoupon={hasCoupon}
            />
          )}
        </CoursesNotFoundFallback>
      ) : (
        <div>
          {typeof totalCount === 'number' &&
            totalCount > 0 &&
            typeof window !== 'undefined' &&
            filtersStringified.searchText &&
            filtersStringified.searchText.length > 0 &&
            !loading && (
              <React.Fragment>
                <div
                  className={`${
                    isBlackTheme ? 'bg-black' : 'bg-white'
                  }  py-12 sm:py-24`}
                >
                  <Container>
                    <p
                      className={`${
                        isBlackTheme ? 'text-white' : 'text-base-main'
                      } font-subtitle2 font-normal`}
                    >
                      <span> {totalCount} resultados para </span>
                      <span
                        className="font-subtitle2"
                        style={{ marginLeft: 3 }}
                      >
                        {filtersStringified.searchText}
                      </span>
                    </p>
                  </Container>
                </div>
              </React.Fragment>
            )}
          <CourseList
            loading={loading}
            courses={courses}
            hasNextPage={hasNextPage}
            customFetchMoreData={customFetchMoreData}
            navigateToCourseDetail={navigateToCourseDetail}
            isCustomBundle={isCustomBundlePageType}
            isCampaignLanding={isCampaignLanding}
            // @ts-expect-error
            onClickCustomBundleButton={toggleCourseInBundle}
            // @ts-expect-error
            coursesInPack={coursesInBundle}
            checkIfItemIsInPack={checkIfItemIsInPack}
            onClickBuyNow={onClickBuyNow}
            isBlackTheme={isBlackTheme}
            // @ts-expect-error
            renderFreeInfluencerButton={
              canRenderFreeInfluencerButton || showCustomButton
                ? renderCustomButton
                : undefined
            }
            hasCoupon={hasCoupon}
          />
        </div>
      )}
      {prices && isCustomBundlePageType && (
        <Loadable
          module={() =>
            import(
              /* webpackChunkName: "campaign-landing-courses-in-bundle" */
              'Jsx/CampaignLanding/components/CoursesInBundle'
            )
          }
          isCouponCampaign={isCouponCampaign}
          onClickBuyNow={onClickBuyNow}
          showBorderBottom={showBorderBottom}
          isQuickPackEnrollment={isQuickPackEnrollment}
          couponCode={couponCode}
        />
      )}
      <ClientSideComponent>
        {isIdle && !isNewUserRequired ? (
          <CouponDialog
            open={couponDialogOpenData.open}
            checkoutGallery={checkoutGallery}
            nextUrl={couponDialogOpenData.nextUrl}
            next={couponDialogOpenData.next}
            pageSlug={pageSlug}
            onDismiss={() => {
              setCouponDialogData(state => ({
                ...state,
                open: false,
                nextUrl: null,
                next: undefined,
              }));
            }}
          />
        ) : null}
      </ClientSideComponent>
    </div>
  );
};

export default Courses;
