import { useQuery } from '@apollo/client';

import { notEmpty } from '@crehana/utils';

import CAMPAIGN_LANDING_COURSES_QUERY from '../graphql/CampaignLandingCourses.graphql';
import {
  GetCampaignLandingCourses,
  GetCampaignLandingCoursesVariables,
} from '../graphql/types/GetCampaignLandingCourses';

function useCourseQuery({
  page, // the page slug
  pageSize,
  newCategories,
  newSubCategories,
  search,
  levelSelected,
  softwaresSelected,
  newOrderBy,
  language,
}: GetCampaignLandingCoursesVariables) {
  const { data, loading, fetchMore, refetch, error } = useQuery<
    GetCampaignLandingCourses,
    GetCampaignLandingCoursesVariables
  >(CAMPAIGN_LANDING_COURSES_QUERY, {
    variables: {
      page,
      pageSize,
      newCategories,
      newSubCategories,
      search,
      levelSelected,
      softwaresSelected,
      newOrderBy,
      cursor: '',
      language,
    },
  });

  const { pageInfo, edges, totalCount } = data?.courses || {};

  const customFetchMoreData = () => {
    return fetchMore({
      variables: {
        cursor: pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          !fetchMoreResult?.courses?.edges.length ||
          !prev.courses?.edges.length
        ) {
          return prev;
        }
        return {
          ...prev,
          courses: {
            pageInfo: {
              ...fetchMoreResult.courses.pageInfo,
            },
            edges: [...prev.courses.edges, ...fetchMoreResult.courses.edges],
            totalCount: fetchMoreResult.courses.totalCount,
            __typename: prev.courses.__typename,
          },
        };
      },
    });
  };

  const courses = edges?.map(e => e?.node).filter(notEmpty) || [];

  return {
    loading,
    error,
    courses,
    hasNextPage: pageInfo?.hasNextPage,
    totalCount: totalCount || 0,
    fetchMore,
    refetch,
    customFetchMoreData,
  };
}

export default useCourseQuery;
