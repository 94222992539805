export interface ProviderPlugin {
  initialize: () => void;
  loaded: () => boolean;
}

interface ThirdPartyInit {
  providers: Array<ProviderPlugin>;
}

export function thirdPartyLoader({ providers }: ThirdPartyInit) {
  providers.forEach(pro => pro.initialize());
}

export default thirdPartyLoader;
