import { useEffect, useState } from 'react';

import { shuffleArray } from '@crehana/utils';

import { SOCIAL_NOTIFICATION_USERS, SocialUserType } from './constants';
import SocialNotification from './SocialNotification';

const useTrustPulseNotification = ({ isActive }: { isActive: boolean }) => {
  const [isOpenTrustPulse, setIsOpenTrustPulse] = useState<boolean>(false);
  const [trustPulseUser, settrustPulseUser] = useState<SocialUserType>({
    id: 1,
    name: 'Julio Casanova',
    image: '/static/img/membership/flags/pe.png',
    timeLabel: 'Hace 2 horas desde Perú',
    city: 'Perú',
  });

  useEffect(() => {
    if (isActive) {
      let timeout: number;
      let innterTimeout: number;

      const secondsPerNotification = 10000;
      const secondsBetweenNotification = 3000;

      shuffleArray(SOCIAL_NOTIFICATION_USERS).forEach((user, idx) => {
        timeout = window.setTimeout(() => {
          settrustPulseUser(user);
          setIsOpenTrustPulse(true);

          innterTimeout = window.setTimeout(() => {
            setIsOpenTrustPulse(false);
          }, secondsPerNotification);
        }, idx * secondsPerNotification + secondsBetweenNotification * idx);
      });

      return () => {
        clearTimeout(timeout);
        clearTimeout(innterTimeout);
      };
    }
  }, [setIsOpenTrustPulse, isActive]);

  return { isOpenTrustPulse, trustPulseUser, SocialNotification };
};

export default useTrustPulseNotification;
