import styled from 'styled-components';

export const AnimatedWrapper = styled.div<{
  isOpen?: boolean;
}>`
  opacity: 0;
  transform: translateY(150px);
  transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out;
  ${props => {
    if (props.isOpen) {
      return `
      opacity: 1;
      transform: translateY(0px);
      `;
    }
  }}
`;
