import React from 'react';

import { Filter as FilterIcon } from '@crehana/icons';
import { colors } from '@crehana/ui';

import { useOnClickOutside } from '@crehana/react-hooks';

type Props = {
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  active: boolean;
  allFilterSelectedCount: number;
  isFilterOpen: boolean;
};

const FilterButton: React.FC<Props> = ({
  onClick,
  className,
  style,
  children,
  active,
  allFilterSelectedCount,
  isFilterOpen,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(
    containerRef,
    () => {
      if (isFilterOpen) {
        onClick();
      }
    },
    [],
  );

  return (
    <div className="w-full" ref={containerRef}>
      <button
        type="button"
        className={`flex justify-center items-center w-full ${className || ''}`}
        onClick={onClick}
        style={style}
        data-testid="page-filter-filterBtn"
      >
        <FilterIcon size={14} color={active ? colors.secondaryMain : ''} />
        <span
          className={`ml-8 font-subtitle3 ${
            active ? 'text-secondary-main' : 'text-base-main'
          }`}
        >
          Filtrar{' '}
          {allFilterSelectedCount > 0 ? `(${allFilterSelectedCount})` : ''}
        </span>
      </button>
      {children}
    </div>
  );
};

export default FilterButton;
