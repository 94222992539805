import React from 'react';

import { Prices as PricesProp } from '../../../types/Course';

import formatPrice from '../../../utils/formatPrice';

const Price: React.FC<PricesProp & { dark?: boolean }> = ({
  price,
  currencySymbol,
  discount,
  realPrice,
  dark,
}) => {
  if (price === 0) {
    return (
      <span className="font-currency course-card-price text-primary-main">
        Gratis
      </span>
    );
  }
  const priceColor = discount > 0 ? 'text-red-main' : 'text-purple-main';
  const priceFormatted = formatPrice(price, currencySymbol);

  return (
    <>
      <span
        className={`font-h4 ${dark ? 'text-white' : priceColor} inline-block`}
      >
        {currencySymbol}
        {priceFormatted}
      </span>
      {discount > 0 ? (
        <span
          className={`ml-8 font-discount-s text-14 ${
            dark ? 'text-white' : 'text-gray-dark'
          }`}
        >
          {`${currencySymbol}${
            currencySymbol === 'R$'
              ? realPrice!.toLocaleString()
              : realPrice!.toLocaleString('en-US')
          }`}
        </span>
      ) : null}
    </>
  );
};

export default Price;
