import { CourseTypeEnum as CourseTypeEnumLegacy } from '@crehana/web/dist/__generated__/graphql-schema';
import { CourseTypeEnum } from '@crehana/web/dist/__generated__/graphql-schema.v4';

export const checkIfIsRockstarCourse = (courseType: string | undefined) => {
  return (
    courseType === CourseTypeEnumLegacy.COURSE_TYPE_ROCKSTAR ||
    courseType === CourseTypeEnum.ROCKSTAR ||
    courseType?.toLowerCase() === 'rockstar'
  );
};
