import React from 'react';
import styled from 'styled-components';

import { colors } from '@crehana/ui';
import { useTranslation } from '@crehana/i18n';
import { useCountdown } from '@crehana/react-hooks';

import { isTimeExpired } from 'Jsx/CampaignLanding/utils';

const StyledDiv = styled.div<{ backgroundColor?: string | null }>`
  background-color: ${props =>
    props.backgroundColor || props.theme.colors.red.main};
  transition: opacity 0.1s ease-in-out;
`;

export interface MiniTimerProps {
  date: string;
  textColor?: string | null;
  backgroundColor?: string | null;
  show?: boolean;
  hideTitle?: boolean;
}

const MiniTimer: React.FC<MiniTimerProps> = ({
  date,
  textColor,
  backgroundColor,
  show = true,
  hideTitle = false,
}) => {
  const { t } = useTranslation();
  const { days, hours, minutes, seconds } = useCountdown(new Date(date), !show);

  if (isTimeExpired(date)) {
    return null;
  }

  return (
    <StyledDiv
      backgroundColor={backgroundColor}
      className={`justify-center p-8 ${show ? 'flex' : 'hidden'}`}
    >
      <span
        style={{
          color: textColor || colors.blueMain,
        }}
        className="font-label"
      >
        {`${
          hideTitle ? '' : t('TIMER_TITLE_2')
        } ${days}D : ${hours}H : ${minutes}M : ${seconds}S`}
      </span>
    </StyledDiv>
  );
};

export default MiniTimer;
