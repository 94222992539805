import { ProviderPlugin } from './thirdPartyLoader';

const facebookPixelProvider = (
  additionalPixelIDs: string[] = [],
): ProviderPlugin => ({
  initialize: () => {
    if (window.APP_DEBUG) {
      return;
    }
    delete window.fbq;
    // prettier-ignore
    /* eslint-disable */
      // @ts-ignore
      !function(f,b,e,v,n,t,s)
      // @ts-ignore
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      // @ts-ignore
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      // @ts-ignore
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      // @ts-ignore
      n.queue=[];t=b.createElement(e);t.async=!0;
      // @ts-ignore
      t.src=v;s=b.getElementsByTagName(e)[0];
      // @ts-ignore
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      /* eslint-enable */

    if (typeof window.fbq === 'function') {
      // @ts-expect-error shows error because we are deleting fbq from window before loading the script
      window.fbq('init', window.FACEBOOK_APP_ID);
      // Load additional Facebook Pixel ID's
      additionalPixelIDs.forEach(pixelID => {
        // @ts-expect-error shows error because we are deleting fbq from window before loading the script
        window.fbq('init', pixelID);
      });
      // @ts-expect-error shows error because we are deleting fbq from window before loading the script
      window.fbq('track', 'PageView');

      if (window.facebookQueue && window.facebookQueue.length) {
        window.facebookQueue.forEach(args => {
          // @ts-expect-error shows error because we are deleting fbq from window before loading the script
          window.fbq(...args);
        });
      }
    }
  },
  loaded: () => !!window.fbq,
});

export default facebookPixelProvider;
