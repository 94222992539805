import { useEffect } from 'react';

const useFacebookPageView = () => {
  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  }, []);
};

export default useFacebookPageView;
