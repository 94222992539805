import * as React from 'react';

type Props = {
  title: string;
  url: string;
  metaDescription?: string;
  dark?: boolean;
  onClickTitleHandler: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => void;
};

const Title: React.FC<Props> = props => {
  const { title, url, metaDescription, dark, onClickTitleHandler } = props;

  return (
    <React.Fragment>
      <a
        href={url}
        className="flex block whitespace-normal"
        onClick={onClickTitleHandler}
      >
        <span
          className={`min-h-36 font-subtitle3 ${
            dark ? 'text-white' : 'text-base-main'
          }`}
        >
          {title}
        </span>
      </a>
      {metaDescription && <div className="hidden">{metaDescription}</div>}
    </React.Fragment>
  );
};

export default Title;
