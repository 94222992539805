// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Dialog,
  Container,
  Row,
  Col,
  Checkbox,
  Radio,
  colors,
} from '@crehana/ui';
import { NewArrowLeft } from '@crehana/icons';
import { useTranslation } from '@crehana/i18n';

import { useFilterContext } from 'Jsx/CampaignLanding/hooks/useFilters';
import { isTimeExpired } from 'Jsx/CampaignLanding/utils';

import MiniTimer from '../MiniTimer';

import ButtonsAndSearch from './ButtonsAndSearch';

interface Props {
  title?: string | null;
  timerProps: {
    date?: string | null;
    textColor?: string | null;
    backgroundColor?: string | null;
    show?: boolean;
  };
  isFilterBarOnTop: boolean;
  isBlackTheme: boolean;
}

type VIEWS = 'filters' | 'orderBy';

const TabletFilter: React.FC<Props> = ({
  title,
  timerProps,
  isFilterBarOnTop,
  isBlackTheme,
}) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = React.useState<{
    view: VIEWS;
    open: boolean;
  }>({
    view: 'filters',
    open: false,
  });

  const {
    categories,
    categorySelected,
    setCategory,
    // ,
    levels,
    levelSelected,
    selectLevel,
    // ,
    languages,
    languageSelected,
    selectLanguage,
    // ,
    softwares,
    softwaresSelected,
    selectSoftware,
    // ,
    orderByOptions,
    orderBySelected,
    setOrderBy,
    clearAllFilters,
    allFilterSelectedCount,
  } = useFilterContext();

  const closeModal = () => {
    setModalState({
      view: 'filters',
      open: false,
    });
  };

  const openModal = (view: VIEWS) => () =>
    setModalState({
      view,
      open: true,
    });

  const isFilterButtonActive = Boolean(allFilterSelectedCount);

  return (
    <div
      className={`w-full relative  px-20 sm:px-32 lg:px-0 ${
        isFilterBarOnTop ? 'py-8' : 'py-24'
      } ${isBlackTheme ? 'bg-black' : 'bg-white'}`}
    >
      <div
        className="flex items-center w-full justify-between"
        style={{ marginTop: 0 }}
      >
        <div
          className={isFilterBarOnTop ? 'opacity-100' : 'opacity-0'}
          style={{ transition: 'opacity 0.2s ease-in-out' }}
        >
          <span
            className={`font-subtitle3 ${isBlackTheme ? 'text-white' : ''}`}
          >
            {title}
          </span>
        </div>
        <div className="ml-32">
          <ButtonsAndSearch
            openModal={openModal}
            isFilterButtonActive={isFilterButtonActive}
            isOrderButtonActive={!!orderBySelected}
            filterSelectedCount={allFilterSelectedCount}
            isBlackTheme={isBlackTheme}
          />
        </div>
      </div>
      <Dialog
        open={modalState.open}
        onDismiss={closeModal}
        containerHTMLProps={{
          'data-testid': 'page-filter-tablet-filterBox',
        }}
        hideCloseBtn
        size="full"
        style={{ backgroundColor: isBlackTheme ? '#1C1C23' : '' }}
      >
        {timerProps.date && !isTimeExpired(timerProps.date) && (
          <MiniTimer
            date={timerProps.date}
            textColor={timerProps.textColor}
            backgroundColor={timerProps.backgroundColor}
            show={timerProps.show}
          />
        )}
        <Container className="pb-64">
          {modalState.view === 'filters' ? (
            <React.Fragment>
              <Row
                className={`items-center sticky ${
                  isBlackTheme ? 'bg-base-main-80' : 'bg-white'
                }`}
                css={`
                  position: sticky;
                  top: 0;
                  margin-top: 0;
                `}
              >
                <Col>
                  <button
                    type="button"
                    className="flex items-center py-16 w-full"
                    onClick={closeModal}
                  >
                    <NewArrowLeft size={24} color={colors.secondaryLight} />
                    <span className="font-link ml-8 inline-block text-secondary-light">
                      Filtrar por
                    </span>
                  </button>
                </Col>
                <Col className="flex justify-end">
                  <button
                    type="button"
                    className="py-16 w-full text-right"
                    onClick={clearAllFilters}
                    css={`
                      opacity: ${isFilterButtonActive ? 1 : 0};
                      transition: opacity 0.2s ease-in-out;
                    `}
                  >
                    <span className="font-label text-base-lighter">
                      Limpiar todos los filtros
                    </span>
                  </button>
                </Col>
              </Row>
              <Row className="mt-24">
                <Col>
                  <span
                    className={`font-subtitle3 ${
                      isBlackTheme ? 'text-white' : ''
                    }`}
                  >
                    {t('CATEGORIES')}
                  </span>
                </Col>
              </Row>
              {/* ============================================================================ */}
              {/* ===============================  Categories ================================ */}
              {/* ============================================================================ */}
              <Row className="border-b border-solid border-gray-main">
                {categories.map(category => {
                  const isSelected = Boolean(
                    categorySelected && category.slug === categorySelected.slug,
                  );

                  return (
                    <Col key={category.slug} xs={4}>
                      <Radio
                        checked={isSelected}
                        label={category.name}
                        className="py-16 w-full"
                        data-testid="page-filter-category-radio"
                        onClick={() => {
                          if (isSelected) {
                            setCategory(undefined);
                          } else {
                            setCategory(category);
                          }
                        }}
                        onChange={() => {}}
                        labelProps={{
                          type: 'body4',
                          truncate: true,
                        }}
                        darkMode={isBlackTheme}
                      />
                    </Col>
                  );
                })}
              </Row>
              {/* ============================================================================ */}
              {/* ===============================  Levels ================================ */}
              {/* ============================================================================ */}
              <Row className="mt-40">
                <Col>
                  <span
                    className={`font-subtitle3 ${
                      isBlackTheme ? 'text-white' : ''
                    }`}
                  >
                    {t('LEVELS')}
                  </span>
                </Col>
              </Row>
              <Row className="border-b border-solid border-gray-main">
                {levels.map(level => {
                  const isSelected = !!levelSelected.find(
                    l => l.value === level.value,
                  );

                  return (
                    <Col key={level.value} xs={4}>
                      <Checkbox
                        data-testid="page-filter-level-checkbox"
                        label={level.label}
                        checked={isSelected}
                        className="py-16 w-full"
                        onClick={() => {
                          selectLevel(level);
                        }}
                        darkMode={isBlackTheme}
                      />
                    </Col>
                  );
                })}
              </Row>
              {/* ============================================================================ */}
              {/* =============================== Languages ================================ */}
              {/* ============================================================================ */}
              <Row className="mt-40">
                <Col>
                  <span
                    className={`font-subtitle3 ${
                      isBlackTheme ? 'text-white' : ''
                    }`}
                  >
                    {t('LANGUAGES')}
                  </span>
                </Col>
              </Row>
              <Row className="border-b border-solid border-gray-main">
                {languages.map(language => {
                  const isSelected = !!languageSelected.find(
                    l => l.value === language.value,
                  );

                  return (
                    <Col key={language.value} xs={4}>
                      <Checkbox
                        className="py-16 w-full"
                        data-testid="page-filter-language-checkbox"
                        label={language.label}
                        checked={isSelected}
                        onClick={() => {
                          selectLanguage(language);
                        }}
                        darkMode={isBlackTheme}
                      />
                    </Col>
                  );
                })}
              </Row>
              {/* ============================================================================ */}
              {/* =============================== Softwares ================================ */}
              {/* ============================================================================ */}
              <Row className="mt-40">
                <Col>
                  <span
                    className={`font-subtitle3 ${
                      isBlackTheme ? 'text-white' : ''
                    }`}
                  >
                    {t('SOFTWARES')}
                  </span>
                </Col>
              </Row>
              <Row>
                {softwares.map(software => {
                  const isSelected = !!softwaresSelected.find(
                    s => s.value === software.value,
                  );

                  return (
                    <Col key={software.label} xs={4}>
                      <Checkbox
                        className="py-16 w-full"
                        data-testid="page-filter-software-checkbox"
                        label={software.label}
                        checked={isSelected}
                        onClick={() => {
                          selectSoftware(software);
                        }}
                        darkMode={isBlackTheme}
                      />
                    </Col>
                  );
                })}
              </Row>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* ============================================================================ */}
              {/* =============================== OrderBy ================================ */}
              {/* ============================================================================ */}
              <Row style={{ marginTop: 0 }}>
                <Col xs={12}>
                  <button
                    type="button"
                    className="flex items-center py-16 w-full"
                    data-testid="page-filter-orderBy-radio"
                    onClick={closeModal}
                  >
                    <NewArrowLeft size={24} color={colors.secondaryLight} />
                    <span className="font-link ml-8 inline-block text-secondary-light">
                      Ordenar por
                    </span>
                  </button>
                </Col>
              </Row>
              <Row className="mt-24">
                {orderByOptions.map(orderByOption => {
                  const isSelected = Boolean(
                    orderBySelected &&
                      orderByOption.value === orderBySelected.value,
                  );

                  return (
                    <Col xs={3} key={orderByOption.value}>
                      <Radio
                        checked={isSelected}
                        label={orderByOption.label}
                        className="py-16 w-full"
                        onClick={() => {
                          if (isSelected) {
                            setOrderBy(undefined);
                          } else {
                            setOrderBy(orderByOption);
                          }
                        }}
                        onChange={() => {}}
                        darkMode={isBlackTheme}
                      />
                    </Col>
                  );
                })}
              </Row>
            </React.Fragment>
          )}
        </Container>
      </Dialog>
    </div>
  );
};

export default TabletFilter;
