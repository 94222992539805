import React, { FC } from 'react';

import { useTranslation } from '@crehana/i18n';

import { SocialNotificationPortalProps } from './types';
import { AnimatedWrapper } from './SocialNotification.styles';

const SocialNotification: FC<SocialNotificationPortalProps> = ({
  isOpen,
  user,
  notificationMessage,
  isSingleParagraph,
  className,
  positionX,
  positionY,
  borderRadius,
  isMobile,
}) => {
  const [randomNumber, setRandomNumber] = React.useState<number>(
    Math.floor(Math.random() * 7 + 1) + 2,
  );

  const { t } = useTranslation();

  React.useEffect(() => {
    setRandomNumber(Math.floor(Math.random() * 7 + 1) + 2);
  }, [user]);

  if (!user) {
    return null;
  }

  const positionStyleX = positionX || { left: isMobile ? '50%' : 32 };
  const positionStyleY = positionY || { bottom: 32 };

  return (
    <AnimatedWrapper
      isOpen={isOpen}
      className={`fixed flex items-center bg-white border-2 border-solid border-gray-main z-dialog ${className}`}
      style={{
        borderRadius: borderRadius || 50,
        ...positionStyleX,
        ...positionStyleY,
        transform: isMobile ? 'translateX(-50%)' : '0',
        width: isMobile ? 'calc(100% - 16px)' : '300px',
      }}
    >
      <div className="flex items-center justify-center pl-16 rounded-full md:py-8 md:pl-8">
        <img src={user.image} alt={user.name} className="w-24 h-auto md:w-72" />
      </div>
      {isSingleParagraph ? (
        <div className="p-16">
          <p className="font-body4">
            <span>
              {t('USERPROOF_LABEL', {
                interpolation: {
                  userQuantity: randomNumber,
                  country: user.city,
                },
                defaultValue: `¡Hay ${randomNumber} personas que están comprando lo mismo que tú desde ${user.city}!`,
              })}
            </span>
          </p>
        </div>
      ) : (
        <div className="p-16">
          <p className="font-subtitle4 text-gray-main-dark-mode">{user.name}</p>
          {notificationMessage && (
            <p className="font-body4">
              <span>{notificationMessage}</span>
            </p>
          )}
          <p className="mt-4 font-legals">{user.timeLabel}</p>
        </div>
      )}
    </AnimatedWrapper>
  );
};

export default SocialNotification;
