/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import dispatchEvent from 'Jsx/Utils/dispatchEvent';

import { CUSTOM_EVENTS } from 'Jsx/CampaignLanding/components/CoursesInBundle/types';
import { useFilterContext } from 'Jsx/CampaignLanding/hooks/useFilters';

import SearchField from '../SearchField';

import FilterButton from './FilterButton';
import OrderByButton from './OrderByButton';
import Filters from './Filters';

interface Props {
  title?: string | null;
  timerProps: {
    date?: string | null;
    textColor?: string | null;
    backgroundColor?: string | null;
    show?: boolean;
  };
  isFilterBarOnTop: boolean;
  isBlackTheme: boolean;
  showFixedCustomBundle: boolean;
}

const DesktopFilters: React.FC<Props> = ({
  isFilterBarOnTop,
  isBlackTheme,
  showFixedCustomBundle,
}) => {
  const {
    categories,
    categorySelected,
    setCategory,
    levels,
    levelSelected,
    selectLevel,
    languages,
    languageSelected,
    selectLanguage,
    softwares,
    softwaresSelected,
    selectSoftware,
    orderByOptions,
    orderBySelected,
    setOrderBy,
    setSearchTextDebouncedValue,

    allFilterSelectedCount,
    clearAllFilters,
  } = useFilterContext();

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [isOrderByOpen, setIsOrderByOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  const searchCoursesByText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;

    setSearchText(query);
    setSearchTextDebouncedValue(query);
  };
  const clearSearchText = () => {
    setSearchText('');
    setSearchTextDebouncedValue('');
  };

  const closeFilterComponent = () => {
    setIsFilterOpen(false);
    dispatchEvent({
      target: document.body,
      detail: {
        filterType: 'desktop',
        isOpen: false,
      },
      type: CUSTOM_EVENTS.FILTER_BUTTON_CLICK,
    });
  };

  const toggleFilterComponentVisibility = () => {
    setIsFilterOpen(prevState => {
      dispatchEvent({
        target: document.body,
        detail: {
          filterType: 'desktop',
          isOpen: !prevState,
        },
        type: CUSTOM_EVENTS.FILTER_BUTTON_CLICK,
      });
      return !prevState;
    });
  };

  const toggleOrderByVisibility = () => {
    setIsOrderByOpen(prev => {
      dispatchEvent({
        target: document.body,
        detail: {
          filterType: 'desktop',
          isOpen: !prev,
        },
        type: CUSTOM_EVENTS.FILTER_BUTTON_CLICK,
      });
      return !prev;
    });
  };

  const isFilterButtonActive = Boolean(allFilterSelectedCount);

  // constants to reuse props, <FilterButton /> and <Filter /> are in two different places
  const filterButtonProps = {
    onClick: toggleFilterComponentVisibility,
    isFilterOpen,
    active: isFilterButtonActive,
    allFilterSelectedCount,
    isBlackTheme,
  };
  const filterComponentProps = {
    clearFilters: clearAllFilters,
    isFilterOpen,
    // categories props
    categories,
    categorySelected,
    setCategory,
    // levels props
    levels,
    levelSelected,
    selectLevel,
    // languages props
    languages,
    languageSelected,
    selectLanguage,
    // softwares props
    softwares,
    softwaresSelected,
    selectSoftware,
    isFilterBarOnTop,
    isFilterButtonActive,
    closeFilterComponent,
  };
  const orderByButtonProps = {
    onClick: toggleOrderByVisibility,
    orderByOptions,
    setOrderBy,
    active: Boolean(isOrderByOpen || orderBySelected),
    orderBySelected,
    isOrderByOpen,
    isFilterBarOnTop,
  };

  return (
    <div
      className={`w-full ${isBlackTheme ? 'bg-black' : 'bg-white'} relative`}
      style={{
        transform:
          showFixedCustomBundle && isFilterBarOnTop
            ? 'translateY(80px)'
            : 'translateY(0)',
        transition: 'transform .2s ease-in-out',
      }}
    >
      <div
        className={`absolute w-full bottom-0 ${
          isBlackTheme ? 'opacity-50' : 'opacity-100'
        } ${
          isFilterBarOnTop
            ? 'border-b border-solid border-gray-main'
            : 'border-b border-solid border-transparent'
        }`}
      />
      <div className="relative flex justify-center">
        <div
          className={`flex px-0 sm:px-32 lg:px-0 w-full ${
            isFilterBarOnTop ? 'my-12 justify-center' : 'my-24 justify-end'
          }`}
          style={{ maxWidth: 1152 }}
        >
          <div className="flex items-center mr-32">
            <FilterButton {...filterButtonProps}>
              {/* Remember, the filter component has a position absolute relative to the Container.
              The FilterButton must not have a position relative! */}
              <Filters {...filterComponentProps} isBlackTheme={isBlackTheme} />
            </FilterButton>
          </div>
          <div className="flex items-center mr-24">
            <OrderByButton
              {...orderByButtonProps}
              isBlackTheme={isBlackTheme}
            />
          </div>
          <div className="max-w-280 w-full">
            <SearchField
              value={searchText}
              onChange={searchCoursesByText}
              onClear={clearSearchText}
              isBlackTheme={isBlackTheme}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopFilters;
