import * as React from 'react';

import { useCountdown, useBrowserIdle } from '@crehana/react-hooks';
import { useTranslation } from '@crehana/i18n';

const isTimeExpired = (date: string) => {
  return (
    !new Date(date).getTime() ||
    new Date(date).getTime() - new Date().getTime() <= 0
  );
};

const TimerItem: React.FC<{
  count: string;
  label: string;
  showDots?: boolean;
  dataTestId: string;
}> = ({ count, label, showDots = true, dataTestId }) => (
  <div className="flex items-center">
    <div className="flex flex-col" style={{ width: 42 }}>
      <span className="font-caption">{label}</span>
      <span className="font-h4 text-24 leading-28" data-testid={dataTestId}>
        {count}
      </span>
    </div>
    {showDots && <div className="font-h4 text-24 leading-28 mx-8">{' . '}</div>}
  </div>
);

type CountdownTimerProps = {
  date: string;
  timerBackgroundColor?: string;
  timerCustomColor?: string;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  date,
  timerBackgroundColor,
  timerCustomColor,
}) => {
  const { t } = useTranslation();
  const isIdle = useBrowserIdle();
  const { days, hours, minutes, seconds } = useCountdown(
    new Date(date),
    !isIdle,
  );

  // if date has experied just return null
  if (isTimeExpired(date)) {
    return null;
  }

  return (
    <div
      className="w-full rounded-5 text-center flex flex-col items-center px-20 py-12"
      style={{
        backgroundColor: timerBackgroundColor || '#ff2d2d',
        color: timerCustomColor || '#fff',
      }}
      data-testid="page-timer"
    >
      <p className="text-center mb-8 font-link-2">{t('TIMER_LABEL')}</p>
      <div className="flex flex-nowrap">
        <TimerItem label="Días" count={days} dataTestId="page-timer-days" />
        <TimerItem label="Hrs" count={hours} dataTestId="page-timer-hours" />
        <TimerItem
          label="Min"
          count={minutes}
          dataTestId="page-timer-minutes"
        />
        <TimerItem
          label="Segs"
          count={seconds}
          dataTestId="page-timer-seconds"
          showDots={false}
        />
      </div>
    </div>
  );
};

export default CountdownTimer;
