import React from 'react';

import { useTranslation } from '@crehana/i18n';
import { Radio, Checkbox } from '@crehana/ui';

import {
  CategoryCommonProps,
  LevelCommonProps,
  LanguageCommonProps,
  SoftwareCommonProps,
  OrderByCommonProps,
} from 'Jsx/CampaignLanding/types';

const Filters: React.FC<
  CategoryCommonProps &
    LevelCommonProps &
    LanguageCommonProps &
    SoftwareCommonProps &
    OrderByCommonProps & {
      isBlackTheme?: boolean;
    }
> = ({
  // categories props
  categories,
  categorySelected,
  setCategory,
  // level props
  levels,
  levelSelected,
  selectLevel,
  // languages
  languages,
  languageSelected,
  selectLanguage,
  // softwares
  softwares,
  softwaresSelected,
  selectSoftware,

  isBlackTheme,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {/* ============== CATEGORIES ============== */}
      <div className="mt-24">
        <div>
          <span
            className={`font-subtitle2 ${isBlackTheme ? 'text-white' : ''}`}
          >
            {t('CATEGORIES')}
          </span>
        </div>
        <div className="mt-8">
          {categories.map(category => {
            const isSelected = Boolean(
              categorySelected && category.slug === categorySelected.slug,
            );

            return (
              <Radio
                key={category.slug}
                label={category.name}
                checked={isSelected}
                name="filter-category-mobile"
                data-testid="page-filter-category-radio"
                className="py-12 w-full"
                inputProps={{
                  onClick: () => {
                    if (isSelected) {
                      setCategory(undefined);
                    } else {
                      setCategory(category);
                    }
                  },
                }}
                // just a dummy function to remove react warnings
                onChange={() => {}}
                darkMode={isBlackTheme}
              />
            );
          })}
        </div>
      </div>
      {/* ================ LEVELS ================== */}
      <div className="mt-36">
        <div>
          <span
            className={`font-subtitle2 ${isBlackTheme ? 'text-white' : ''}`}
          >
            {t('LEVELS')}
          </span>
        </div>
        <div className="mt-8">
          {levels.map(level => {
            const isSelected = !!levelSelected.find(
              l => l.value === level.value,
            );

            return (
              <Checkbox
                data-testid="page-filter-level-checkbox"
                label={level.label}
                checked={isSelected}
                className="py-16 w-full"
                key={level.value}
                inputProps={{
                  onClick: () => {
                    selectLevel(level);
                  },
                }}
                darkMode={isBlackTheme}
              />
            );
          })}
        </div>
      </div>
      {/* ============== LANGUAGES ================== */}
      <div className="mt-36">
        <div>
          <span
            className={`font-subtitle2 ${isBlackTheme ? 'text-white' : ''}`}
          >
            {t('LANGUAGES')}
          </span>
        </div>
        <div className="mt-8">
          {languages.map(language => {
            const isSelected = !!languageSelected.find(
              l => l.value === language.value,
            );

            return (
              <Checkbox
                data-testid="page-filter-language-checkbox"
                key={language.value}
                label={language.label}
                checked={isSelected}
                inputProps={{
                  onClick: () => {
                    selectLanguage(language);
                  },
                }}
                className="py-16 w-full"
                darkMode={isBlackTheme}
              />
            );
          })}
        </div>
      </div>
      {/* =========== SOFTWARES ============= */}
      <div className="mt-36 mb-24">
        <div>
          <span
            className={`font-subtitle2 ${isBlackTheme ? 'text-white' : ''}`}
          >
            {t('SOFTWARES')}
          </span>
        </div>
        <div className="mt-8">
          {softwares.map(software => {
            const isSelected = !!softwaresSelected.find(
              s => s.value === software.value,
            );

            return (
              <Checkbox
                data-testid="page-filter-software-checkbox"
                label={software.label}
                checked={isSelected}
                className="py-16 w-full"
                inputProps={{
                  onClick: () => {
                    selectSoftware(software);
                  },
                }}
                key={software.label}
                darkMode={isBlackTheme}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Filters;
