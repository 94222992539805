import { ProviderPlugin } from './thirdPartyLoader';

const regexCache: Record<string, RegExp> = {};

function scriptLoaded(containerId: string) {
  let regex = regexCache[containerId];

  if (!regex) {
    regex = new RegExp(
      `googletagmanager\\.com\\/gtm\\.js.*[?&]id=${containerId}`,
    );
    regexCache[containerId] = regex;
  }
  const scripts = document.querySelectorAll('script[src]');

  return !!Object.keys(scripts).filter(key =>
    // @ts-ignore
    (scripts[key].src || '').match(regex),
  ).length;
}

function gtmProvider(): ProviderPlugin {
  return {
    initialize: () => {
      if (!window.GOOGLE_ANALYTICS_ENABLED) {
        return;
      }
      window.dataLayer = window.dataLayer || [];

      // prettier-ignore
      /* eslint-disable */
      // @ts-ignore
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      // @ts-ignore
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      // @ts-ignore
        '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TZVZB2')
      /* eslint-enable */
    },
    loaded: () => {
      const hasDataLayer = !!(
        window.dataLayer && Array.prototype.push !== window.dataLayer.push
      );

      return scriptLoaded('GTM-TZVZB2') && hasDataLayer;
    },
  };
}

export default gtmProvider;
