import React from 'react';

import { Users } from '@crehana/icons';

const Enrollments: React.FC<{ enrollments: number; dark?: boolean }> = ({
  enrollments,
  dark,
}) => (
  <p className="flex items-center mr-16">
    <span className="block" style={{ height: 19.5 }}>
      <Users size={20} ignoreColor className="fill-current text-gray-dark" />
    </span>
    <span
      className={`font-body4 block ml-8 ${
        dark ? 'text-gray-main' : 'text-gray-dark'
      }`}
    >
      {enrollments}
    </span>
  </p>
);

export default Enrollments;
