import * as React from 'react';

import { Clamp } from '@crehana/ui';
import BookmarkButton from '@crehana/web/dist/components/Cards/components/BookmarkButton';
import { useSavedCoursesContext } from '@crehana/web/dist/ReactContexts/SavedCoursesContext';

import { trackUserActivity, UserActivityEvent } from '@crehana/analytics';

import { CourseCardProps } from 'Jsx/Shared/CourseCardV3/types';

const Title: React.FC<Partial<CourseCardProps>> = props => {
  const {
    title,
    url,
    clickTitleHandler,
    originalId,
    metaDescription,
    dark,
    beforePreview,
    isSavedCourse = false,
    source,
    sourceDetail,
  } = props;
  const { isEnabledSaveCourses } = useSavedCoursesContext();

  const onClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();

    if (beforePreview) {
      beforePreview();
    }
    setTimeout(() => {
      if (clickTitleHandler) {
        setTimeout(() => clickTitleHandler(), 0);
      } else if (url) {
        trackUserActivity({
          event: UserActivityEvent.CLICK,
          data: {
            product_id: originalId!,
            product_name: title!,
            item_type: 'course',
            item_path: window.location.pathname,
          },
          nextUrl: url,
        });
      }
    }, 0);
  };

  return (
    <div
      className="flex justify-between TitleComponent"
      css={`
        .course-title {
          width: 100%;
        }
        @media (min-width: 1024px) {
          min-height: 40px;
        }
      `}
    >
      <div
        className={
          isEnabledSaveCourses ? 'flex justify-between items-start w-full' : ''
        }
      >
        <a
          href={url || ''}
          className="block text-left whitespace-normal course-title"
          onClick={e => onClickHandler(e)}
          data-cy="course-title"
        >
          <Clamp
            as="h2"
            clamp={2}
            className={`TitleComponentText sm:mb-0 min-h-40 font-subtitle3 ${
              dark ? 'text-white' : 'text-base-main'
            }`}
          >
            {title}
          </Clamp>
        </a>

        <BookmarkButton
          isSavedCourse={isSavedCourse}
          course={{
            originalId: originalId!,
            title: title!,
          }}
          isDark={dark}
          source={source}
          sourceDetail={sourceDetail}
        />
      </div>

      {metaDescription && <div className="hidden">{metaDescription}</div>}
    </div>
  );
};

export default Title;
