import { MetaTags, LANGUAGES } from '@crehana/ts-types';

import * as Types from 'Jsx/Types/__generated__/types';

import { ENROLLMENT_URL_SOURCES } from './constants';
import {
  CampaignLandingPageData_page as PageNode,
  CampaignLandingPageData_page_prices as PagePrice,
} from './graphql/types/CampaignLandingPageData';

export type CourseDetailEnrollmentUrlSources =
  typeof ENROLLMENT_URL_SOURCES[keyof typeof ENROLLMENT_URL_SOURCES];

// the shape of the object window.__INITIAL_STATE__['page_key']
export interface RawPageData {
  metaTags: MetaTags;
  // eslint-disable-next-line camelcase
  is_coupon_campaign: boolean;
  page: PageNode;
  // eslint-disable-next-line camelcase
  page_theme?: {
    theme: PageTheme | null;
    // eslint-disable-next-line camelcase
    step_theme: PageTheme | null;
    // eslint-disable-next-line camelcase
    tyc_text_color: string | null;
  };
}

export interface AppProps {
  featureFlags?: {
    isEnableCallCenterInMainMenu: boolean;
  };
  pageSlug: string;
  pageId: number;
  pageType: string;
  bannerMobile?: Types.Maybe<string>;
  bannerTablet?: Types.Maybe<string>;
  bannerDesktop?: Types.Maybe<string>;
  logoImageUrl?: Types.Maybe<string>;
  logoColor?: Types.Maybe<string>;
  title?: Types.Maybe<string>;
  titleColor?: Types.Maybe<string>;
  subtitle?: Types.Maybe<string>;
  subtitleColor?: Types.Maybe<string>;
  description?: Types.Maybe<string>;
  customBundleItems?: Types.Maybe<number>;
  categoriesPage: Category[];
  metaTags: MetaTags;
  isInfluencerLandingPage: boolean;
  isFreeInfluencerLandingPage: boolean;
  isPageForAbTest: boolean;
  pageSize: number;
  prices: Prices | null;
  timer: {
    expireDate?: string;
    identifier?: string;
  };
  timerBackgroundColor?: Types.Maybe<string>;
  timerCustomColor?: Types.Maybe<string>;
  showIconsSection: boolean;
  levels: Array<Level>;
  softwaresFilter: Array<Software>;
  orderByOptions: Array<OrderBy>;
  pageSteps: Array<{ content: string; iconImageUrl: string; title: string }>;
  stepsTitle?: Types.Maybe<string>;
  languages: Array<{
    value: LANGUAGES;
    label: string; // 'Espanhol' | 'Español' | 'Português' | 'Portugués';
  }>;
  isAffiliate: boolean;
  temporalAccessDurationLabel?: Types.Maybe<string>;
  enableLinks: boolean;
  isCouponCampaign?: boolean;
  ctaButtonText?: Types.Maybe<string>;
  bannerButonTextColor?: Types.Maybe<string>;
  bannerButtonText?: Types.Maybe<string>;
  legalsCopy?: Types.Maybe<string>;
  campaignName: string;
  checkoutGallery: {
    image: string | null;
    title: string | null;
    subtitle: string | null;
  }[];
  isSpecializationPage: boolean;
  isCompleteTemporalAccess: boolean;
  formIsComplete?: boolean;
  userLanguage: LANGUAGES;
  isNewUserRequired: boolean;
  showPopupIsNewUserRequired: boolean;
  isDark?: boolean;
  isLeftSideContentInHero?: boolean;
  pageTheme?: PageTheme | null;
  stepTheme?: PageTheme | null;
  customButtonLabel?: string | null;
  couponCode?: string;
  isApplyCouponWithRequirements: boolean;
  isQuickPackEnrollment: boolean;
  isQuickCourseEnrollment: boolean;
  isQuickEnrollment: boolean;
  isUserRequired: boolean;
  tycTextColor?: string | null;
}

export type PageTheme = 'white' | 'dark' | 'black';

export enum PAGE_TYPES {
  COURSE = 'CURSO',
  CUSTOM_BUNDLE = 'FABRICA',
  COURSE_TEMPORAL_ACCESS = 'COURSE TEMPORAL ACCESS',
  TRACK_TEMPORAL_ACCESS = 'TRACK TEMPORAL ACCESS',
  PACKS_NEW_COUPON_FLOW = 'PACKS NEW COUPON FLOW',
}

export interface Timer {
  title?: string;
  expireDate?: string;
  identifier?: string;
}

export type Prices = PagePrice;

export type Subcategory = {
  slug: string;
  originalId: number;
  name: string;
};

export type Category = {
  slug: string;
  name: string;
  originalId: number;
  subCategories: Subcategory[];
};

export type Level = {
  value: string;
  label: string;
};

export type Language = {
  value: LANGUAGES;
  label: string;
};

export type Software = {
  value: string;
  label: string;
};

export type OrderBy = {
  value: string;
  label: string;
};

// reusable component types
export interface CategoryCommonProps {
  categories: Category[];
  categorySelected?: Category;
  setCategory: (category?: Category) => void;
}

export interface LevelCommonProps {
  levels: Level[];
  levelSelected: Level[];
  selectLevel: (level: Level) => void;
}

export interface LanguageCommonProps {
  languages: Language[];
  languageSelected: Language[];
  selectLanguage: (language: Language) => void;
}

export interface SoftwareCommonProps {
  softwares: Software[];
  softwaresSelected: Software[];
  selectSoftware: (software: Software) => void;
}

export interface OrderByCommonProps {
  orderByOptions: OrderBy[];
  orderBySelected?: OrderBy;
  setOrderBy: (tag?: OrderBy) => void;
}

export type DefaultFilters = {
  categorySelected?: Category;
  levelsSelected: Level[];
  softwaresSelected: Software[];
  languagesSelected: Language[];
};

export interface Course {
  __typename?: 'CourseNode';
  originalId?: Types.Maybe<number>;
  title: string;
  isSavedCourse?: Types.Maybe<boolean>;
  promoImage?: Types.Maybe<string>;
  canEnroll?: Types.Maybe<boolean>;
  urlToBuyNow?: Types.Maybe<string>;
  isEnrollment?: Types.Maybe<boolean>;
  courseType?: Types.Maybe<string>;
  url?: Types.Maybe<string>;
  enrollments: number;
  enrollmentAccessPreviewUrl?: Types.Maybe<string>;
  imagePlaceholder?: Types.Maybe<string>;
  promoImageAnimated?: Types.Maybe<string>;
  enrollmentUrl?: Types.Maybe<string>;
  urlToAddToCart?: Types.Maybe<string>;
  shoppingCartAdded?: Types.Maybe<boolean>;
  prices?: Types.Maybe<{
    __typename?: 'FakeCoursePriceNode';
    realPrice?: Types.Maybe<number>;
    price?: Types.Maybe<number>;
    currencySymbol?: Types.Maybe<string>;
    currencyCode?: Types.Maybe<string>;
    discount?: Types.Maybe<number>;
  }>;
  courseTag?: Types.Maybe<{
    __typename?: 'CatalogTagNode';
    textLabel?: Types.Maybe<string>;
    textColor?: Types.Maybe<string>;
    backgroundColor?: Types.Maybe<string>;
    name?: Types.Maybe<string>;
    icon?: Types.Maybe<string>;
  }>;
  categoryObj?: Types.Maybe<{
    __typename?: 'CategoryNode';
    slug: string;
    name: string;
  }>;
  level?: Types.Maybe<{ __typename?: 'CourseLevelNode'; name: string }>;
  principalSoftware?: Types.Maybe<{
    __typename?: 'SoftwareNode';
    id: string;
    name: string;
    originalImageUrl?: Types.Maybe<string>;
  }>;
  score?: Types.Maybe<{
    __typename?: 'ScoreNode';
    average?: Types.Maybe<number>;
    total?: Types.Maybe<number>;
  }>;
  professor?: Types.Maybe<{
    __typename?: 'UserNode';
    fullName?: Types.Maybe<string>;
    occupation?: Types.Maybe<string>;
  }>;
  subCategoryObj?: Types.Maybe<{
    __typename?: 'SubCategoryNode';
    slug: string;
    name: string;
  }>;
}

export type BackgroundXPosition = 'center' | 'right';
